@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
.navbarr {
  position: fixed;
  display: flex;
  justify-content: space-around;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff;
  align-items: center;
  height: 70px;
  color: rgb(37, 37, 37);
  box-shadow: rgb(173 173 213 / 23%) 0px 30px 30px -20px;

  /*box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;*/
}
.logo {
  width: 150px;
  height: 30px;
  margin-left: 30px;
}
.nav-links {
  display: flex;
  justify-content: space-between;
  width: 75%;
  padding-right: 20px;
  padding-top: 20px;
}

.nav-links li {
  color: black;
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  text-transform: capitalize;
}
.button {
  background-color: #4CAF50; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 10px 20px; /* Some padding */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
}
.hero {
  text-align: center;
  margin-top: 15%;
}
.hero h1 {
  text-align: center;
  font-size: 80px;
}
.mobile-menu-icon {
  display: none;
}
button {
  border: none;
  text-decoration: none;
  font-size: 20px;
  background: none;
}

@media (max-width: 768px) {
  .logo {
    display: flex;
    position: absolute;
    /* top: 15px; */
    left: 0px;
  }
  .nav-links {
    display: none;
  }
  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    left: 0;
    top: 10%;
    transition: all 0.5s ease-in-out;
    width: 100%;
    padding-bottom: 20px;
    padding-right: 45px;
    background-color: white;
  }
  .nav-links-mobile li.buttonlogin {
    background-color: #559ff8; /* Change the background color as needed */
    color: #ffffff; /* Change the text color as needed */
    border-radius: 5px;
    padding: 15px; /* Adjust padding for better mobile display */
    margin: 0;
  }
  
  ul li {
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    text-align: center;
  }
  .mobile-menu-icon {
    position: absolute;
    display: block;
    right: 20px;
  }
  .hero h1 {
    font-size: 50px;
  }
}