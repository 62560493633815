.containerStyle {
  display: flex;
  ;
}


.leftColumnStyle {
  max-width: 50%;
  flex-basis: 48%;
  flex: 1;
}


.rightColumnStyle {
  max-width: 50%;
  flex-basis: 50%;
  background-color: white;
  padding: 20px;
}

/* .gambar{
      width: 100%;
  } */

/* .responsive-image{
    max-width: 100%;
    height: auto;
  } */

.bagian-kiri {
  flex: 1;
  padding: 140px;
  padding-top: 24px;

}

.bagian-kanan {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gambar-setengah-halaman {
  width: 105%;
}

button[type="login"]:disabled {
  background-color: #aaaaaa;
}


button[type="login"] {
  width: 100px;
  background-color: #D8100B;
  color: #ffffff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}



.btn {
  align: center;
}


.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 400px;
  margin: 0 auto;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.card-button button {
  width: 200px;
  border-radius: 20px;
  align-items: center;
  width: 400px;
  margin: 0 auto;
}

.bawah {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 0 auto;
}

.hoverregistrasi {
  background-color: white !important;
  transition: background-color 0.3s, color 0.3s;
  /* Efek transisi saat perubahan tampilan */

}

.hoverregistrasi:hover {

  border-color: #559FF8 !important;
  box-shadow: '0 0 3px rgba(0, 0, 255, 0.3)';
}