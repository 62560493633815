html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    touch-action: manipulation;  
    max-width: 100%;             
    overflow-x: hidden;
}

.home{
    max-width: 100%;
}
.garisourfeature{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 30px;
    border-color: black;
}
.semibold {
    font-weight: 700;
}
/* HOVER BLUE */
.custombutton {
    background-color: white !important;
    transition: background-color 0.3s, color 0.3s; /* Efek transisi saat perubahan tampilan */
    
}
.custombutton:hover{ 
    background-color: rgba(85, 159, 248, 0.1) !important;
    border: 1.5px solid #559FF8 !important;
    color: blue !important;
}
.custombutton:hover svg {
    color: #559FF8 !important;
    fill: #559FF8 !important; /* This targets the SVG's fill property */
}
.imgcustombutton:hover {
    color: white !important;
    box-sizing: border-box;  /* ensures padding is included in the element's total width and height */
}
.custombutton:hover .gmbr{
    background-color: #559FF8;
}
.custombutton:hover .gmbr img{
    filter: brightness(2) saturate(0);
}
.hoverregistrasi{
    background-color: white !important;
    transition: background-color 0.3s, color 0.3s;
}
.hoverregistrasi:hover{
    border-color: #559FF8 !important;
    box-shadow: '0 0 3px rgba(0, 0, 255, 0.3)';
}
/* register 2 */
.bendera select {
    appearance: none;
    padding-left: 30px; /* Space for the flag */
    background-repeat: no-repeat;
    background-position: 5px center;
}
.bendera select[data-country='Indonesia'] {
    background-image: url('../image/Indonesia.png');
}
.bendera select[data-country='1'] {
    background-image: url('../image/USA.png');
}
/* CSS untuk mengatur gambar bendera di sebelah kiri angka */
option.flag-indonesia {
    background-image: url('../image/Indonesia.png');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left center;
    padding-left: 25px;
}
option.flag-america {
    background-image: url('../image/USA.png');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left center;
    padding-left: 25px;
}
