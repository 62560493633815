.authentication-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
  max-width: 1440px; /* Tambahkan max-width */
  margin: 0 auto; /* Tengahkan container di layar yang lebih besar */
}

h3 {
  font-size: 3vw; /* Gunakan vw untuk ukuran font responsif */
}

.auth-illustration {
  width: 100%;
  height: auto;
}

.authentication-inner {
  padding: 2rem;
}

.authentication-inner .app-brand {
  margin-bottom: 2rem;
}

@media (min-width: 1440px) {
  h3 {
    font-size: 2.5rem; /* Sesuaikan ukuran font di layar lebih dari 1440px */
  }
}
.authentication-wrapper .authentication-inner {
  width: 100%;
}
.authentication-wrapper.authentication-basic {
  align-items: center;
  justify-content: center;
}
.authentication-wrapper.authentication-basic .card-body {
  padding: 2rem;
}
.authentication-wrapper.authentication-cover {
  align-items: flex-start;
}
.authentication-wrapper.authentication-cover .authentication-inner {
  height: 100%;
  margin: auto 0;
}
.authentication-wrapper.authentication-cover .authentication-inner .auth-cover-bg {
  width: 100%;
  margin: 2rem 0 2rem 2rem;
  height: calc(100vh - 4rem);
  border-radius: 1.125rem;
  position: relative;
}
.authentication-wrapper.authentication-cover .authentication-inner .auth-cover-bg .auth-illustration {
  max-height: 65%;
  z-index: 1;
  transition: opacity 0.5s ease-in-out; 
}

.authentication-wrapper.authentication-cover .authentication-inner .auth-cover-bg .auth-illustration.hidden {
  opacity: 0;
}
.authentication-wrapper.authentication-cover .authentication-inner .auth-cover-bg .auth-illustration.visible {
  opacity: 1;
}

.authentication-wrapper.authentication-cover .authentication-inner .platform-bg {
  position: absolute;
  width: 100%;
  bottom: 0%;
  left: 0%;
  height: 35%;
}
.authentication-wrapper.authentication-cover .authentication-inner .auth-multisteps-bg-height {
  height: 100vh;
}
.authentication-wrapper.authentication-cover .authentication-inner .auth-multisteps-bg-height > img:first-child {
  z-index: 1;
}
.authentication-wrapper.authentication-basic .authentication-inner {
  max-width: 400px;
  position: relative;
}
.authentication-wrapper.authentication-basic .authentication-inner:before {
  width: 238px;
  height: 233px;
  content: " ";
  position: absolute;
  top: -55px;
  left: -40px;
  background-image: url("data:image/svg+xml,%3Csvg width='239' height='234' viewBox='0 0 239 234' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='88.5605' y='0.700195' width='149' height='149' rx='19.5' stroke='%237367F0' stroke-opacity='0.16'/%3E%3Crect x='0.621094' y='33.761' width='200' height='200' rx='10' fill='%237367F0' fill-opacity='0.08'/%3E%3C/svg%3E%0A");
}
@media (max-width: 575.98px) {
  .authentication-wrapper.authentication-basic .authentication-inner:before {
    display: none;
  }
}

@media (min-width: 1441px) {
  .authentication-wrapper.authentication-inner {
    max-width: 1200px; /* Atur lebar maksimum sesuai kebutuhan */
    margin: 0 auto; /* Pusatkan elemen di tengah layar */
  }

  .authentication-wrapper.authentication-cover .authentication-inner .auth-cover-bg {
    margin: 2rem auto 2rem 2rem; /* Sesuaikan margin agar tampilan tetap baik di layar besar */
  }

  .authentication-wrapper.authentication-basic .authentication-inner {
    max-width: 800px; /* Atur lebar maksimum sesuai kebutuhan */
  }
}


.authentication-wrapper.authentication-basic .authentication-inner:after {
  width: 180px;
  height: 180px;
  content: " ";
  position: absolute;
  z-index: -1;
  bottom: -30px;
  right: -56px;
  background-image: url("data:image/svg+xml,%3Csvg width='181' height='181' viewBox='0 0 181 181' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1.30469' y='1.44312' width='178' height='178' rx='19' stroke='%237367F0' stroke-opacity='0.16' stroke-width='2' stroke-dasharray='8 8'/%3E%3Crect x='22.8047' y='22.9431' width='135' height='135' rx='10' fill='%237367F0' fill-opacity='0.08'/%3E%3C/svg%3E");
}
@media (max-width: 575.98px) {
  .authentication-wrapper.authentication-basic .authentication-inner:after {
    display: none;
  }
}
.authentication-wrapper .auth-input-wrapper .auth-input {
  max-width: 50px;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  font-size: 150%;
}

@media (max-height: 636px) {
  .auth-multisteps-bg-height {
    height: 100% !important;
  }
}
@media (max-width: 575.98px) {
  .authentication-wrapper .auth-input-wrapper .auth-input {
    font-size: 1.125rem;
  }
}
#twoStepsForm .fv-plugins-bootstrap5-row-invalid .form-control {
  border-color: #ea5455;
}

.light-style .authentication-wrapper.authentication-bg {
  background-color: #fff;
}
.light-style .auth-cover-bg-color {
  background-color: #f8f7fa;
}

.dark-style .authentication-wrapper.authentication-bg {
  background-color: #2f3349;
}
.dark-style .auth-cover-bg-color {
  background-color: #25293c;
}
