/*
Template Name: TechBiz
Template URL: https://html.vecuro.com/techbiz
Description: TechBiz - IT Solution & Service HTML Template
Author: vecuro
Author URI: https://themeforest.net/user/vecuro_themes
Version: 1.0.0
*/
/*=================================
    CSS Index Here
==================================*/
/*

01. Theme Base
    1.1. Mixin
    1.2. Function
    1.3. Variable
    1.4. Typography
    1.5. Extend
    1.7. Wordpress Default
02. Reset
    2.1. Container
    2.2. Grid
    2.3. Input
    2.4. Slick Slider
    2.5. Mobile Menu
03. Utilities
    3.1. Preloader
    3.2. Buttons
    3.3. Titles
    3.4. Common
    3.6. Font
    3.7. Background
    3.8. Text Color
    3.9. Overlay
    3.10. Animation
04. Template Style
    4.1. Widget
    4.2. Header
    4.3. Footer
    4.4. Breadcumb
    4.5. Pagination
    4.6. Blog
    4.7. Comments
    4.8. Hero Area
    4.9. Error    
    4.10. Feature
    4.11. About
    4.12. Service
    4.13. Counter
    4.14. Team
    4.15. Process
    4.16. Accordion
    4.17. Simple Sections
    4.18. Testimonial
    4.19. Popup Side Menu
    4.20. Price Plan
    4.21. Forms Style
    4.22. Projects
    4.23. Contact
05. Spacing
06. Color Plate

*/
/*=================================
    CSS Index End
==================================*/
/*=================================
   01. Theme Base
==================================*/
/*------------------- 1.1. Mixin -------------------*/
/*------------------- 1.2. Function -------------------*/
/*------------------- 1.3. Variable-------------------*/
:root {
  --theme-color: #559ff8;
  --title-color: #252525;
  --body-color: #878D97;
  --smoke-color: #EFF1F5;
  --secondary-color: #EEF2FB;
  --black-color: #252525;
  --white-color: #ffffff;
  --yellow-color: #fec624;
  --success-color: #28a745;
  --error-color: #dc3545;
  --border-color: #E0E0E0;
  --title-font: "Exo", sans-serif;
  --body-font: "Fira Sans", sans-serif;
  --icon-font: "Font Awesome 5 Pro";
  --main-container: 1220px;
  --container-gutters: 30px;
  --section-space: 80px;
  --section-bottom: 10px;
  --section-space-mobile: 80px;
  --section-title-space: 80px;
  --ripple-ani-duration: 5s;
}

.home-five,
.home-four {
  --theme-color: #0037ff;
  --smoke-color: #f6f7fc;
}

/*------------------- 1.5. Typography -------------------*/
html,
body {
  scroll-behavior: auto !important;
}

body {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  color: var(--body-color);
  line-height: 26px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  /* Small devices */
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
    line-height: 24px;
  }
}

iframe {
  border: none;
  width: 100%;
}

.slick-slide:focus,
button:focus,
a:focus,
a:active,
input,
input:hover,
input:focus,
input:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
  outline: none;
}

input:focus {
  outline: none;
  box-shadow: none;
}

img:not([draggable]),
embed,
object,
video {
  max-width: 100%;
  height: auto;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid var(--border-color);
}

th {
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--title-color);
}

td,
th {
  border: 1px solid var(--border-color);
  padding: 9px 12px;
}

a {
  color: var(--theme-color);
  text-decoration: none;
  outline: 0;
  transition: all ease 0.4s;
}

a:hover {
  color: var(--title-color);
}

a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0;
}

button {
  transition: all ease 0.4s;
}

img {
  border: none;
  max-width: 100%;
}

ins {
  text-decoration: none;
}

pre {
  background: #f5f5f5;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

span.ajax-loader:empty,
p:empty {
  display: none;
}

p {
  margin: 0 0 18px 0;
  color: var(--body-color);
  line-height: 1.63;
  font-size: 18px;
  /* Small devices */
}

@media (max-width: 767px) {
  p {
    line-height: 1.73;
  }
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
p a,
span a {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: var(--title-font);
  color: var(--title-color);
  text-transform: none;
  font-weight: 700;
  line-height: 1.5;
  margin: 0 0 15px 0;
}

.h1,
h1 {
  font-size: 48px;
}

.h2,
h2 {
  font-size: 40px;
}

.h3,
h3 {
  font-size: 36px;
}

.h4,
h4 {
  font-size: 30px;
}

.h5,
h5 {
  font-size: 24px;
}

.h6,
h6 {
  font-size: 20px;
}

/* Large devices */
@media (max-width: 1199px) {

  .h1,
  h1 {
    font-size: 40px;
  }

  .h2,
  h2 {
    font-size: 36px;
  }

  .h3,
  h3 {
    font-size: 30px;
  }

  .h4,
  h4 {
    font-size: 24px;
  }

  .h5,
  h5 {
    font-size: 20px;
  }

  .h6,
  h6 {
    font-size: 16px;
  }
}

/* Small devices */
@media (max-width: 767px) {

  .h1,
  h1 {
    font-size: 36px;
  }

  .h2,
  h2 {
    font-size: 30px;
  }

  .h3,
  h3 {
    font-size: 26px;
  }

  .h4,
  h4 {
    font-size: 22px;
  }

  .h5,
  h5 {
    font-size: 18px;
  }

  .h6,
  h6 {
    font-size: 16px;
  }
}

/*------------------- 1.6. Extend -------------------*/
/*------------------- 1.7. Wordpress Default -------------------*/
p.has-drop-cap {
  margin-bottom: 20px;
}

.page--item p:last-child .alignright {
  clear: right;
}

.blog-title,
.pagi-title,
.breadcumb-title {
  word-break: break-word;
}

.blocks-gallery-caption,
.wp-block-embed figcaption,
.wp-block-image figcaption {
  color: var(--body-color);
}

.bypostauthor,
.gallery-caption {
  display: block;
}

.page-links,
.clearfix {
  clear: both;
}

.page--item {
  margin-bottom: 30px;
}

.page--item p {
  line-height: 1.8;
}

.content-none-search {
  margin-top: 30px;
}

.wp-block-button.aligncenter {
  text-align: center;
}

.alignleft {
  display: inline;
  float: left;
  margin-bottom: 10px;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-bottom: 10px;
  margin-left: 1.5em;
  margin-right: 1em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.gallery {
  margin-bottom: 1.5em;
  width: 100%;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
  padding: 0 5px;
}

.wp-block-columns {
  margin-bottom: 1em;
}

figure.gallery-item {
  margin-bottom: 10px;
  display: inline-block;
}

figure.wp-block-gallery {
  margin-bottom: 14px;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
  font-size: 12px;
  color: var(--body-color);
  line-height: 1.5;
  padding: 0.5em 0;
}

.wp-block-cover p:not(.has-text-color),
.wp-block-cover-image-text,
.wp-block-cover-text {
  color: var(--white-color);
}

.wp-block-cover {
  margin-bottom: 15px;
}

.wp-caption-text {
  text-align: center;
}

.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption .wp-caption-text {
  margin: 0.5em 0;
  font-size: 14px;
}

.wp-block-media-text,
.wp-block-media-text.alignwide,
figure.wp-block-gallery {
  margin-bottom: 30px;
}

.wp-block-media-text.alignwide {
  background-color: var(--smoke-color);
}

.editor-styles-wrapper .has-large-font-size,
.has-large-font-size {
  line-height: 1.4;
}

.wp-block-latest-comments a {
  color: inherit;
}

.wp-block-button {
  margin-bottom: 10px;
}

.wp-block-button:last-child {
  margin-bottom: 0;
}

.wp-block-button .wp-block-button__link {
  color: #fff;
}

.wp-block-button .wp-block-button__link:hover {
  color: #fff;
  background-color: var(--theme-color);
}

.wp-block-button.is-style-outline .wp-block-button__link {
  background-color: transparent;
  border-color: var(--title-color);
  color: var(--title-color);
}

.wp-block-button.is-style-outline .wp-block-button__link:hover {
  color: #fff;
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

.wp-block-button.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

ol.wp-block-latest-comments li {
  margin: 15px 0;
}

ul.wp-block-latest-posts {
  padding: 0;
  margin: 0;
  margin-bottom: 15px;
}

ul.wp-block-latest-posts a {
  color: inherit;
}

ul.wp-block-latest-posts a:hover {
  color: var(--theme-color);
}

ul.wp-block-latest-posts li {
  margin: 15px 0;
}

.wp-block-search {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.wp-block-search .wp-block-search__input {
  width: 100%;
  max-width: calc(100% - 120px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  padding-left: 20px;
}

.wp-block-search .wp-block-search__button {
  margin: 0;
  width: 120px;
  border: none;
  height: 50px;
  color: #fff;
  background-color: var(--theme-color);
}

.wp-block-search .wp-block-search__button:hover {
  background-color: var(--title-color);
  opacity: 0.8;
}

ul.wp-block-rss a {
  color: inherit;
}

.wp-block-group.has-background {
  padding: 15px 15px 1px;
  margin-bottom: 30px;
}

.wp-block-table td,
.wp-block-table th {
  border-color: rgba(0, 0, 0, 0.1);
}

.wp-block-table.is-style-stripes {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.logged-in .will-sticky .sticky-active.active,
.logged-in .preloader .vs-btn {
  top: 32px;
}

@media (max-width: 782px) {

  .logged-in .will-sticky .sticky-active.active,
  .logged-in .preloader .vs-btn {
    top: 46px;
  }
}

@media (max-width: 600px) {

  .logged-in .will-sticky .sticky-active.active,
  .logged-in .preloader .vs-btn {
    top: 0;
  }
}

.post-password-form .theme-input-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 20px;
}

.post-password-form .theme-input-group .theme-input-style {
  width: 100%;
  max-width: calc(100% - 120px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 50px;
  padding-left: 20px;
}

.post-password-form .theme-input-group .submit-btn {
  margin: 0;
  width: 120px;
  border: none;
  height: 50px;
  color: #fff;
  background-color: var(--theme-color);
}

.post-password-form .theme-input-group .submit-btn:hover {
  background-color: var(--title-color);
  opacity: 0.8;
}

.page-links {
  clear: both;
  margin: 0 0 1.5em;
  padding-top: 1em;
}

.page-links>.page-links-title {
  margin-right: 10px;
}

.page-links>span:not(.page-links-title):not(.screen-reader-text),
.page-links>a {
  display: inline-block;
  padding: 5px 13px;
  background-color: var(--white-color);
  color: var(--title-color);
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-right: 10px;
}

.page-links>span:not(.page-links-title):not(.screen-reader-text):hover,
.page-links>a:hover {
  opacity: 0.8;
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: transparent;
}

.page-links>span:not(.page-links-title):not(.screen-reader-text).current,
.page-links>a.current {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: transparent;
}

.page-links span.screen-reader-text {
  display: none;
}

.blog-single .wp-block-archives-dropdown {
  margin-bottom: 30px;
}

.blog-single.format-quote,
.blog-single.format-link,
.blog-single.tag-sticky-2,
.blog-single.sticky {
  border-color: transparent;
  position: relative;
}

.blog-single.format-quote .blog-content,
.blog-single.format-link .blog-content,
.blog-single.tag-sticky-2 .blog-content,
.blog-single.sticky .blog-content {
  background-color: var(--smoke-color);
  padding: 40px;
  border: none;
}

.blog-single.format-quote .blog-content:before,
.blog-single.format-link .blog-content:before,
.blog-single.tag-sticky-2 .blog-content:before,
.blog-single.sticky .blog-content:before {
  display: none;
}

.blog-single.format-quote:before,
.blog-single.format-link:before,
.blog-single.tag-sticky-2:before,
.blog-single.sticky:before {
  content: "\f0c1";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  font-size: 5rem;
  opacity: 0.3;
  right: 15px;
  line-height: 1;
  top: 15px;
  color: var(--theme-color);
  z-index: 1;
}

.blog-single.tag-sticky-2::before,
.blog-single.sticky::before {
  content: "Featured";
  right: 0;
  top: 0;
  font-size: 18px;
  color: var(--white-color);
  background-color: var(--theme-color);
  font-family: var(--title-font);
  opacity: 1;
  text-transform: capitalize;
  padding: 10px 23px;
  font-weight: 400;
}

.blog-single.format-quote:before {
  content: "\f10e";
}

.blog-single .blog-content .wp-block-categories-dropdown.wp-block-categories,
.blog-single .blog-content .wp-block-archives-dropdown {
  display: block;
  margin-bottom: 30px;
}

.blog-details .blog-single:before {
  display: none;
}

.blog-details .blog-single .blog-content {
  background-color: transparent;
  overflow: hidden;
}

.blog-details .blog-single.format-chat .blog-meta {
  margin-bottom: 20px;
}

.blog-details .blog-single.format-chat .blog-content>p:nth-child(2n) {
  background: var(--smoke-color);
  padding: 5px 20px;
}

.blog-details .blog-single.tag-sticky-2,
.blog-details .blog-single.sticky,
.blog-details .blog-single.format-quote,
.blog-details .blog-single.format-link {
  box-shadow: none;
  border: none;
  background-color: transparent;
}

.blog-details .blog-single.tag-sticky-2:before,
.blog-details .blog-single.sticky:before,
.blog-details .blog-single.format-quote:before,
.blog-details .blog-single.format-link:before {
  display: none;
}

.vs-search {
  background-color: #f3f3f3;
  margin-bottom: 30px;
  border: 1px solid #f3f3f3;
}

.vs-search .search-grid-content {
  padding: 30px;
  /* Small devices */
}

@media (max-width: 767px) {
  .vs-search .search-grid-content {
    padding: 20px;
  }
}

.vs-search .search-grid-title {
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: -0.2em;
}

.vs-search .search-grid-title a {
  color: inherit;
}

.vs-search .search-grid-title a:hover {
  color: var(--theme-color);
}

.vs-search .search-grid-meta>* {
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
}

.vs-search .search-grid-meta>*:last-child {
  margin-right: 0;
}

.vs-search .search-grid-meta a,
.vs-search .search-grid-meta span {
  color: var(--body-color);
}

/* Large devices */
@media (max-width: 1199px) {

  .blog-single.format-quote:before,
  .blog-single.format-link:before,
  .blog-single.tag-sticky-2:before,
  .blog-single.sticky:before {
    font-size: 14px;
    padding: 8px 16px;
  }

  .blog-single.format-quote .blog-content,
  .blog-single.format-link .blog-content,
  .blog-single.tag-sticky-2 .blog-content,
  .blog-single.sticky .blog-content {
    padding: 30px;
  }
}

/* Small devices */
@media (max-width: 767px) {

  .blog-single.format-quote:before,
  .blog-single.format-link:before,
  .blog-single.tag-sticky-2:before,
  .blog-single.sticky:before {
    font-size: 14px;
    padding: 8px 16px;
  }

  .blog-single.format-quote .blog-content,
  .blog-single.format-link .blog-content,
  .blog-single.tag-sticky-2 .blog-content,
  .blog-single.sticky .blog-content {
    padding: 30px 15px;
  }
}

@media (max-width: 768px) {
  .wp-block-search .wp-block-search__input {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .wp-block-latest-comments {
    padding-left: 10px;
  }

  .page--content.clearfix+.vs-comment-form {
    margin-top: 24px;
  }
}

/*=================================
      02. Reset
  ==================================*/
/*------------------- 2.1. Container -------------------*/
@media only screen and (min-width: 1300px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: calc(var(--main-container) + var(--container-gutters));
    padding-left: calc(var(--container-gutters) / 2);
    padding-right: calc(var(--container-gutters) / 2);
  }
}

@media only screen and (max-width: 1600px) {
  .container-fluid.px-0 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .container-fluid.px-0 .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media (min-width: 1399px) {
  .container-style2 {
    max-width: 1920px;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
  }
}

.container-style3 {
  --main-container: 1445px;
}

@media (min-width: 1500px) and (max-width: 1921px) {
  .container-style1 {
    max-width: 1576px;
    overflow: hidden;
    margin-right: 0;
    padding-right: 0;
  }
}

/*------------------- 2.2. Grid -------------------*/
.slick-track>[class*=col] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

@media (min-width: 767px) {
  .row:not([class*=gx-]) {
    --bs-gutter-x: 30px;
  }
}

.gy-gx {
  --bs-gutter-y: var(--bs-gutter-x);
}

.gy-30 {
  --bs-gutter-y: 30px;
}

.gx-15 {
  --bs-gutter-x: 15px;
}

@media (min-width: 1199px) {
  .gx-45 {
    --bs-gutter-x: 45px;
  }

  .gx-60 {
    --bs-gutter-x: 60px;
  }

  .gx-50 {
    --bs-gutter-x: 50px;
  }

  .gx-70 {
    --bs-gutter-x: 70px;
  }

  .gx-80 {
    --bs-gutter-x: 80px;
  }
}

@media (min-width: 1399px) {
  .gx-20 {
    --bs-gutter-x: 20px;
  }

  .gx-30 {
    --bs-gutter-x: 30px;
  }

  .gx-40 {
    --bs-gutter-x: 40px;
  }
}

/*------------------- 2.3. Input -------------------*/
select,
.form-control,
.form-select,
textarea,
input {
  height: 50px;
  padding: 0 30px 0 28px;
  padding-right: 45px;
  border: 1px solid #E0E0E0;
  color: var(--body-color);
  background-color: var(--white-color);
  border-radius: 9999px;
  border-radius: 0;
  font-size: 14px;
  width: 100%;
}

select:focus,
.form-control:focus,
.form-select:focus,
textarea:focus,
input:focus {
  outline: 0;
  box-shadow: none;
  background-color: var(--white-color);
}

select::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
  color: var(--body-color);
}

select:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: var(--body-color);
}

select::-moz-placeholder,
.form-control::-moz-placeholder,
.form-select::-moz-placeholder,
textarea::-moz-placeholder,
input::-moz-placeholder {
  color: var(--body-color);
}

select:-ms-input-placeholder,
.form-control:-ms-input-placeholder,
.form-select:-ms-input-placeholder,
textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: var(--body-color);
}

select::placeholder,
.form-control::placeholder,
.form-select::placeholder,
textarea::placeholder,
input::placeholder {
  color: var(--body-color);
}

select.form-control-lg,
.form-control.form-control-lg,
.form-select.form-control-lg,
textarea.form-control-lg,
input.form-control-lg {
  height: 60px;
}

select.form-control-sm,
.form-control.form-control-sm,
.form-select.form-control-sm,
textarea.form-control-sm,
input.form-control-sm {
  height: 40px;
  font-size: 12px;
}

select[readonly],
.form-control[readonly],
.form-select[readonly],
textarea[readonly],
input[readonly] {
  color: var(--title-color);
}

.form-select,
select {
  display: block;
  width: 100%;
  line-height: 1.5;
  vertical-align: middle;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
  background-position: right 26px center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea.form-control,
textarea {
  min-height: 150px;
  padding-top: 16px;
  padding-bottom: 17px;
  border-radius: 0;
}

input[type=checkbox] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}

input[type=checkbox]:checked~label:before {
  content: "\f00c";
  color: var(--white-color);
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}

input[type=checkbox]~label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  display: block;
}

input[type=checkbox]~label:before {
  content: "";
  font-family: var(--icon-font);
  font-weight: 700;
  position: absolute;
  left: 0px;
  top: 3.5px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  font-size: 12px;
}

input[type=radio] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}

input[type=radio]~label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 1;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 0;
}

input[type=radio]~label::before {
  content: "\f111";
  position: absolute;
  font-family: var(--icon-font);
  left: 0;
  top: -2px;
  width: 20px;
  height: 20px;
  padding-left: 0.5px;
  font-size: 0.6em;
  line-height: 19px;
  text-align: center;
  border: 1px solid var(--theme-color);
  border-radius: 100%;
  font-weight: 700;
  background: var(--white-color);
  color: transparent;
  transition: all 0.2s ease;
}

input[type=radio]:checked~label::before {
  border-color: var(--theme-color);
  background-color: var(--theme-color);
  color: var(--white-color);
}

label {
  margin-bottom: 0.5em;
  margin-top: -0.3em;
  display: block;
  color: var(--title-color);
  font-family: var(--body-font);
  font-size: 16px;
}

.form-group {
  margin-bottom: var(--bs-gutter-x);
  position: relative;
}

.form-group>i {
  position: absolute;
  right: calc(var(--bs-gutter-x) / 2 + 30px);
  top: 18px;
  font-size: 14px;
  color: #A0ABB4;
}

.form-group.has-label>i {
  top: 45px;
}

textarea.is-invalid,
select.is-invalid,
input.is-invalid,
.was-validated input:invalid {
  border: 1px solid var(--error-color) !important;
  background-position: right calc(0.375em + 0.8875rem) center;
  background-image: none;
}

textarea.is-invalid:focus,
select.is-invalid:focus,
input.is-invalid:focus,
.was-validated input:invalid:focus {
  outline: 0;
  box-shadow: none;
}

textarea.is-invalid {
  background-position: top calc(0.375em + 0.5875rem) right calc(0.375em + 0.8875rem);
}

.row.no-gutters>.form-group {
  margin-bottom: 0;
}

.form-messages {
  display: none;
}

.form-messages.mb-0 * {
  margin-bottom: 0;
}

.form-messages.success {
  color: var(--success-color);
  display: block;
}

.form-messages.error {
  color: var(--error-color);
  display: block;
}

.form-messages pre {
  padding: 0;
  background-color: transparent;
  color: inherit;
}

/*------------------- 2.4. Slick Slider -------------------*/
.slick-track>[class*=col] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.slick-track {
  min-width: 100%;
}

.slick-slide img {
  display: inline-block;
}

.slick-dots {
  list-style-type: none;
  padding: 2px 0;
  margin: 38px 0 38px 0;
  line-height: 0;
  text-align: center;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.slick-dots li {
  display: inline-block;
  margin-right: 25px;
}

.slick-dots li:last-child {
  margin-right: 0;
}

.slick-dots button {
  font-size: 0;
  padding: 0;
  background-color: transparent;
  width: 8px;
  height: 8px;
  line-height: 0;
  border-radius: 9999px;
  border: none;
  background-color: var(--dots-bg, #D8D8D8);
  transition: all ease 0.4s;
  position: relative;
}

.slick-dots button:hover {
  border-color: var(--theme-color);
}

.slick-dots button:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 30px;
  height: 30px;
  margin: -15px 0 0 -15px;
  border: 2px solid var(--theme-color);
  border-radius: 50%;
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
}

.slick-dots .slick-active button {
  background-color: var(--theme-color);
}

.slick-dots .slick-active button::before {
  opacity: 1;
  visibility: visible;
}

.slick-arrow {
  display: inline-block;
  padding: 0;
  background-color: var(--white-color);
  position: absolute;
  top: 50%;
  border: 1px solid var(--smoke-color);
  left: var(--pos-x, -100px);
  width: var(--icon-size, 50px);
  height: var(--icon-size, 50px);
  font-size: var(--icon-font-size, 18px);
  margin-top: calc(var(--icon-size, 50px) / -2);
  z-index: 2;
  border-radius: 50%;
}

.slick-arrow.default {
  position: relative;
  --pos-x: 0;
  margin-top: 0;
}

.slick-arrow.slick-next {
  right: var(--pos-x, -100px);
  left: auto;
}

.slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  border-color: transparent;
}

.arrow-margin .slick-arrow {
  top: calc(50% - 30px);
}

.arrow-wrap .slick-arrow {
  opacity: 0;
  visibility: hidden;
}

.arrow-wrap:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.dot-style2 .slick-dots {
  margin-top: 10px;
  margin-bottom: 30px;
}

.dot-style2 .slick-dots button {
  width: 10px;
  height: 10px;
  border-radius: 9999px;
  background-color: var(--theme-color);
}

.dot-style2 .slick-dots button:before {
  display: none;
}

.dot-style2 .slick-dots button:hover {
  background-color: var(--title-color);
}

.dot-style2 .slick-dots li {
  margin: 0 5px;
}

.dot-style2 .slick-dots li.slick-active button {
  width: 40px;
}

/* Extra large devices */
@media (max-width: 1500px) {
  .slick-arrow {
    --arrow-horizontal: -20px;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .slick-arrow {
    --arrow-horizontal: 40px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .slick-arrow {
    --icon-size: 40px;
    margin-right: 40px;
  }

  .slick-arrow.slick-next {
    margin-right: 0;
    margin-left: 40px;
  }

  .slick-dots {
    margin: 8px 0 38px 0;
  }
}

/*------------------- 2.5. Mobile Menu -------------------*/
.vs-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
  width: 0;
  width: 100%;
  height: 100%;
  transition: all ease 0.8s;
  opacity: 0;
  visibility: hidden;
}

.vs-menu-wrapper .mobile-logo {
  padding-bottom: 30px;
  padding-top: 40px;
  display: block;
  text-align: center;
  background-color: rgba(28, 185, 200, 0.1);
}

.vs-menu-wrapper .mobile-logo svg {
  max-width: 185px;
}

.vs-menu-wrapper .vs-menu-toggle {
  border: none;
  font-size: 22px;
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0;
  line-height: 1;
  width: 33px;
  height: 33px;
  line-height: 33px;
  font-size: 18px;
  z-index: 1;
  border-radius: 50%;
}

.vs-menu-wrapper .vs-menu-toggle:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.vs-menu-wrapper .vs-menu-area {
  width: 100%;
  max-width: 310px;
  background-color: #fff;
  border-right: 3px solid var(--theme-color);
  height: 100%;
  position: relative;
  left: -110%;
  opacity: 0;
  visibility: hidden;
  transition: all ease 1s;
  z-index: 1;
}

.vs-menu-wrapper.vs-body-visible {
  opacity: 1;
  visibility: visible;
}

.vs-menu-wrapper.vs-body-visible .vs-menu-area {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.vs-mobile-menu {
  overflow-y: scroll;
  max-height: calc(100vh - 200px);
  padding-bottom: 40px;
  margin-top: 33px;
  text-align: left;
}

.vs-mobile-menu .new-label {
  margin-left: 5px;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.vs-mobile-menu ul {
  margin: 0;
  padding: 0 0;
}

.vs-mobile-menu ul li {
  border-bottom: 1px solid #fdedf1;
  list-style-type: none;
}

.vs-mobile-menu ul li li:first-child {
  border-top: 1px solid #fdedf1;
}

.vs-mobile-menu ul li a {
  display: block;
  position: relative;
  padding: 12px 0;
  line-height: 1;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--title-color);
}

.vs-mobile-menu ul li a:before {
  content: "\f105";
  font-family: var(--icon-font);
  position: relative;
  left: 0;
  top: 0;
  margin-right: 10px;
  display: inline-block;
}

.vs-mobile-menu ul li.vs-active>a {
  color: var(--theme-color);
}

.vs-mobile-menu ul li.vs-active>a:before {
  transform: rotate(90deg);
}

.vs-mobile-menu ul li ul li {
  padding-left: 10px;
}

.vs-mobile-menu ul li ul li:last-child {
  border-bottom: none;
}

.vs-mobile-menu ul .vs-item-has-children>a .vs-mean-expand {
  position: absolute;
  right: 0;
  top: 50%;
  font-weight: 400;
  font-size: 12px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-top: -12.5px;
  display: inline-block;
  text-align: center;
  background-color: var(--smoke-color);
  color: var(--title-color);
  box-shadow: 0 0 20px -8px rgba(173, 136, 88, 0.5);
  border-radius: 50%;
}

.vs-mobile-menu ul .vs-item-has-children>a .vs-mean-expand:before {
  content: "\f067";
  font-family: var(--icon-font);
}

.vs-mobile-menu ul .vs-item-has-children.vs-active>a .vs-mean-expand:before {
  content: "\f068";
}

.vs-mobile-menu>ul {
  padding: 0 40px;
}

.vs-mobile-menu>ul>li:last-child {
  border-bottom: none;
}

.vs-menu-toggle {
  width: 50px;
  height: 50px;
  padding: 0;
  font-size: 24px;
  border: none;
  display: inline-block;
  background-color: #F2F6FF;
  color: var(--theme-color);
  border-radius: 5px;
}

.vs-menu-toggle.style2 {
  background-color: var(--theme-color);
  border-radius: 50%;
  color: var(--white-color);
  width: 55px;
  height: 55px;
}

@media (max-width: 400px) {
  .vs-menu-wrapper .vs-menu-area {
    width: 100%;
    max-width: 270px;
  }

  .vs-mobile-menu>ul {
    padding: 0 20px;
  }
}

/*=================================
      03. Utilities
  ==================================*/
/*------------------- 3.1. Preloader -------------------*/
.preloader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background-color: var(--white-color);
}

.preloader .vs-btn {
  padding: 15px 20px;
  border-radius: 0;
  font-size: 14px;
}

.preloader-inner {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.preloader-inner img {
  display: block;
  margin: 0 auto 10px auto;
}

.loader {
  -webkit-animation: loaderspin 1.5s infinite;
  animation: loaderspin 1.5s infinite;
  height: 50px;
  width: 50px;
  display: block;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  -webkit-animation: ball1 1.5s infinite;
  animation: ball1 1.5s infinite;
  background-color: var(--title-color);
  box-shadow: 30px 0 0 var(--theme-color);
  margin-bottom: 10px;
}

.loader:after {
  -webkit-animation: ball2 1.5s infinite;
  animation: ball2 1.5s infinite;
  background-color: var(--theme-color);
  box-shadow: 30px 0 0 var(--title-color);
}

@-webkit-keyframes loaderspin {
  0% {
    transform: rotate(0deg) scale(0.8);
  }

  50% {
    transform: rotate(360deg) scale(1);
  }

  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes loaderspin {
  0% {
    transform: rotate(0deg) scale(0.8);
  }

  50% {
    transform: rotate(360deg) scale(1);
  }

  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@-webkit-keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 var(--theme-color);
  }

  50% {
    box-shadow: 0 0 0 var(--theme-color);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 var(--theme-color);
    margin-bottom: 10px;
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 var(--theme-color);
  }

  50% {
    box-shadow: 0 0 0 var(--theme-color);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 var(--theme-color);
    margin-bottom: 10px;
  }
}

@-webkit-keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 var(--title-color);
  }

  50% {
    box-shadow: 0 0 0 var(--title-color);
    margin-top: -20px;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 var(--title-color);
    margin-top: 0;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 var(--title-color);
  }

  50% {
    box-shadow: 0 0 0 var(--title-color);
    margin-top: -20px;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 var(--title-color);
    margin-top: 0;
  }
}

/*------------------- 3.2. Buttons -------------------*/
.vs-btn {
  position: relative;
  display: inline-block;
  border: none;
  text-transform: uppercase;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  padding: 19px 30.5px;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
}

.vs-btn::after,
.vs-btn::before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0%;
  left: 0%;
  background: var(--title-color);
  transition: all ease 0.5s;
  z-index: -1;
  border-radius: inherit;
}

.vs-btn:before {
  transform: rotateX(90deg);
}

.vs-btn:after {
  transform: rotateY(90deg);
}

.vs-btn>i {
  margin-left: 8px;
}

.vs-btn.style2 {
  background-color: var(--white-color);
  color: var(--theme-color);
}

.vs-btn.style4,
.vs-btn.style3 {
  background-color: var(--smoke-color);
  color: var(--theme-color);
}

.vs-btn.style3 {
  padding: 14px 23.5px;
}

.vs-btn.style5 {
  background-color: #559ff8;
  color: var(--white-color);
}

.vs-btn.style6 {
  background-color: var(--title-color);
  color: var(--white-color);
}

.vs-btn.style6:after,
.vs-btn.style6::before {
  background-color: var(--theme-color);
}

.vs-btn.style7 {
  border-radius: 9999px;
  text-transform: capitalize;
}

.vs-btn.style8 {
  border: 2px solid var(--theme-color);
  color: var(--theme-color);
  background-color: transparent;
  border-radius: 9999px;
  padding: 17px 28px;
  text-transform: capitalize;
}

.vs-btn.style8:after,
.vs-btn.style8:before {
  display: none;
}

.vs-btn.style8:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.vs-btn.style9 {
  background-color: var(--white-color);
  color: var(--theme-color);
  border-radius: 9999px;
  text-transform: capitalize;
}

.vs-btn.style10 {
  border: 2px solid var(--white-color);
  color: var(--white-color);
  background-color: transparent;
  border-radius: 9999px;
  padding: 17px 28px;
  text-transform: capitalize;
}

.vs-btn.style10:hover {
  border-color: transparent;
}

.vs-btn:hover {
  color: var(--white-color);
}

.vs-btn:hover:before {
  transform: rotateX(0);
}

.vs-btn:hover:after {
  transform: rotateY(0);
}

.icon-btn,
.hero-layout4 .slick-arrow {
  display: inline-block;
  width: var(--btn-size, 67px);
  height: var(--btn-size, 67px);
  line-height: var(--btn-size, 67px);
  font-size: var(--btn-font-size, 20px);
  background-color: var(--theme-color);
  color: var(--white-color);
  border: none;
  text-align: center;
  border-radius: 50%;
  padding: 0;
}

.icon-btn.style2,
.hero-layout4 .style2.slick-arrow {
  border: 2px solid var(--white-color);
  color: var(--white-color);
  background-color: transparent;
}

.icon-btn.style2:hover,
.hero-layout4 .style2.slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.icon-btn.style4,
.hero-layout4 .style4.slick-arrow,
.icon-btn.style3,
.hero-layout4 .style3.slick-arrow {
  --btn-size: 55px;
  background-color: #F2F6FF;
  color: var(--theme-color);
  border-radius: 5px;
}

.icon-btn.style4,
.hero-layout4 .style4.slick-arrow {
  background-color: var(--white-color);
}

.icon-btn.style5,
.hero-layout4 .style5.slick-arrow {
  background-color: var(--white-color);
  color: var(--title-color);
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.08);
}

.icon-btn.style5:hover,
.hero-layout4 .style5.slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  box-shadow: none;
}

.icon-btn.style6,
.hero-layout4 .style6.slick-arrow {
  background-color: var(--theme-color);
  color: var(--white-color);
  --btn-size: 55px;
  --btn-font-size: 17px;
}

.icon-btn:hover,
.hero-layout4 .slick-arrow:hover {
  background-color: var(--title-color);
  color: var(--white-color);
  border-color: transparent;
}

.play-btn {
  display: inline-block;
  position: relative;
  z-index: 1;
  border: none;
  background-color: transparent;
  padding: 0;
}

.play-btn>i {
  display: inline-block;
  width: var(--icon-size, 124px);
  height: var(--icon-size, 124px);
  line-height: var(--icon-size, 124px);
  font-size: var(--icon-font-size, 1.5em);
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 50%;
  z-index: 1;
  transition: all ease 0.4s;
}

.play-btn:after,
.play-btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--theme-color);
  z-index: -1;
  border-radius: 50%;
  transition: all ease 0.4s;
}

.play-btn:after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.play-btn.style3 i,
.play-btn.style2 i {
  --icon-size: 60px;
  border-radius: 5px;
  --icon-font-size: 18px;
}

.play-btn.style2:after,
.play-btn.style2:before {
  border-radius: 5px;
}

.play-btn.style3 i {
  background-color: rgba(14, 89, 242, 0.7);
}

.play-btn.style3:after,
.play-btn.style3::before {
  display: none;
}

.play-btn.style4 {
  --icon-size: 120px;
  --icon-font-size: 30px;
}

.play-btn.style4:after,
.play-btn.style4:before,
.play-btn.style4 i {
  background-color: var(--white-color);
  color: var(--theme-color);
}

.play-btn.style5 i {
  border-radius: 0;
  --icon-size: 120px;
  --icon-font-size: 30px;
}

.play-btn.style5:after,
.play-btn.style5:before {
  border: 2px solid var(--theme-color);
  background-color: transparent;
  border-radius: 0;
}

.play-btn.style6 {
  --icon-size: 60px;
  --icon-font-size: 18px;
}

.play-btn:hover:after,
.play-btn:hover::before,
.play-btn:hover i {
  background-color: var(--title-color);
  color: var(--white-color);
}

.link-btn {
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  line-height: 0.8;
  position: relative;
  padding-bottom: 2px;
  margin-bottom: -2px;
  text-transform: uppercase;
}

.link-btn i {
  margin-left: 7px;
  font-size: 0.9rem;
}

.link-btn:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: var(--theme-color);
  transition: all ease 0.4s;
}

.link-btn:hover {
  color: var(--theme-color);
}

.link-btn:hover::before {
  width: 100%;
}

.scroll-btn {
  position: fixed;
  bottom: 300px;
  right: 30px;
  z-index: 94;
  opacity: 0;
  visibility: hidden;
  display: inline-block;
  border-radius: 50%;
  /* Small devices */
}

.scroll-btn i {
  display: inline-block;
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  font-size: 16px;
  width: var(--btn-size, 50px);
  height: var(--btn-size, 50px);
  line-height: var(--btn-size, 50px);
  z-index: 2;
  border-radius: inherit;
  position: relative;
  transition: all ease 0.8s;
}

.scroll-btn:before {
  content: "";
  position: absolute;
  left: var(--extra-shape, -6px);
  top: var(--extra-shape, -6px);
  right: var(--extra-shape, -6px);
  bottom: var(--extra-shape, -6px);
  background-color: transparent;
  border-radius: inherit;
  z-index: 1;
  border: 2px dashed var(--theme-color);
  -webkit-animation: spin 13s infinite linear;
  animation: spin 13s infinite linear;
}

.scroll-btn:focus i,
.scroll-btn:hover i {
  background-color: var(--title-color);
  color: var(--white-color);
}

.scroll-btn.show {
  bottom: 120px;
  opacity: 1;
  visibility: visible;
}

@media (max-width: 767px) {
  .scroll-btn {
    --btn-size: 40px;
    --extra-shape: -4px;
    right: 15px;
    bottom: 50px;
  }

  .scroll-btn.show {
    bottom: 15px;
  }
}

.scrollToTop {
  position: fixed;
  right: 60px;
  bottom: 500px;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  z-index: 96;
}

.scrollToTop.show {
  bottom: 60px;
  opacity: 1;
  visibility: visible;
}

/* Large devices */
@media (max-width: 1199px) {
  .play-btn.style4 {
    --icon-size: 80px;
    --icon-font-size: 20px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .play-btn {
    --icon-size: 60px;
  }

  .scrollToTop {
    right: 20px;
  }

  .scrollToTop.show {
    bottom: 20px;
  }
}

/*------------------- 3.3. Titles -------------------*/
.sec-title3,
.sec-title {
  text-transform: uppercase;
  line-height: 1.35;
  margin: -0.25em 0 18px 0;
}

.sec-title2 {
  font-size: 18px;
  font-weight: 700;
  margin: -0.3em 0 22px 0;
}

.sec-title3 {
  text-transform: capitalize;
}

.sec-title4 {
  font-size: 60px;
  line-height: 1.2;
}

.sec-subtitle2,
.sec-subtitle {
  text-transform: none;
  display: block;
  color: #252525;
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 600;
  margin: -0.25em 0 23px 0;
}

.sec-subtitle2>i,
.sec-subtitle>i {
  margin-right: 15px;
}

.sec-subtitle2 {
  text-transform: none;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}

.sec-subtitle2 .fa-arrow-right {
  font-size: 1.1em;
  vertical-align: middle;
  transform: rotate(-30deg);
}

.title-area {
  margin-bottom: calc(var(--section-title-space) - 17px);
}

.sec-btns {
  padding: 13px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.sec-btns i.fa-arrow-left {
  margin-left: 0;
  margin-right: 10px;
}

.sec-btns2 {
  margin-bottom: calc(var(--section-title-space) - 5px);
}

.sec-line-wrap {
  display: flex;
  gap: 25px;
  align-items: center;
  margin: 0 0 80px 0;
}

.sec-line-wrap .sec-title2 {
  margin: -0.3em 0 -0.4em 0;
}

.sec-line {
  background-color: var(--theme-color);
  flex: 1;
  height: 2px;
}

.border-title {
  position: relative;
  padding: 0 0 5px 0;
  margin-bottom: 0;
}

.border-title-border {
  width: 48px;
  height: 2px;
  display: inline-block;
  background-color: var(--theme-color);
  margin-bottom: 25px;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .sec-title4 {
    font-size: 42px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .sec-title4 {
    font-size: 36px;
    line-height: 1.4;
  }
}

/* Medium devices */
@media (max-width: 991px) {

  .sec-title3.h1,
  .sec-title.h1 {
    font-size: 36px;
  }

  .sec-line-wrap {
    margin: 0 0 60px 0;
  }

  .sec-subtitle2 {
    margin-bottom: 15px;
  }

  .title-area {
    margin-bottom: 40px;
  }
}

/* Small devices */
@media (max-width: 767px) {

  .sec-title3.h1,
  .sec-title.h1 {
    font-size: 30px;
  }

  .sec-subtitle {
    font-size: 16px;
  }

  .sec-line-wrap {
    margin: 0 0 40px 0;
    gap: 10px;
  }

  .sec-title4 {
    font-size: 30px;
    line-height: 1.4;
  }
}

/*------------------- 3.4. Common -------------------*/
.image-scale-hover {
  overflow: hidden;
}

.image-scale-hover img {
  transition: all ease 0.4s;
  transform: scale(1.001);
}

.image-scale-hover:hover img {
  transform: scale(1.2);
}

.alert-dismissible .btn-close {
  font-size: 18px;
  line-height: 1;
}

.z-index-step1 {
  position: relative;
  z-index: 4 !important;
}

.z-index-common {
  position: relative;
  z-index: 3;
}

.z-index-n1 {
  z-index: -1;
}

.media-body {
  flex: 1;
}

.badge {
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  padding: 0.35em 0.55em;
  border-radius: 50%;
}

.hr-style1 {
  background-color: var(--white-color);
  margin: 20px 0 30px 0;
}

.new-label {
  font-size: 12px;
  background-color: var(--error-color);
  color: var(--white-color);
  line-height: 1;
  padding: 1px 5px;
  border-radius: 4px;
  text-transform: capitalize;
  position: relative;
  top: -1px;
}

.big-name {
  font-size: 450px;
  color: var(--white-color);
  -webkit-text-stroke: 2px #eef0ff;
  line-height: 1;
  letter-spacing: -0.08em;
  font-weight: 400;
  position: relative;
  z-index: 31;
  text-align: right;
  margin: -234px 30px -59px 0;
}

.sec-big-text2,
.sec-big-text {
  font-size: 160px;
  font-weight: 700;
  font-family: var(--title-font);
  line-height: 1;
  display: block;
  color: #f1f3f9;
  position: absolute;
  left: 0;
  right: 0;
  top: -37px;
  z-index: -1;
}

.sec-big-text2 {
  top: auto;
  left: auto;
  right: 120px;
  bottom: -26px;
}

.home-five-shape1 {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #f6f7fc;
  width: 100%;
  max-width: 1255px;
  z-index: -3;
}

.home-five-shape2 {
  position: absolute;
  left: -428px;
  bottom: 250px;
  width: 1024px;
  height: 1024px;
  border: 50px solid var(--theme-color);
  border-radius: 50%;
  z-index: -2;
}

.home-five-shape3 {
  position: absolute;
  right: 60px;
  bottom: 325px;
}

.home-five-wrap1 {
  position: relative;
  border-bottom: 7px solid var(--theme-color);
  z-index: 1;
}

/* Hight Resoulation devices */
@media (min-width: 1921px) {

  .ls-wrapper>.ls-layer.d-hd-none,
  .ls-wrapper>a>.ls-layer.d-hd-none,
  .d-hd-none {
    display: none !important;
  }
}

@media (min-width: 1500px) {
  .d-xxxl-block {
    display: block !important;
  }
}

/* Extra large devices */
@media (max-width: 1500px) {
  .big-name {
    font-size: 320px;
    margin: -168px 30px -59px 0;
  }

  .home-five-shape3 {
    right: 30px;
    bottom: 50px;
    max-width: 200px;
  }

  .home-five-shape2 {
    left: -18%;
    bottom: 34%;
    width: 500px;
    height: 500px;
  }

  .home-five-shape1 {
    max-width: 100%;
    right: 60px;
    width: auto;
  }

  .sec-big-text2,
  .sec-big-text {
    font-size: 130px;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .big-name {
    font-size: 245px;
    margin: -128px 40px -42px 0;
  }

  .home-five-shape1 {
    right: 0;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .big-name {
    font-size: 190px;
    margin: -100px 40px -42px 0;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .big-name {
    font-size: 146px;
    margin: -78px 40px -25px 0;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .big-name {
    font-size: 75px;
    margin: -20px 0 -12px 0;
    letter-spacing: 0;
    text-align: center;
  }
}

/*------------------- 3.6. Font -------------------*/
.font-icon {
  font-family: var(--icon-font);
}

.font-title {
  font-family: var(--title-font);
}

.font-body {
  font-family: var(--body-font);
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-extrabold {
  font-weight: 800;
}

.fs-md {
  font-size: 18px;
}

.fs-xs {
  font-size: 14px;
}

/*------------------- 3.7. Background -------------------*/
.bg-theme {
  background-color: var(--theme-color) !important;
}

.bg-smoke {
  background-color: var(--smoke-color) !important;
}

.bg-white {
  background-color: var(--white-color) !important;
}

.bg-black {
  background-color: var(--black-color) !important;
}

.bg-title {
  background-color: var(--title-color) !important;
}

.bg-gray {
  background-color: #e5e5e5;
}

.background-image,
[data-bg-src] {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-fluid {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.bg-auto {
  background-size: auto auto;
}

/*------------------- 3.8. Text Color -------------------*/
.text-theme {
  color: var(--theme-color) !important;
}

.text-title {
  color: var(--title-color) !important;
}

.text-body {
  color: var(--body-color) !important;
}

.text-white {
  color: var(--white-color) !important;
}

.text-yellow {
  color: var(--yellow-color) !important;
}

.text-success {
  color: var(--success-color) !important;
}

.text-error {
  color: var(--error-color) !important;
}

.text-inherit,
.footer-layout5 .copyright-text a,
.footer-info-list .info a {
  color: inherit;
}

.text-inherit:hover,
.footer-layout5 .copyright-text a:hover,
.footer-info-list .info a:hover {
  color: var(--theme-color);
}

a.text-theme:hover,
.text-reset:hover {
  text-decoration: underline;
}

.text-light2 {
  color: #919398;
}

/*------------------- 3.9. Overlay -------------------*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

[data-overlay] {
  position: relative;
}

[data-overlay] [class^=col-],
[data-overlay] [class*=col-] {
  z-index: 1;
}

[data-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

[data-overlay=theme]:before {
  background-color: var(--theme-color);
}

[data-overlay=title]:before {
  background-color: var(--title-color);
}

[data-overlay=white]:before {
  background-color: var(--white-color);
}

[data-overlay=black]:before {
  background-color: var(--black-color);
}

[data-opacity="1"]:before {
  opacity: 0.1;
}

[data-opacity="2"]:before {
  opacity: 0.2;
}

[data-opacity="3"]:before {
  opacity: 0.3;
}

[data-opacity="4"]:before {
  opacity: 0.4;
}

[data-opacity="5"]:before {
  opacity: 0.5;
}

[data-opacity="6"]:before {
  opacity: 0.6;
}

[data-opacity="7"]:before {
  opacity: 0.7;
}

[data-opacity="8"]:before {
  opacity: 0.8;
}

[data-opacity="9"]:before {
  opacity: 0.9;
}

[data-opacity="10"]:before {
  opacity: 1;
}

/*------------------- 3.10. Animation -------------------*/
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.wow-animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadein {
  --animation-name: fadein-custom;
}

.slideinup {
  --animation-name: slideinup;
}

.slideindown {
  --animation-name: slideindown;
}

.slideinleft {
  --animation-name: slideinleft;
}

.slideinright {
  --animation-name: slideinright;
}

.animated,
.hero-layout5 .hero-info,
.hero-layout5 .hero-shape5,
.hero-layout5 .hero-shape4,
.hero-layout5 .hero-shape3,
.hero-layout5 .hero-shape2,
.hero-layout5 .hero-list li,
.hero-layout5 .hero-title,
.hero-layout5 .hero-subtitle,
.hero-layout4 .hero-title2,
.hero-layout4 .hero-title,
.hero-layout4 .hero-subtitle,
.hero-layout3 .hero-text,
.hero-layout3 .hero-title,
.hero-layout3 .hero-subtitle,
.hero-layout2 .hero-play,
.hero-layout2 .hero-shape2,
.hero-layout2 .hero-title,
.hero-layout2 .hero-subtitle,
.hero-layout2 .hero-bg-text,
.hero-layout1 .hero-text,
.hero-layout1 .hero-title,
.hero-btns {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  -webkit-animation-name: var(--animation-name);
  animation-name: var(--animation-name);
}

.ripple-animation,
.play-btn:after,
.play-btn:before {
  -webkit-animation-duration: var(--ripple-ani-duration);
  animation-duration: var(--ripple-ani-duration);
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: ripple;
  animation-name: ripple;
}

@-webkit-keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  30% {
    opacity: 0.4;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  30% {
    opacity: 0.4;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes slideinup {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideinup {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }

  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes slideindown {
  0% {
    opacity: 0;
    transform: translateY(-70px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideindown {
  0% {
    opacity: 0;
    transform: translateY(-70px);
  }

  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes slideinleft {
  0% {
    opacity: 0;
    transform: translateX(-70px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideinleft {
  0% {
    opacity: 0;
    transform: translateX(-70px);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes slideinright {
  0% {
    opacity: 0;
    transform: translateX(70px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideinright {
  0% {
    opacity: 0;
    transform: translateX(70px);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes fadein-custom {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein-custom {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/*=================================
      04. Template Style
  ==================================*/
/*------------------- 4.1. Widget  -------------------*/
.widget_nav_menu ul,
.widget_meta ul,
.widget_pages ul,
.widget_archive ul,
.widget_categories ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget_nav_menu>ul,
.widget_meta>ul,
.widget_pages>ul,
.widget_archive>ul,
.widget_categories>ul {
  margin: 0 0 -38px 0;
}

.widget_nav_menu a,
.widget_meta a,
.widget_pages a,
.widget_archive a,
.widget_categories a {
  display: block;
  background-color: transparent;
  color: var(--body-color);
  margin: 0 0 18px 0;
  padding: 0 0 17px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  border-bottom: 1px solid #DADFEC;
}

.widget_nav_menu a::before,
.widget_meta a::before,
.widget_pages a::before,
.widget_archive a::before,
.widget_categories a::before {
  content: "\f178";
  position: absolute;
  right: 0;
  top: 0;
  font-family: var(--icon-font);
  font-weight: 400;
  color: var(--body-color);
}

.widget_nav_menu a:hover,
.widget_meta a:hover,
.widget_pages a:hover,
.widget_archive a:hover,
.widget_categories a:hover {
  color: var(--body-color);
}

.widget_nav_menu li,
.widget_meta li,
.widget_pages li,
.widget_archive li,
.widget_categories li {
  display: block;
  position: relative;
}

.widget_nav_menu li:last-child a,
.widget_meta li:last-child a,
.widget_pages li:last-child a,
.widget_archive li:last-child a,
.widget_categories li:last-child a {
  border-bottom: none;
}

.widget_nav_menu li>span,
.widget_meta li>span,
.widget_pages li>span,
.widget_archive li>span,
.widget_categories li>span {
  text-align: center;
  position: absolute;
  right: 0;
  top: -4.5px;
  font-size: 16px;
  transition: all ease 0.4s;
}

.widget_nav_menu li:hover>span,
.widget_meta li:hover>span,
.widget_pages li:hover>span,
.widget_archive li:hover>span,
.widget_categories li:hover>span {
  color: var(--theme-color);
}

.widget_nav_menu .children,
.widget_meta .children,
.widget_pages .children,
.widget_archive .children,
.widget_categories .children {
  margin-left: 10px;
}

.widget_nav_menu a,
.widget_meta a,
.widget_pages a {
  padding-right: 20px;
}

.widget_nav_menu .sub-menu {
  margin-left: 10px;
}

.wp-block-archives {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

.wp-block-archives a:not(:hover) {
  color: inherit;
}

.vs-blog ul.wp-block-archives li {
  margin: 5px 0;
}

/* Small devices */
@media (max-width: 767px) {

  .widget_nav_menu a,
  .widget_meta a,
  .widget_pages a,
  .widget_archive a,
  .widget_categories a {
    font-size: 14px;
  }
}

.widget {
  padding: var(--widget-padding-y, 40px) var(--widget-padding-x, 40px);
  background-color: var(--smoke-color);
  position: relative;
  margin-bottom: 40px;
  border: 6px;
}

.widget select,
.widget input {
  height: 55px;
  border: none;
  background-color: #fff;
  padding-left: 20px;
  font-weight: 400;
}

.widget_title {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  line-height: 1em;
  margin: -0.07em 0 35px 0;
  font-family: var(--title-font);
}

.widget_titlee {
  position: relative;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: 1em;
  font-family: var(--title-font);
}

.widget .search-form {
  position: relative;
  display: flex;
}

.widget .search-form input {
  flex: 1;
}

.widget .search-form button {
  border: none;
  background-color: var(--theme-color);
  color: var(--white-color);
  height: 55px;
  width: 55px;
  line-height: 55px;
  font-size: 17px;
}

.widget .search-form button:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.wp-block-tag-cloud a,
.tagcloud a {
  display: inline-block;
  border: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  padding: 9.5px 16.5px;
  margin-right: 5px;
  margin-bottom: 10px;
  color: var(--title-color);
  background-color: var(--smoke-color);
  border-radius: 4px;
}

.wp-block-tag-cloud a:hover,
.tagcloud a:hover {
  background-color: var(--theme-color);
  color: var(--white-color) !important;
}

.tagcloud {
  margin-right: -5px;
  margin-bottom: -10px;
}

.tagcloud a {
  background-color: var(--white-color);
  color: var(--title-color);
}

.recent-post {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.recent-post:last-child {
  margin-bottom: 0;
}

.recent-post .media-img {
  margin-right: 20px;
  width: 80px;
  overflow: hidden;
}

.recent-post .media-img img {
  width: 100%;
  border-radius: 4px;
  transition: all ease 0.4s;
}

.recent-post .post-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  margin: 0 0 5px 0;
}

.recent-post .recent-post-meta a {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  color: var(--body-color);
}

.recent-post .recent-post-meta a i {
  margin-right: 5px;
  color: var(--theme-color);
}

.recent-post:hover .media-img img {
  transform: scale(1.1);
}

.wp-block-calendar,
.calendar_wrap {
  position: relative;
  background-color: #fff;
  padding-bottom: 0;
  border: none;
}

.wp-block-calendar span[class*=wp-calendar-nav],
.calendar_wrap span[class*=wp-calendar-nav] {
  position: absolute;
  top: 8px;
  left: 20px;
  font-size: 14px;
  color: var(--title-color);
  font-weight: 500;
  z-index: 1;
}

.wp-block-calendar span[class*=wp-calendar-nav] a,
.calendar_wrap span[class*=wp-calendar-nav] a {
  color: inherit;
}

.wp-block-calendar span.wp-calendar-nav-next,
.calendar_wrap span.wp-calendar-nav-next {
  left: auto;
  right: 20px;
}

.wp-block-calendar caption,
.calendar_wrap caption {
  caption-side: top;
  text-align: center;
  color: var(--title-color);
  background-color: #f1dbbe;
}

.wp-block-calendar th,
.calendar_wrap th {
  font-size: 14px;
  padding: 5px 5px;
  border: none;
  text-align: center;
  border-right: 1px solid #fff;
  color: #01133c;
  font-weight: 500;
}

.wp-block-calendar th a,
.calendar_wrap th a {
  color: inherit;
}

.wp-block-calendar td,
.calendar_wrap td {
  font-size: 14px;
  padding: 5px;
  color: #01133c;
  border: 1px solid #ededed;
  text-align: center;
  background-color: transparent;
  transition: all ease 0.4s;
}

.wp-block-calendar #today,
.calendar_wrap #today {
  color: var(--theme-color);
  background-color: var(--white-color);
  border-color: #ededed;
}

.wp-block-calendar thead,
.calendar_wrap thead {
  background-color: #f6f6f6;
}

.wp-block-calendar .wp-calendar-table,
.calendar_wrap .wp-calendar-table {
  margin-bottom: 0;
}

.wp-block-calendar .wp-calendar-nav .pad,
.calendar_wrap .wp-calendar-nav .pad {
  display: none;
}

.sidebar-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.sidebar-gallery .gallery-thumb {
  overflow: hidden;
  border-radius: 5px;
}

.sidebar-gallery .gallery-thumb img {
  width: 100%;
  transform: scale(1);
  transition: all ease 0.4s;
}

.sidebar-gallery .gallery-thumb:hover img {
  transform: scale(1.2);
}

.widget_shopping_cart {
  text-align: left;
}

.widget_shopping_cart ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.widget_shopping_cart .mini_cart_item {
  position: relative;
  border-bottom: 1px solid var(--border-color);
  padding: 0 0 32px 110px;
  margin: 0 0 27px 0;
  min-height: 90px;
}

.widget_shopping_cart .remove {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--title-color);
  line-height: 1;
  font-size: 18px;
}

.widget_shopping_cart .remove:hover {
  color: var(--theme-color);
}

.widget_shopping_cart .img {
  position: absolute;
  left: 0;
  top: 3px;
  width: 90px;
  height: 90px;
  display: inline-block;
  border: 1px solid var(--border-color);
}

.widget_shopping_cart .product-title {
  font-size: 14px;
  color: var(--title-color);
  font-weight: 400;
  margin-bottom: 0;
  display: inline-block;
}

.widget_shopping_cart .amount {
  display: block;
  font-weight: 600;
  color: var(--title-color);
  font-size: 16px;
}

.widget_shopping_cart .quantity {
  display: inline-flex;
  margin-top: 12px;
}

.widget_shopping_cart .qut-btn {
  border: 1px solid var(--border-color);
  background-color: transparent;
  display: inline-block;
  background-color: transparent;
  width: 25px;
  height: 25px;
  padding: 0;
  font-size: 12px;
  z-index: 1;
  position: relative;
}

.widget_shopping_cart .qut-btn:hover {
  background-color: var(--title-color);
  border-color: transparent;
  color: var(--white-color);
}

.widget_shopping_cart .qty-input {
  border: 1px solid var(--border-color);
  text-align: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: 40px;
  font-size: 12px;
  padding: 0;
  height: 25px;
  margin: 0 -1px;
}

.widget_shopping_cart .subtotal {
  float: right;
  text-align: right;
  font-size: 12px;
  margin-top: 19px;
}

.widget_shopping_cart .subtotal>span:not(.amount) {
  color: var(--body-color);
  font-weight: 300;
}

.widget_shopping_cart .subtotal .amount {
  font-size: 12px;
  display: inline-block;
}

.widget_shopping_cart .total {
  color: var(--title-color);
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 25px;
  font-size: 18px;
}

.widget_shopping_cart .total .amount {
  font-size: inherit;
  display: inline-block;
  color: var(--theme-color);
}

.widget_shopping_cart .vs-btn {
  border: 1px solid var(--title-color);
  color: var(--title-color);
  margin-right: 10px;
  text-transform: capitalize;
  height: 50px;
  line-height: 47px;
  padding: 0 35px;
  background-color: var(--title-color);
  color: var(--white-color);
  font-weight: 400;
}

.widget_shopping_cart .vs-btn:hover {
  background-color: transparent;
  color: var(--title-color);
}

.widget_shopping_cart .buttons {
  margin: 0;
}

.sidebar-area {
  margin-bottom: -10px;
}

.sidebar-area ul.wp-block-latest-posts {
  margin-bottom: 0;
}

.sidebar-area ul.wp-block-latest-posts li:last-child {
  margin-bottom: 0;
}

.sidebar-area .newsletter-form button {
  width: 100%;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  height: 60px;
  margin-top: 10px;
}

.sidebar-area .widget .wp-block-search {
  margin-bottom: 0;
}

.sidebar-area .wp-block-group__inner-container h2 {
  font-size: 20px;
  line-height: 1em;
  margin-bottom: 20px;
  margin-top: -0.07em;
}

.sidebar-area ol.wp-block-latest-comments {
  padding: 0;
  margin: 0;
}

.sidebar-area ol.wp-block-latest-comments li {
  line-height: 1.5;
  margin: 0 0 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}

.sidebar-area ol.wp-block-latest-comments li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.recent-product {
  display: flex;
}

.recent-product .product-title {
  font-size: 16px;
}

.widget-workhours ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.widget-workhours li {
  background-color: var(--white-color);
  padding: 14.5px 20px;
  margin: 0 0 15px 0;
  font-family: var(--title-font);
  font-weight: 500;
}

.widget-workhours li:last-child {
  margin-bottom: 0;
}

.widget-workhours li i {
  margin-right: 10px;
}

.quote-box {
  position: relative;
  text-align: center;
  padding: 80px 30px;
  margin: 0 0 30px 0;
}

.quote-box:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 26, 62, 0.7);
}

.quote-box__title {
  color: var(--white-color);
  position: relative;
  z-index: 1;
  max-width: 200px;
  margin: -0.3em auto 25px auto;
}

/* Large devices */
@media (max-width: 1199px) {
  .widget {
    --widget-padding-y: 30px;
    --widget-padding-x: 30px;
  }

  .widget_title {
    font-size: 20px;
    margin-bottom: 25px;
  }

  .recent-post .post-title {
    font-size: 14px;
    line-height: 22px;
  }

  .recent-post .recent-post-meta a {
    font-size: 12px;
  }

  .widget-workhours li {
    padding: 14.5px 20px;
    font-size: 14px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .sidebar-area {
    padding-top: 30px;
  }

  .wp-block-tag-cloud a,
  .tagcloud a {
    padding: 10.5px 18px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .contact-widget .icon {
    --icon-size: 30px;
    --icon-font-size: 12px;
    top: 5px;
  }

  .contact-widget .contact {
    padding: 0 0 0 45px;
    margin: 0 0 20px 0;
    border-bottom: none;
  }

  .sidebar-gallery {
    gap: 10px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .widget {
    padding: 30px 20px;
  }
}

.footer-widget {
  margin-bottom: 40px;
}

.footer-widget,
.footer-widget .widget {
  padding: 0;
  border: none;
  padding-bottom: 0;
  background-color: transparent;
}

.footer-widget .widget_title {
  margin: 0 0 27px 0;
}

.footer-widget.widget_meta,
.footer-widget.widget_pages,
.footer-widget.widget_archive,
.footer-widget.widget_categories,
.footer-widget.widget_nav_menu {
  margin-bottom: 10px;
}

.footer-widget.widget_meta a,
.footer-widget.widget_pages a,
.footer-widget.widget_archive a,
.footer-widget.widget_categories a,
.footer-widget.widget_nav_menu a {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: block;
  border: none;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
  max-width: 100%;
  padding: 0;
}

.footer-widget.widget_meta a:hover,
.footer-widget.widget_pages a:hover,
.footer-widget.widget_archive a:hover,
.footer-widget.widget_categories a:hover,
.footer-widget.widget_nav_menu a:hover {
  background-color: transparent;
  color: var(--theme-color);
}

.footer-widget.widget_meta a:before,
.footer-widget.widget_pages a:before,
.footer-widget.widget_archive a:before,
.footer-widget.widget_categories a:before,
.footer-widget.widget_nav_menu a:before {
  display: none;
}

.footer-widget.widget_meta li>span,
.footer-widget.widget_pages li>span,
.footer-widget.widget_archive li>span,
.footer-widget.widget_categories li>span,
.footer-widget.widget_nav_menu li>span {
  width: auto;
  height: auto;
  position: relative;
  background-color: transparent;
  color: var(--body-color);
  line-height: 1;
}

.footer-widget.widget_meta li:last-child a,
.footer-widget.widget_pages li:last-child a,
.footer-widget.widget_archive li:last-child a,
.footer-widget.widget_categories li:last-child a,
.footer-widget.widget_nav_menu li:last-child a {
  margin-bottom: 0;
}

.footer-widget .footer-links>ul {
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr);
}

.footer-widget .footer-links a {
  margin-bottom: 21px;
}

.footer-widget .footer-links a:hover {
  color: #e50707;
}

.footer-logo {
  margin-bottom: 15px;
}

.footer-text {
  margin: -0.3em 0 25px 0;
  max-width: 285px;
}

.footer-info {
  display: flex;
}

.footer-info_group {
  display: flex;
  justify-content: center;
  border-right: 1px solid #152B54;
  padding: 60px 0 60px 0;
}

.footer-info_group:last-child {
  border-right: none;
}

.footer-info_group.style2 {
  border: none;
}

.footer-info_group.style2 .footer-info_icon {
  background-color: transparent;
  border: 1px solid #35496F;
}

.footer-info_icon {
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  background-color: #152B54;
  color: var(--white-color);
  font-size: 30px;
  margin: 0 25px 0 0;
  display: inline-block;
  border-radius: 5px;
}

.footer-info_label {
  color: var(--white-color);
  font-size: 16px;
  font-weight: 600;
  font-family: var(--title-font);
  text-transform: uppercase;
  display: block;
  margin: 0 0 5px 0;
}

.footer-info_link {
  max-width: 190px;
  color: #8A99B4;
  margin: 0 0 -0.15em 0;
}

.footer-info_link a {
  color: inherit;
}

.footer-info_link a:hover {
  color: var(--theme-color);
}

.footer-number {
  border-top: 1px solid #14284F;
  padding: 43px 0 0 0;
  margin: 34px 0 0 0;
  position: relative;
}

.footer-number:before {
  content: "";
  position: absolute;
  top: -1px;
  left: -60px;
  width: 9999px;
  height: 1px;
  border: inherit;
}

.footer-number .info {
  color: var(--body-color);
  margin: 0;
  line-height: 1;
  display: inline-block;
}

.footer-number .info:hover {
  color: var(--theme-color);
}

.footer-number .title {
  margin: 0 0 7px 0;
}

.footer-map {
  max-width: 400px;
  width: 100%;
}

.footer-map frame {
  max-width: 100%;
}

.footer-social .social-title {
  font-family: var(--title-font);
  color: var(--title-color);
  font-size: 20px;
  display: inline-block;
  margin-right: 20px;
  font-weight: 700;
}

.footer-social a {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  margin: 0 5px 0 0;
  color: var(--white-color);
  background-color: #294595;
  text-align: center;
  border-radius: 5px;
}

.footer-social a:last-child {
  margin-right: 0;
}

.footer-social a:hover {
  background-color: var(--white-color);
  color: var(--theme-color);
}

.footer-social.style2 a {
  background-color: var(--white-color);
  outline: 2px solid #e7e8ec;
  outline-offset: -2px;
  color: var(--body-color);
  box-shadow: 8px 13.856px 30px 0px rgba(132, 137, 154, 0.1);
}

.footer-social.style2 a:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
  outline-color: transparent;
}

.footer-newsletter {
  display: flex;
}

.footer-newsletter input {
  background-color: transparent;
  border-radius: 5px 0 0 5px;
  border: 1px solid #14284F;
  height: 55px;
  color: var(--white-color);
  flex: 1;
}

.footer-newsletter input::-moz-placeholder {
  color: var(--white-color);
}

.footer-newsletter input:-ms-input-placeholder {
  color: var(--white-color);
}

.footer-newsletter input::placeholder {
  color: var(--white-color);
}

.footer-newsletter .vs-btn {
  border-radius: 0 5px 5px 0;
}

.footer-newsletter .vs-btn:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.footer-newsletter .vs-btn:after,
.footer-newsletter .vs-btn::before {
  display: none;
}

.footer-schedule table {
  border: none;
  margin: -0.3em 0 0 0;
}

.footer-schedule th,
.footer-schedule td {
  padding: 0 0 10px 0;
  color: var(--body-color);
  border: none;
  font-weight: 400;
}

.footer-number1 {
  font-size: 30px;
  font-weight: 700;
  color: var(--body-color);
  font-family: var(--title-font);
  margin-bottom: 25px;
  display: block;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.footer-mail1,
.footer-address1 {
  padding-left: 25px;
  position: relative;
}

.footer-mail1>i:first-child,
.footer-address1>i:first-child {
  position: absolute;
  left: 0;
  top: 4px;
}

.footer-address1 {
  max-width: 250px;
}

.footer-mail1 {
  color: var(--body-color);
  text-decoration: underline;
}

.footer-mail1 i:first-child {
  top: 2px;
}

.footer-info-list {
  margin: 35px 0 22px 0;
}

.footer-info-list .info {
  position: relative;
  margin-bottom: 21px;
  padding-left: 30px;
  max-width: 250px;
}

.footer-info-list .info i:first-child {
  position: absolute;
  left: 0;
  top: 4px;
  color: var(--theme-color);
}

.newsletter-form2 .form_text {
  margin: -0.3em 0 22px 0;
  display: block;
  max-width: 300px;
}

.newsletter-form2 input {
  background-color: #ebecf1;
  margin-bottom: 15px;
  border-radius: 7px;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .menu-all-pages-container.footer-links {
    width: 330px;
    max-width: 100%;
  }

  .footer-number:before {
    display: none;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .footer-widget {
    margin-bottom: 40px;
  }

  .footer-widget .widget_title {
    font-size: 26px;
  }

  .footer-number {
    padding: 27px 0 0 0;
    margin: 17px 0 0 0;
  }

  .footer-info {
    display: block;
    text-align: center;
  }

  .footer-info_icon {
    margin: 0 0 20px 0;
  }

  .footer-info_group {
    padding: 0;
    border: none;
  }
}

@media (min-width: 991px) and (max-width: 1399px) {
  .footer-newsletter {
    display: block;
  }

  .footer-newsletter input {
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .footer-newsletter .vs-btn {
    border-radius: 5px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .footer-widget .widget_title {
    font-size: 22px;
  }

  .footer-layout1 .footer-widget.widget_meta a,
  .footer-layout1 .footer-widget.widget_pages a,
  .footer-layout1 .footer-widget.widget_archive a,
  .footer-layout1 .footer-widget.widget_categories a,
  .footer-layout1 .footer-widget.widget_nav_menu a {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Small devices */
@media (max-width: 767px) {

  .footer-widget.widget_meta a,
  .footer-widget.widget_pages a,
  .footer-widget.widget_archive a,
  .footer-widget.widget_categories a,
  .footer-widget.widget_nav_menu a {
    font-size: 14px;
  }

  .footer-newsletter {
    display: block;
  }

  .footer-newsletter input {
    border-radius: 5px;
    margin-bottom: 20px;
  }

  .footer-newsletter .vs-btn {
    border-radius: 5px;
  }
}

/*------------------- 4.2. Header  -------------------*/
.vs-header {
  position: relative;
  z-index: 41;
}

.will-sticky .sticky-active {
  position: fixed;
  top: -100%;
  right: 0;
  left: 0;
  background-color: var(--white-color);
  transition: all ease 0.8s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
}

.will-sticky .sticky-active.active {
  top: 0;
}

.main-menu a {
  display: block;
  position: relative;
  font-family: var(--title-font);
  font-weight: 600;
  font-size: 16px;
  color: var(--title-color);
}

.main-menu a:hover {
  color: var(--theme-color);
}

.main-menu>ul>li {
  margin: 0 18px;
}

.main-menu>ul>li>a .new-label {
  position: absolute;
  top: -10px;
  right: -27px;
  font-size: 11px;
  border-radius: 3px;
}

.main-menu>ul>li>a .has-new-lable {
  position: relative;
}

.main-menu ul {
  margin: 0;
  padding: 0;
}

.main-menu ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
}

.main-menu ul li.menu-item-has-children>a:after {
  content: "\f078";
  position: relative;
  font-family: var(--icon-font);
  margin-left: 5px;
  font-size: 0.8rem;
}

.main-menu ul li:last-child {
  margin-right: 0;
}

.main-menu ul li:first-child {
  margin-left: 0;
}

.main-menu ul li:hover>ul.sub-menu,
.main-menu ul li:hover ul.mega-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  z-index: 9;
}

.main-menu ul.sub-menu,
.main-menu ul.mega-menu {
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background-color: var(--white-color);
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  visibility: hidden;
  min-width: 190px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 7px;
  left: -14px;
  margin-top: 50px;
  opacity: 0;
  z-index: -1;
  border-bottom: 3px solid var(--theme-color);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.09), 0px 3px 0px 0px rgba(231, 13, 60, 0.004);
  transform-origin: top center;
  transition: margin-top 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, z-index 0s;
}

.main-menu ul.sub-menu a,
.main-menu ul.mega-menu a {
  font-size: 16px;
  line-height: 30px;
}

.main-menu ul.sub-menu {
  padding: 18px 20px;
  left: -27px;
}

.main-menu ul.sub-menu:before {
  content: "";
  position: absolute;
  left: 34.5px;
  top: 30px;
  width: 1px;
  background-color: #ededed;
  height: calc(100% - 60px);
}

.main-menu ul.sub-menu li {
  display: block;
  margin: 0 0;
  padding: 3px 9px;
}

.main-menu ul.sub-menu li.menu-item-has-children>a:after {
  content: "\f105";
  float: right;
  top: 3px;
}

.main-menu ul.sub-menu li a {
  position: relative;
  padding-left: 21px;
}

.main-menu ul.sub-menu li a:before {
  content: "\f111";
  position: absolute;
  top: 2.5em;
  left: 0;
  font-family: var(--icon-font);
  width: 11px;
  height: 11px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  font-size: 0.2em;
  line-height: 11.5px;
  color: var(--theme-color);
  font-weight: 700;
  background-color: var(--white-color);
  box-shadow: inset 0px 2px 4px 0px rgba(173, 136, 88, 0.4);
}

.main-menu ul.sub-menu li ul.sub-menu {
  left: 100%;
  right: auto;
  top: 0;
  margin: 0 0;
  margin-left: 20px;
}

.main-menu ul.sub-menu li ul.sub-menu li ul {
  left: 100%;
  right: auto;
}

.main-menu .mega-menu-wrap {
  position: static;
}

.main-menu ul.mega-menu {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  max-width: var(--main-container);
  padding: 20px 15px 23px 15px;
  left: 50%;
  transform: translateX(-50%);
}

.main-menu ul.mega-menu .main-menu ul.mega-menu>li>ul>li>a {
  position: relative;
}

.main-menu ul.mega-menu li {
  display: block;
  width: 100%;
  padding: 0 15px;
}

.main-menu ul.mega-menu li li {
  padding: 4px 0;
}

.main-menu ul.mega-menu li a {
  display: inline-block;
}

.main-menu ul.mega-menu>li>a {
  display: block;
  padding: 0;
  padding-bottom: 15px;
  margin-bottom: 10px;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-weight: 700;
  color: var(--title-color);
  border-color: var(--theme-color);
}

.main-menu ul.mega-menu>li>a::after,
.main-menu ul.mega-menu>li>a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 15px;
  height: 1px;
  background-color: var(--theme-color);
}

.main-menu ul.mega-menu>li>a::after {
  width: calc(100% - 20px);
  left: 20px;
}

.main-menu ul.mega-menu>li>a:hover {
  padding-left: 0;
}

.main-menu ul.mega-menu>li>ul>li>a:before {
  content: "\f105";
  font-family: var(--icon-font);
  font-weight: 400;
  margin: 0 10px 0 0;
}

.menu-style1>ul>li>a {
  padding: 16.5px 0;
}

.menu-style2>ul>li>a {
  padding: 41.5px 0;
}

.menu-style3>ul>li>a {
  padding: 45px 0;
}

.menu-style4>ul>li>a {
  padding: 49.5px 0;
  position: relative;
}

.menu-style4>ul>li>a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--theme-color);
  width: 100%;
  height: 2px;
  transition: all ease 0.4s;
  transform: scale(0);
  opacity: 0;
  visibility: hidden;
}

.menu-style4>ul>li>a:hover:before {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.menu-style5>ul>li {
  margin: 0 13px;
}

.menu-style5>ul>li>a {
  padding: 51.5px 0;
}

.menu-style5>ul>li:hover ul.mega-menu,
.menu-style5>ul>li:hover ul.sub-menu {
  margin-top: -1px;
}

.header-text {
  margin: 0;
}

.header-text i {
  margin-right: 10px;
}

.header-info {
  display: flex;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 28px;
  margin-left: 40px;
  padding-left: 40px;
  border-left: 1px solid #E7EBF3;
}

.header-info:nth-child(2) {
  margin-left: 0;
  padding-left: 0;
  border-left: none;
}

.header-info_icon {
  color: var(--theme-color);
  font-size: 24px;
  margin-right: 20px;
}

.header-info_label {
  font-size: 14px;
  color: var(--body-color);
  display: block;
  margin-bottom: 2px;
}

.header-info_link {
  color: var(--title-color);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.header-info_link a {
  color: inherit;
}

.header-info_link a:hover {
  color: var(--theme-color);
}

.header-info.style2 {
  padding: 0;
  border: none;
  margin: 0;
}

.header-info.style2 .header-info_icon {
  width: 60px;
  height: 60px;
  line-height: 56px;
  background-color: var(--theme-color);
  text-align: center;
  border-radius: 50%;
  margin-right: 15px;
}

.header-info.style2 .header-info_label {
  color: var(--title-color);
  text-transform: capitalize;
  font-weight: 500;
}

.header-info.style2 .header-info_link {
  color: var(--theme-color);
  font-weight: 400;
  font-size: 24px;
}

.header-info.style2 .header-info_link a:hover {
  color: var(--title-color);
}

.header-search {
  display: flex;
  width: 340px;
  max-width: 100%;
}

.header-search input {
  flex: 1;
  border: none;
  padding: 0 20px 0 20px;
  height: 35px;
  width: 100%;
  border-radius: 4px;
  transition: all ease 0.4s;
}

.header-search button {
  border: none;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 13px;
  width: 35px;
  height: 35px;
  border-radius: 4px;
}

.header-search button:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.header-search input:focus {
  padding: 0 20px 0 20px;
  background-color: var(--smoke-color);
}

.header-links ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.header-links li {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: var(--title-color);
  font-family: var(--title-font);
  padding: 0 30px 0 0;
  margin: 0 26px 0 0;
  border-right: 1px solid #CAD4F1;
  line-height: 23px;
}

.header-links li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.header-links i {
  color: var(--theme-color);
  margin: 0 10px 0 0;
}

.header-links a {
  color: inherit;
}

.header-links a:hover {
  color: var(--theme-color);
}

.header-links.style-white i,
.header-links.style-white li {
  color: var(--white-color);
  border-color: #558EFF;
}

.header-social {
  font-size: 14px;
}

.header-social .social-title {
  color: var(--title-color);
  display: inline-block;
}

.header-social a {
  display: inline-block;
  color: var(--title-color);
  margin-left: 16px;
}

.header-social a:hover {
  color: var(--theme-color);
}

.header-social.style-white .social-title,
.header-social.style-white a {
  color: var(--white-color);
}

.header-social.style-white a:hover {
  color: var(--title-color);
}

.logofooter {
  margin-bottom: 15px;
  width: 150px;
}

.logo-style1 {
  background: linear-gradient(278.46deg, #00BCFA 0.91%, #0E59F2 71.89%, #0E59F2 100%);
  padding: 30px 35px 30px 35px;
  border-radius: 5px 0px 0px 5px;
  line-height: 0;
  margin: -1px 0;
}

.header-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.header-box {
  border: 10px solid var(--theme-color);
  background-color: var(--white-color);
  padding: 20px;
}

.header-box__icon {
  border-radius: 5px;
  margin: 0 15px 0 0;
  font-size: 18px;
  width: 43px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  display: inline-block;
  background-color: var(--theme-color);
  color: var(--white-color);
}

.header-box__title {
  display: inline-block;
  color: var(--title-color);
  font-weight: 600;
  font-family: var(--title-font);
}

.header-box__number {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-family: var(--title-font);
  font-size: 22px;
  font-weight: 700;
  color: var(--theme-color);
  margin: 15px 0 0 0;
}

.header-dropdown>.dropdown-toggle {
  color: var(--title-color);
}

.header-dropdown>.dropdown-toggle i {
  margin: 0 10px 0 0;
}

.header-dropdown>.dropdown-toggle:after {
  content: "\f107";
  font-family: var(--icon-font);
  border: none;
  vertical-align: middle;
  position: relative;
  top: -1px;
  margin-left: 5px;
}

.header-dropdown>.dropdown-toggle:hover {
  color: var(--theme-color);
}

.header-dropdown ul.dropdown-menu {
  padding: 10px 15px;
  border: none;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.07);
  margin: 0;
  margin-left: -15px !important;
  margin-top: 15px !important;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  min-width: auto;
  position: relative;
  z-index: 1;
  border: none;
}

.header-dropdown ul.dropdown-menu li {
  margin-bottom: 10px;
}

.header-dropdown ul.dropdown-menu li:last-child {
  margin-bottom: 0;
}

.header-dropdown ul.dropdown-menu a {
  display: block;
  margin: 0 0;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  padding: 2px 10px;
  color: var(--title-color);
  font-weight: 400;
  font-size: 14px;
}

.header-dropdown ul.dropdown-menu a:hover {
  color: var(--theme-color);
}

.header-dropdown ul.dropdown-menu a:first-child {
  margin-top: 0;
  padding-top: 0;
}

.header-dropdown ul.dropdown-menu a:last-of-type,
.header-dropdown ul.dropdown-menu a:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

.header-dropdown ul.dropdown-menu:before {
  content: "";
  position: absolute;
  left: 47px;
  top: -7px;
  width: 14px;
  height: 14px;
  background-color: var(--white-color);
  z-index: -1;
  transform: rotate(45deg);
  border-top: 1px solid #ededed;
  border-left: 1px solid #ededed;
}

.header-dropdown.style-white>.dropdown-toggle {
  color: var(--white-color);
}

.header-dropdown.style-white>.dropdown-toggle:hover {
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.7);
}

.login-tab {
  display: flex;
  border-radius: 9999px;
  position: relative;
  z-index: 1;
}

.login-tab:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  border: 2px solid var(--theme-color);
  z-index: -1;
}

.login-tab a {
  display: inline-block;
  color: var(--theme-color);
  background-color: transparent;
  font-family: var(--title-font);
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  padding: 18.5px 37px;
  line-height: 1;
  border-radius: inherit;
}

.login-tab a:hover,
.login-tab a.active {
  color: var(--white-color);
}

.login-tab .indicator {
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0;
  background-color: var(--theme-color);
  z-index: -1;
  transition: all ease 0.4s;
  border-radius: inherit;
}

.header-layout3 .header-top,
.header-layout1 .header-top {
  background-color: var(--theme-color);
  padding: 9.5px 0;
}

.header-layout1 .menu-top {
  border-bottom: 1px solid #E7EBF3;
}

.header-layout1 .header-text {
  color: var(--white-color);
}

.header-layout2 {
  position: relative;
  margin-bottom: -55px;
}

.header-layout2 .header-shape {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 55px);
  z-index: -1;
  background-color: #F6F7FA;
}

.header-layout2 .header-top {
  padding: 27px 0;
}

.header-layout2 .menu-area {
  background-color: var(--white-color);
  padding: 0 30px 0 0;
  border-radius: 5px;
  box-shadow: 0px 17px 29px rgba(32, 56, 105, 0.18);
}

.header-layout2 .vs-menu-toggle {
  border-radius: 5px;
}

.header-layout2 .will-sticky .sticky-active {
  box-shadow: none;
  background-color: transparent;
}

.header-layout3 {
  position: relative;
}

@media (min-width: 1199px) {

  .header-layout3 .header-box-wrap,
  .header-layout3 .header-top,
  .header-layout3 .sticky-wrapper:not(.will-sticky) {
    --box-width: 290px;
    --main-container: 1680px;
  }

  .header-layout3 .header-box-wrap .container,
  .header-layout3 .header-top .container,
  .header-layout3 .sticky-wrapper:not(.will-sticky) .container {
    padding-right: var(--box-width);
  }

  .header-layout3 .header-box {
    width: calc(var(--box-width) - 40px);
    position: absolute;
    right: 0;
    top: 0;
  }
}

@media (max-width: 1500px) and (min-width: 1199px) {
  .header-layout3 {
    --main-container: 1320px;
  }

  .header-layout3 .main-menu>ul>li {
    margin: 0 12px;
  }
}

.header-layout5 {
  position: relative;
}

@media (min-width: 1500px) {
  .header-layout5 {
    --main-container: 1430px;
  }
}

.header-layout5 .header-shape1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 63px);
  background-color: var(--theme-color);
  z-index: -1;
}

.header-layout5 .menu-area {
  background-color: var(--white-color);
  z-index: 1;
}

@media (min-width: 1500px) {
  .header-layout5 .menu-area {
    max-width: 1518px;
    margin: 0 auto;
    padding: 0 20px;
  }
}

.header-layout5 .header-top {
  padding: 12.5px 0;
  position: relative;
  z-index: 2;
}

@media (min-width: 767px) {
  .header-layout5 .header-social {
    border-left: 1px solid #558EFF;
    padding-left: 4px;
  }
}

.header-layout5 .header-social a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.header-layout5 .header-links a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.header-layout6 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  max-width: 1390px;
  margin-left: auto;
  margin-right: auto;
  --clip-total: 80px;
  --shape-gap: 17px;
}

.header-layout6 .shape1 {
  position: absolute;
  left: var(--pos, 0);
  right: var(--pos, 0);
  top: var(--pos, 0);
  bottom: var(--pos, 0);
  background-color: var(--white-color);
  z-index: -1;
  -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 80px) 99%, 80px 100%);
  clip-path: polygon(0 0, 100% 0, calc(100% - 80px) 99%, 80px 100%);
}

.header-layout6 .shape2 {
  position: absolute;
  border-bottom: 2px solid var(--white-color);
  left: calc(var(--clip-total) - var(--shape-gap));
  right: calc(var(--clip-total) - var(--shape-gap));
  bottom: calc(var(--shape-gap) / -1);
}

.header-layout6 .shape3:after,
.header-layout6 .shape3:before {
  content: "";
  position: absolute;
  right: calc((var(--shape-gap) + 11px) / -1);
  top: 0;
  width: 2px;
  height: calc(100% + 43px);
  background-color: var(--white-color);
  transform: rotate(32deg);
  transform-origin: top left;
}

.header-layout6 .shape3::before {
  right: auto;
  left: calc((var(--shape-gap) + 11px) / -1);
  transform: rotate(-32deg);
  transform-origin: top right;
}

@media (min-width: 1500px) {
  .header-layout6 .container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

/* Hight Resoulation devices */
@media (min-width: 1921px) {
  .logo-style1 {
    margin-bottom: -1px;
  }
}

/* Extra large devices */
@media (max-width: 1500px) {
  .header-layout6 {
    position: relative;
  }

  .header-layout6 .shape3,
  .header-layout6 .shape2,
  .header-layout6 .shape1 {
    display: none;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .header-layout3 .main-menu>ul>li {
    margin: 0 13px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .header-search {
    width: 200px;
  }

  .main-menu>ul>li {
    margin: 0 14px;
  }

  .header-layout2 {
    margin: 0;
  }

  .header-layout2 .header-shape {
    height: 100%;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .header-links li {
    padding-right: 15px;
    margin-right: 10px;
  }

  .header-logo {
    padding: 15px 0;
  }

  .logo-style1 {
    padding: 20px 20px 20px 20px;
  }

  .header-layout1 .vs-menu-toggle {
    margin: 10px 0;
  }

  .header-layout2 .menu-area {
    padding: 0 20px 0 0;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .header-info:nth-child(2) {
    padding-left: 10px;
  }

  .header-info_label {
    font-size: 12px;
  }

  .header-info_link {
    font-size: 14px;
  }

  .header-info_icon {
    font-size: 20px;
    margin-right: 10px;
  }

  .header-links li {
    padding-right: 10px;
    margin-right: 0;
    border-right: none;
    font-size: 12px;
  }

  .header-links li i {
    margin-right: 5px;
  }

  .logo-style1 {
    padding: 15px 15px 15px 15px;
    max-width: 200px;
  }

  .header-layout2 .header-top {
    padding: 13px 0;
  }
}

/*------------------- 4.3. Footer  -------------------*/
.widget-area {
  padding-top: 48px;
  padding-bottom: 10px;
}

.copyright-wrap {
  text-align: center;
  padding: 29px 0;
  background-color: #2d3b4b;
}

.copyright-text {
  margin: 0;
  font-size: 14px;
}

.footer-layout3,
.footer-layout2,
.footer-layout1 {
  --body-color: #fff;
  --title-color: #fff;
}

.footer-layout1 {
  background-color: #83ade1;
}

.footer-layout1 .footer-top {
  border-bottom: 1px solid #559FF8;
}

.footer-layout2 {
  background-color: #071A3E;
  overflow: hidden;
}

.footer-layout2 .footer-top {
  border-bottom: 1px solid #559FF8;
  padding: 50px 0;
}

.footer-layout2 .footer-text {
  color: #fff;
  max-width: 390px;
  margin-bottom: 15px;
}

.footer-layout2 .widget-area {
  padding: 50px 0 0px 0;
}

@media (min-width: 1399px) {
  .footer-layout2 .footer-widget {
    padding: 0;
    margin: 0;
    height: 100%;
  }

  .footer-layout2 .widget-area {
    padding: 0;
  }

  .footer-layout2 .widget-area .row>div {
    padding-top: 50px;
    padding-bottom: 44px;
  }

  .footer-layout2 .widget-area .row>div:nth-child(2) {
    text-align: center;
    padding-left: 60px;
    padding-right: 60px;
  }

  .footer-layout2 .widget-area .row>div:nth-child(3) {
    padding-left: 60px;
  }
}

.footer-layout3 {
  background-color: #071A3E;
}

.footer-layout3 .footer-top {
  background-color: #0B2048;
}

.footer-layout3 .copyright-wrap {
  background-color: #0B1F44;
}

.footer-layout4 {
  padding-bottom: 60px;
  position: relative;
}

.footer-layout4 .shape1 {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 550px;
}

.footer-layout4 .footer-top {
  padding-top: var(--section-space);
  padding-bottom: var(--section-space);
  border-bottom: 1px solid #436af7;
}

.footer-layout4 .widget-area {
  --title-color: #fff;
  --body-color: #e6ebff;
  padding-top: 80px;
  padding-bottom: 20px;
}

.footer-layout4 .widget-area a:hover {
  color: #fff;
}

.footer-layout4 .copyright-wrap {
  background-color: #1648ff;
  padding: 20.5px 40px;
}

.footer-layout4 .copyright-wrap .copyright-text {
  color: var(--white-color);
}

.footer-layout4 .widget_title {
  font-weight: 600;
  margin-bottom: 35px;
}

.footer-layout4 .post-title a {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  background-image: linear-gradient(white, white);
  background-size: 0 1px;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.footer-layout4 .post-title a:hover {
  color: var(--white-color);
  background-size: 100% 1px;
}

.footer-layout4 .recent-post-meta a {
  font-size: 12px;
}

.footer-layout4 .recent-post-meta a i {
  color: inherit;
  margin-right: 10px;
}

.footer-layout4 .footer-widget.widget_nav_menu a {
  padding-left: 15px;
  margin-bottom: 23px;
}

.footer-layout4 .footer-widget.widget_nav_menu a:before {
  content: "\f105";
  display: block;
  right: auto;
  left: 0;
  top: -1px;
  color: var(--white-color);
}

.footer-layout4 .recent-post .post-title {
  max-width: 200px;
}

.footer-layout4 .gallery-thumb,
.footer-layout4 .gallery-thumb img,
.footer-layout4 .recent-post .media-img img {
  border-radius: 0;
}

.footer-layout5 {
  border-top: 2px solid var(--theme-color);
  background-color: #fafafb;
}

@media (min-width: 1199px) {
  .footer-layout5 {
    padding-top: 192px;
  }
}

.footer-layout5 .widget-area {
  padding-top: 88px;
  padding-bottom: 50px;
}

.footer-layout5 .widget_title {
  margin-bottom: 55px;
  margin-top: 10px;
  font-weight: 600;
}

.footer-layout5 .copyright-wrap {
  background-color: #fafafb;
  border-top: 1px solid #e6e8f2;
  padding: 24px 0;
}

.footer-layout5 .copyright-text {
  color: var(--body-color);
}

/* Extra large devices */
@media (max-width: 1500px) {
  .footer-layout4 .shape1 {
    max-width: 380px;
  }
}

/* Large devices */
@media (max-width: 1199px) {

  .footer-layout3 .footer-top,
  .footer-layout1 .footer-top {
    padding: 50px 0;
  }

  .footer-layout3 .footer-top .row,
  .footer-layout1 .footer-top .row {
    --bs-gutter-y: 40px;
  }

  .footer-layout5 .widget_title {
    margin-bottom: 30px;
  }

  .footer-layout4 .shape1 {
    max-width: 230px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .footer-layout1 {
    text-align: center;
  }

  .footer-layout1 .footer-text {
    margin-left: auto;
    margin-right: auto;
  }

  .footer-layout2 .footer-text {
    max-width: 100%;
  }

  .footer-layout2 .footer-top-logo {
    text-align: center;
  }

  .footer-layout2 .widget-area {
    padding: 50px 0 10px 0;
  }

  .footer-layout2 .footer-widget {
    margin-bottom: 40px;
  }

  .footer-layout4 .shape1 {
    display: none;
  }

  .footer-layout4 .footer-top {
    padding-top: var(--section-space-mobile);
    padding-bottom: var(--section-space-mobile);
  }
}

/* Small devices */
@media (max-width: 767px) {
  .footer-layout2 .footer-social {
    text-align: center;
  }

  .footer-layout2 .footer-social .social-title {
    display: block;
    margin: 0 0 20px 0;
  }

  .footer-layout4 .copyright-wrap {
    padding: 20.5px 10px;
  }
}

/*------------------- 4.4. Breadcumb  -------------------*/
.breadcumb-menu {
  max-width: 100%;
  margin: -0.35em 0 -0.35em 0;
  padding: 0;
  list-style-type: none;
}

.breadcumb-menu li {
  display: inline-block;
  list-style: none;
  position: relative;
}

.breadcumb-menu li:after {
  content: "/";
  position: relative;
  margin: 0 5px 0 10px;
}

.breadcumb-menu li:last-child:after {
  display: none;
}

.breadcumb-menu li,
.breadcumb-menu a,
.breadcumb-menu span {
  white-space: normal;
  color: inherit;
  word-break: break-word;
  font-weight: 500;
  font-size: 16px;
  font-family: var(--title-font);
  color: var(--white-color);
}

.breadcumb-title {
  color: var(--white-color);
  margin: -0.3em 0 -0.35em 0;
  max-width: 60%;
  width: 100%;
}

.breadcumb-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 40px;
}

.breadcumb-wrapper {
  padding-top: 140px;
  padding-bottom: 135px;
  background-color: var(--title-color);
  /* Medium devices */
}

@media (max-width: 991px) {
  .breadcumb-wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .breadcumb-content {
    display: block;
  }

  .breadcumb-title {
    max-width: 100%;
  }

  .breadcumb-menu {
    margin-top: 15px;
  }
}

/*------------------- 4.5. Pagination  -------------------*/
.vs-pagination {
  margin-bottom: 30px;
}

.vs-pagination ul {
  margin: 0;
  padding: 0;
}

.vs-pagination li {
  display: inline-block;
  margin: 0 3px;
  list-style-type: none;
}

.vs-pagination li:last-child {
  margin-right: 0;
}

.vs-pagination li:first-child {
  margin-left: 0;
}

.vs-pagination span,
.vs-pagination a {
  display: inline-block;
  font-family: var(--body-font);
  color: var(--title-color);
  text-align: center;
  position: relative;
  border: none;
  width: 56px;
  height: 56px;
  line-height: 56px;
  font-weight: 400;
  font-size: 20px;
  z-index: 1;
  background-color: #F5F9FF;
  border-radius: 5px;
}

.vs-pagination span.active,
.vs-pagination span:hover,
.vs-pagination a.active,
.vs-pagination a:hover {
  color: var(--white-color);
  background-color: var(--theme-color);
  box-shadow: none;
}

.post-pagination {
  border-top: 1px solid #F0F0F0;
  border-bottom: 1px solid #F0F0F0;
  padding: 30px 0;
  margin: 30px 0;
}

.post-pagi-box {
  display: flex;
  align-items: center;
}

.post-pagi-box>a {
  color: var(--title-color);
  font-weight: 600;
  font-size: 16px;
  font-family: var(--title-font);
}

.post-pagi-box>a:hover {
  color: var(--theme-color);
}

.post-pagi-box img {
  width: 80px;
  margin-right: 25px;
}

.post-pagi-box.next {
  flex-direction: row-reverse;
}

.post-pagi-box.next img {
  margin-right: 0;
  margin-left: 25px;
}

.pagi-icon {
  color: #F0F0F0;
  font-size: 40px;
}

/* Small devices */
@media (max-width: 767px) {

  .vs-pagination span,
  .vs-pagination a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    padding: 0 10px;
  }

  .post-pagi-box>a {
    font-size: 12px;
  }

  .post-pagi-box img {
    width: 40px;
    margin-right: 15px;
  }

  .post-pagi-box.next img {
    margin-right: 0;
    margin-left: 15px;
  }
}

/*------------------- 4.6. Blog  -------------------*/
blockquote {
  display: block;
  position: relative;
  overflow: hidden;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--title-color);
  background-color: var(--quote-bg, #EFF1F5);
  border-left: 4px solid var(--theme-color);
  padding: 43px 50px 42px 35px;
  margin: 35px 0;
}

blockquote p {
  font-family: inherit;
  color: inherit;
  z-index: 3;
  width: 100%;
  margin-bottom: 0 !important;
  line-height: 1.5;
  position: relative;
}

blockquote:before {
  content: "\f10e";
  font-family: var(--icon-font);
  position: absolute;
  right: 40px;
  bottom: 30px;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1;
  color: var(--theme-color);
  opacity: 0.3;
}

blockquote p {
  margin-bottom: 0;
}

blockquote p a {
  color: inherit;
}

blockquote cite {
  color: var(--theme-color);
  font-family: var(--title-font);
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  position: relative;
  padding-left: 45px;
  line-height: 1;
  margin-top: 20px;
  font-style: normal;
}

blockquote cite:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 8px;
  width: 30px;
  height: 2px;
  border-top: 2px solid var(--theme-color);
}

blockquote.vs-quote {
  text-align: center;
  padding: 40px 50px;
  border-left: 2px solid var(--theme-color);
}

blockquote.vs-quote:before {
  position: relative;
  bottom: 0;
  right: 0;
  font-weight: 300;
  opacity: 1;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 34px;
  display: inline-block;
  margin: 0 0 28px 0;
  border-radius: 50%;
}

blockquote.vs-quote cite {
  padding: 0;
  margin-top: 23px;
}

blockquote.vs-quote cite::before {
  display: none;
}

blockquote.vs-quote .quote-author {
  display: block;
  font-size: 14px;
  color: #74787C;
  font-family: var(--title-font);
  display: block;
  font-weight: 400;
}

.blog-meta span,
.blog-meta a {
  display: inline-block;
  margin-right: 16px;
  font-size: 16px;
  color: #8B919C;
}

.blog-meta span:last-child,
.blog-meta a:last-child {
  margin-right: 0;
}

.blog-meta span i,
.blog-meta a i {
  margin-right: 10px;
  color: var(--theme-color);
}

.blog-meta a:hover {
  color: var(--theme-color);
}

.blog-category {
  margin-bottom: -10px;
}

.blog-category a {
  display: inline-block;
  color: var(--white-color);
  padding: 4.5px 24.5px;
  margin-right: 5px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  background-color: var(--theme-color);
}

.blog-category a:hover {
  background-color: var(--white-color);
  color: var(--body-color);
  border-color: var(--theme-color);
}

.blog-title a {
  color: inherit;
}

.blog-title a:hover {
  color: var(--theme-color);
}

.vs-blog {
  margin-bottom: 30px;
}

.blog-inner-title {
  font-size: 30px;
  margin-top: -0.2em;
  margin-bottom: 8px;
}

.blog-single {
  position: relative;
  margin-bottom: 40px;
  background: var(--white-color);
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
  padding: 0.1px 0 0.1px 0;
}

.blog-single .blog-meta {
  margin: -0.2em 0 10px 0;
}

.blog-single .blog-title {
  font-size: 30px;
  margin-bottom: 17px;
}

.blog-single .link-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
  margin-top: 26px;
}

.blog-single .blog-date {
  color: var(--body-color);
}

.blog-single .blog-date i {
  color: var(--theme-color);
  margin: 0 10px 0 0;
}

.blog-single .share-links-title {
  font-size: 16px;
  font-weight: 600;
  color: var(--title-color);
  font-family: var(--title-font);
  margin: 0 15px 0 0;
  display: inline-block;
}

.blog-single .share-links {
  margin: 16px 0 0 0;
}

.blog-single .share-links .row {
  align-items: center;
  --bs-gutter-y: 15px;
}

.blog-single .share-links .tagcloud {
  display: inline-block;
}

.blog-single .share-links .tagcloud a {
  padding: 0;
  border-radius: 0;
  color: var(--body-color);
}

.blog-single .share-links .tagcloud a:not(:last-child):after {
  content: ",";
}

.blog-single .social-links {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: inline-block;
}

.blog-single .social-links li {
  display: inline-block;
  margin-right: 13px;
}

.blog-single .social-links li:last-child {
  margin-right: 0;
}

.blog-single .social-links a {
  line-height: 1;
  font-size: 16px;
  color: var(--body-color);
  text-align: center;
  display: block;
}

.blog-single .social-links a:hover {
  color: var(--theme-color);
}

.blog-single .blog-content {
  margin: var(--blog-space-y, 40px) var(--blog-space-x, 40px) var(--blog-space-y, 40px) var(--blog-space-x, 40px);
  padding: 0;
  position: relative;
  border-radius: 0 0 5px 5px;
}

.blog-single .blog-audio {
  line-height: 1;
}

.blog-single .blog-audio,
.blog-single .blog-img {
  position: relative;
  background-color: var(--smoke-color);
}

.blog-single .blog-audio img,
.blog-single .blog-audio>*,
.blog-single .blog-img img,
.blog-single .blog-img>* {
  border-radius: 5px 5px 0 0;
}

.blog-single .blog-img .slick-arrow {
  --pos-x: 30px;
  border: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 5px;
}

.blog-single .blog-img .slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.blog-single .blog-img .play-btn {
  --icon-size: 80px;
  --icon-font-size: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: calc(var(--icon-size) / -2) 0 0 calc(var(--icon-size) / -2);
}

.blog-single:hover .blog-img .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.blog-author {
  background-color: var(--smoke-color);
  padding: 30px;
  display: flex;
  align-items: center;
  margin: var(--blog-space-y, 60px) 0;
}

.blog-author .media-img {
  width: 189px;
  margin: 0 30px 0 0;
}

.blog-author .author-name {
  font-size: 24px;
  font-weight: 700;
  margin: -0.3em 0 0px 0;
}

.blog-author .author-degi {
  color: var(--theme-color);
}

.blog-author .author-text {
  margin: 0 0 -0.1em 0;
}

.blog-details .blog-single {
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.blog-details .blog-img {
  margin: 0 0 40px 0;
}

.blog-details .blog-img img {
  border-radius: 0;
}

.blog-details .blog-content {
  margin: 0;
}

.blog-style1 {
  background-color: var(--white-color);
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.07);
  border-radius: 5px;
}

.blog-style1 .blog-meta {
  margin: 0 0 10px 0;
}

.blog-style1 .blog-content {
  padding: 35px 40px 33px 40px;
}

.blog-style2 {
  background-color: var(--white-color);
  box-shadow: none;
}

.blog-style2 .blog-meta {
  margin: 0 0 15px;
}

.blog-style2 .blog-text {
  margin: 0 0 20px 0;
}

.blog-style2 .blog-content {
  border: 2px solid #ECF2FD;
  border-top: none;
  padding: 35px 40px 40px 40px;
}

.blog-style3 .blog-img {
  overflow: hidden;
  margin: -2px;
}

.blog-style3 .blog-img img {
  transform: scale(1);
  transition: all ease 0.4s;
}

.blog-style3 .blog-body {
  border: 2px solid #f4f5f8;
}

.blog-style3:nth-child(odd) .blog-body {
  display: flex;
  flex-direction: column-reverse;
}

.blog-style3 .blog-content {
  padding: 35px 40px 30px 40px;
}

.blog-style3 .blog-meta {
  margin-bottom: 15px;
}

.blog-style3 .blog-meta i {
  color: var(--body-color);
}

.blog-style3 .blog-meta a {
  position: relative;
  line-height: 1;
  border-right: 1px solid #c8c8c8;
  margin-right: 12px;
  padding: 0 15px 0 0;
}

.blog-style3 .blog-meta a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.blog-style3 .blog-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.blog-style3 .link-btn {
  text-transform: capitalize;
}

.blog-style3:hover .blog-img img {
  transform: scale(1.1);
}

.blog-style4 {
  box-shadow: 8.5px 0 60px 0px rgba(166, 170, 184, 0.05);
  border-radius: 20px;
  padding: 20px;
  border: 2px solid #eff2fb;
}

.blog-style4 .blog-content {
  padding: 0 20px 10px 20px;
}

.blog-style4 .blog-img {
  overflow: hidden;
  border-radius: 20px;
  margin: 0 0 32px 0;
}

.blog-style4 .blog-img img {
  transform: scale(1);
  transition: all ease 0.4s;
}

.blog-style4 .blog-meta {
  margin-bottom: 4px;
}

.blog-style4 .blog-meta i {
  color: var(--body-color);
}

.blog-style4 .blog-meta a {
  font-size: 14px;
}

.blog-style4 .blog-title {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 22px;
}

.blog-style4:hover .blog-img img {
  transform: scale(1.2);
}

@media (min-width: 1199px) {
  .blog-style4-slider {
    max-width: 775px;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .blog-style2 .blog-content {
    padding: 30px 25px 35px 25px;
  }

  .blog-style1 .blog-content {
    padding: 35px 25px 33px 25px;
  }

  .blog-style1 .blog-title {
    font-size: 18px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  blockquote {
    font-size: 20px;
    padding: 23px 30px 26px 30px;
  }

  blockquote cite {
    font-size: 16px;
  }

  .blog-single {
    --blog-space-y: 40px;
    --blog-space-x: 30px;
  }

  .blog-single .blog-title {
    font-size: 24px;
  }

  .blog-meta span,
  .blog-meta a {
    font-size: 14px;
  }

  .blog-style3 .blog-content {
    padding: 25px 25px 30px 25px;
  }

  .blog-style4 {
    padding: 10px;
  }

  .blog-style4 .blog-content {
    padding: 0 15px 20px 15px;
  }

  .blog-style4 .blog-title {
    margin-bottom: 15px;
    font-size: 20px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  blockquote.vs-quote {
    padding: 40px 15px;
  }

  blockquote.vs-quote p {
    line-height: 1.8;
  }

  blockquote.vs-quote cite {
    padding: 0;
    margin-top: 10px;
  }

  .blog-inner-title {
    font-size: 26px;
  }

  .blog-single {
    --blog-space-y: 40px;
    --blog-space-x: 20px;
  }

  .blog-single .blog-title {
    font-size: 20px;
  }

  .blog-single .blog-img .play-btn {
    --icon-size: 60px;
    --icon-font-size: 20px;
  }

  .blog-author {
    display: block;
    padding: 30px 15px;
  }

  .blog-author .media-img {
    margin: 0 0 30px 0;
    width: 100%;
  }

  .blog-author .media-img img {
    width: 100%;
  }

  .blog-author .author-name {
    font-size: 18px;
  }

  .blog-author .author-degi {
    margin-bottom: 10px;
  }
}

/*------------------- 4.7. Comments  -------------------*/
.comment-respond {
  margin: var(--blog-space-y, 60px) 0 30px 0;
  position: relative;
}

.comment-respond .form-title a#cancel-comment-reply-link {
  font-size: 0.7em;
  text-decoration: underline;
}

.comment-respond .custom-checkbox.notice {
  margin-top: 10px;
  margin-bottom: 25px;
}

.comment-respond .row {
  --bs-gutter-x: 20px;
}

.comment-respond .form-control {
  background-color: var(--white-color);
  border: none;
  height: 55px;
}

.comment-respond .form-group>i {
  top: 20.5px;
}

.comment-respond input[type=checkbox]~label:before {
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
  border-radius: 3px;
}

.comment-respond input[type=checkbox]:checked~label:before {
  background-color: var(--theme-color);
  border-color: transparent;
}

.comment-respond .blog-inner-title {
  margin-bottom: 0px;
}

.comment-respond .form-text {
  margin-bottom: 25px;
}

.comment-respond .form-inner {
  background-color: var(--smoke-color);
  padding: 30px;
}

.comment-respond .vs-btn {
  width: 100%;
}

.question-form .comment-respond,
.review-form .comment-respond {
  margin: 0 0 20px 0;
}

.question-form .form-control,
.review-form .form-control {
  background-color: transparent;
  border: 1px solid #e4e4e4;
}

.question-form .rating-select,
.review-form .rating-select {
  display: flex;
  align-items: center;
}

.question-form .rating-select label,
.review-form .rating-select label {
  font-weight: 500;
  color: var(--title-color);
}

.review-form {
  display: none;
}

.vs-comments-wrap {
  margin: var(--blog-space-y, 60px) 0 var(--blog-space-y, 60px) 0;
}

.vs-comments-wrap .description p:last-child {
  margin-bottom: 0;
}

.vs-comments-wrap .comment-respond {
  margin: 30px 0;
}

.vs-comments-wrap pre {
  background: #ededed;
  color: #666;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.vs-comments-wrap blockquote {
  background-color: #eaf8f9;
}

.vs-comments-wrap li {
  margin: 0;
}

.vs-comments-wrap .vs-post-comment {
  border-bottom: 1px solid #F0F0F0;
  position: relative;
  display: flex;
  position: relative;
  padding: 0 0 30px 0;
  margin: 30px 0 30px 0;
}

.vs-comments-wrap ul.comment-list {
  list-style: none;
  margin: -10px 0 0 0;
  padding: 0;
}

.vs-comments-wrap ul.comment-list ul ul,
.vs-comments-wrap ul.comment-list ul ol,
.vs-comments-wrap ul.comment-list ol ul,
.vs-comments-wrap ul.comment-list ol ol {
  margin-bottom: 0;
}

.vs-comments-wrap .comment-avater {
  width: 110px;
  height: 110px;
  margin-right: 25px;
  overflow: hidden;
  background-color: var(--white-color);
}

.vs-comments-wrap .comment-avater img {
  width: 100%;
}

.vs-comments-wrap .comment-content {
  flex: 1;
  position: relative;
}

.vs-comments-wrap .commented-on {
  font-size: 14px;
  display: block;
  margin-bottom: 3px;
  margin-top: -0.25em;
  font-weight: 400;
  color: var(--body-color);
  font-family: var(--title-font);
}

.vs-comments-wrap .commented-on i {
  margin-right: 7px;
  font-size: 0.9rem;
}

.vs-comments-wrap .name {
  margin-bottom: 7px;
  font-size: 20px;
  font-weight: 600;
}

.vs-comments-wrap .comment-top {
  display: flex;
  justify-content: space-between;
}

.vs-comments-wrap .text:last-of-type {
  margin-bottom: 0;
}

.vs-comments-wrap .children {
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-left: 70px;
}

.vs-comments-wrap .reply_and_edit {
  position: absolute;
  right: 0;
  top: 15px;
  line-height: 1;
}

.vs-comments-wrap .replay-btn {
  font-weight: 600;
  font-size: 16px;
  display: inline-block;
  font-family: var(--title-font);
  color: var(--theme-color);
}

.vs-comments-wrap .replay-btn i {
  margin-right: 7px;
  font-size: 0.8em;
}

.vs-comments-wrap .replay-btn:hover {
  color: var(--title-color);
}

.vs-comments-wrap .star-rating {
  font-size: 12px;
  margin-bottom: 10px;
  position: absolute;
  top: 5px;
  right: 0;
  width: 80px;
}

.vs-comments-wrap.vs-comment-form {
  margin: 0;
}

/* Large devices */
@media (max-width: 1199px) {
  .vs-comments-wrap .vs-post-comment {
    display: block;
  }

  .vs-comments-wrap .star-rating {
    position: relative;
    top: 0;
    right: 0;
  }

  .vs-comments-wrap .comment-top {
    display: block;
  }

  .vs-comments-wrap .comment-avater {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .vs-comments-wrap .children {
    margin-left: 40px;
  }

  .vs-comments-wrap .reply_and_edit {
    position: relative;
    top: 0;
    margin: 15px 0 0 0;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .vs-comments-wrap .children {
    margin-left: 20px;
  }

  .vs-comments-wrap .name {
    font-size: 18px;
  }

  .comment-respond .form-inner {
    padding: 30px 15px;
  }
}

/*------------------- 4.8. Hero Area  -------------------*/
.ls-btn-group {
  display: flex;
  gap: 20px;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.ls-arrow-vertical .icon-btn,
.ls-arrow-vertical .hero-layout4 .slick-arrow,
.hero-layout4 .ls-arrow-vertical .slick-arrow {
  display: block;
  margin-bottom: 20px;
}

.ls-arrow-vertical .icon-btn:last-child,
.ls-arrow-vertical .hero-layout4 .slick-arrow:last-child,
.hero-layout4 .ls-arrow-vertical .slick-arrow:last-child {
  margin-bottom: 0;
}

.ls-play-btn .play-btn {
  --icon-size: 100%;
  width: 100%;
  height: 100%;
}

.ls-play-btn .play-btn i {
  line-height: 1;
}

.ls-play-btn .play-btn i:before {
  position: absolute;
  left: 52%;
  top: 49%;
  transform: translate(-50%, -50%);
}

.hero-style3 .ls-inner {
  background-size: cover !important;
}

.ls-custom-dot {
  display: block;
  background-color: transparent;
  width: var(--icon-size, 55px);
  height: var(--icon-size, 55px);
  line-height: var(--icon-size, 52px);
  border: 2px solid var(--white-color);
  color: var(--white-color);
  font-size: 18px;
  font-weight: 700;
  font-family: var(--title-font);
  border-radius: 50%;
  margin: 20px 0;
}

.ls-custom-dot:first-child {
  margin-top: 0;
}

.ls-custom-dot:last-child {
  margin-bottom: 0;
}

.ls-custom-dot:focus {
  outline: none;
}

.ls-custom-dot:hover,
.ls-custom-dot.active {
  background-color: var(--white-color);
  color: var(--theme-color);
  border-color: transparent;
}

.ls-custom-dot.active {
  transform: scale(1.1);
}

.hero-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.hero-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 1;
}

@media (min-width: 1500px) {
  .ls-hero-layout4 {
    margin-top: -63px;
  }
}

#ls-global .ls-hero-layout5.ls-v6 .ls-nav-prev,
#ls-global .ls-hero-layout5.ls-v6 .ls-nav-next {
  width: 80px;
  height: 80px;
  line-height: 80px;
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--white-color);
  text-align: center;
  font-size: 24px;
  border-radius: 50%;
}

#ls-global .ls-hero-layout5.ls-v6 .ls-nav-prev:after,
#ls-global .ls-hero-layout5.ls-v6 .ls-nav-next:after {
  display: none;
}

#ls-global .ls-hero-layout5.ls-v6 .ls-nav-prev::before,
#ls-global .ls-hero-layout5.ls-v6 .ls-nav-next::before {
  content: "\f061";
  font-family: var(--icon-font);
}

#ls-global .ls-hero-layout5.ls-v6 .ls-nav-prev:hover,
#ls-global .ls-hero-layout5.ls-v6 .ls-nav-next:hover {
  transform: none;
  background-color: var(--theme-color);
  color: var(--white-color);
}

#ls-global .ls-hero-layout5.ls-v6 .ls-nav-prev {
  left: 150px;
}

#ls-global .ls-hero-layout5.ls-v6 .ls-nav-prev:before {
  content: "\f060";
}

#ls-global .ls-hero-layout5.ls-v6 .ls-nav-next {
  right: 150px;
}

.hero-layout1 .slick-arrow {
  --pos-x: 150px;
  --icon-font-size: 24px;
  --icon-size: 67px;
  background-color: transparent;
  line-height: 65px;
  color: var(--white-color);
  border: 2px solid var(--white-color);
  top: calc(50% - 37.5px);
}

.hero-layout1 .hero-inner {
  position: relative;
  min-height: 850px;
  display: flex;
  align-items: center;
  background-color: var(--title-color);
  padding-bottom: 75px;
  overflow: hidden;
}

.hero-layout1 .hero-content {
  position: relative;
  z-index: 4;
  padding: 80px 0;
}

.hero-layout1 .hero-title {
  color: var(--white-color);
  text-transform: uppercase;
  margin: -0.2em 0 20px 0;
  font-size: 60px;
  line-height: 1.3;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.hero-layout1 .hero-text {
  color: var(--white-color);
  max-width: 690px;
  line-height: 28px;
  margin: 0 0 30px 0;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.hero-layout1 .hero-btns {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.hero-layout1 .hero-innertext {
  font-size: 18px;
  text-align: left;
  font-weight: 600;
  border: 2px solid var(--white-color);
  font-family: var(--title-font);
  color: var(--white-color);
  border-radius: 5px;
  padding: 9px 23.5px;
  vertical-align: middle;
  position: relative;
  top: -4px;
  margin-left: 15px;
  display: inline-block;
}

.hero-layout1 .hero-shape1 {
  position: absolute;
  top: -473px;
  left: 51px;
  z-index: 2;
  width: 300px;
  height: 1558px;
  background-color: rgba(14, 84, 245, 0.5);
  transform: rotate(43.46deg);
}

.hero-layout1 .hero-shape2 {
  position: absolute;
  transform: rotate(43.46deg);
  top: -485px;
  left: 406px;
  z-index: 2;
  width: 589px;
  height: 1819.7px;
  background: linear-gradient(172deg, rgba(5, 26, 79, 0.35) 21%, rgba(0, 0, 0, 0) 54%);
  background-position: 0% 0%;
  background-repeat: no-repeat;
}

.hero-layout1 .slick-current {
  --animation-name: slideinup;
}

.hero-layout2 {
  position: relative;
}

.hero-layout2 .hero-inner {
  min-height: 980px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-layout2 .hero-bg-text {
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 300px;
  z-index: 1;
  font-family: var(--title-font);
  color: rgba(255, 255, 255, 0.05);
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.1);
  display: inline-block;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.hero-layout2 .hero-subtitle {
  color: var(--white-color);
  font-family: var(--title-font);
  font-size: 20px;
  font-weight: 600;
  display: block;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
  position: relative;
  z-index: 5;
}

.hero-layout2 .hero-title {
  color: var(--white-color);
  text-align: center;
  font-weight: 700;
  font-size: 72px;
  line-height: 1.2;
  max-width: 810px;
  margin: 0 auto 40px auto;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  position: relative;
  z-index: 5;
}

.hero-layout2 .hero-btns {
  justify-content: center;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  position: relative;
  z-index: 5;
}

.hero-layout2 .hero-shape2,
.hero-layout2 .hero-shape1 {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  z-index: 3;
  line-height: 1;
}

.hero-layout2 .hero-shape1 img {
  width: 100%;
}

.hero-layout2 .hero-shape2 {
  z-index: 2;
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.hero-layout2 .hero-play {
  position: absolute;
  left: 16.5%;
  bottom: 18.6%;
  z-index: 4;
  display: flex;
  align-items: center;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.hero-layout2 .hero-play .play-btn {
  margin-right: 30px;
}

.hero-layout2 .hero-play__title {
  color: var(--white-color);
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 700;
  font-family: var(--title-font);
  display: block;
  margin: 3px 0 3px 0;
}

.hero-layout2 .hero-play__label {
  color: var(--white-color);
  font-size: 16px;
  font-family: var(--title-font);
  margin: 0;
}

.hero-layout2 .slick-current {
  --animation-name: slideinup;
}

.hero-layout2 .slick-current .hero-bg-text {
  --animation-name: fadein-custom;
}

.hero-layout2 .slick-current .hero-shape2 {
  --animation-name: slideinleft;
}

.hero-layout2 .slick-current .hero-play {
  --animation-name: slideinright;
}

.hero-layout3 .hero-img img {
  border-radius: 22px;
}

.hero-layout3 .hero-inner {
  min-height: 850px;
  padding: 100px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.hero-layout3 .hero-subtitle {
  color: var(--white-color);
  font-size: 18px;
  font-weight: 600;
  font-family: var(--title-font);
  display: block;
  margin: -0.2em 0 15px 0;
}

.hero-layout3 .hero-title {
  color: var(--white-color);
  font-size: 60px;
  font-weight: 700;
  line-height: 1.27;
  margin: 0 0 25px 0;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.hero-layout3 .inner-text {
  font-weight: 300;
}

.hero-layout3 .hero-btns {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.hero-layout3 .hero-text {
  color: var(--white-color);
  font-family: var(--title-font);
  line-height: 28px;
  margin: 0 0 30px 0;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.hero-layout3 .slick-dots {
  position: absolute;
  top: 50%;
  left: 225px;
  margin: 0;
  transform: translateY(-50%);
}

.hero-layout3 .slick-dots button {
  font-size: 30px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: transparent;
  color: var(--white-color);
  border: 2px solid var(--white-color);
  font-family: var(--title-font);
  font-size: 20px;
  font-weight: 700;
}

.hero-layout3 .slick-dots button::before {
  display: none;
}

.hero-layout3 .slick-dots li {
  display: block;
  margin: 0 0 20px 0;
}

.hero-layout3 .slick-dots li.slick-active button {
  transform: scale(1.1);
  background-color: var(--white-color);
  color: var(--theme-color);
}

.hero-layout3 .slick-dots li:last-child {
  margin-bottom: 0;
}

.hero-layout3 .slick-current {
  --animation-name: slideinup;
}

.hero-layout4 {
  margin-top: -65px;
}

.hero-layout4 .hero-content {
  position: relative;
  z-index: 4;
}

.hero-layout4 .hero-inner {
  min-height: 950px;
  max-width: 1518px;
  padding: 87px 0 100px 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.hero-layout4 .hero-shape2 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.hero-layout4 .hero-shape1 {
  position: absolute;
  width: var(--shape-size, 540px);
  height: var(--shape-size, 540px);
  right: calc(var(--shape-size, 540px) / -2);
  bottom: calc((var(--shape-size, 540px) - 460px) / -1);
  z-index: 1;
  background-color: var(--theme-color);
  border-radius: 65% 0% 65% 0%/65% 0% 65% 0%;
}

.hero-layout4 .hero-subtitle {
  font-size: 24px;
  font-weight: 500;
  font-family: var(--title-color);
  color: var(--white-color);
  display: block;
  margin: 0 0 30px 0;
}

.hero-layout4 .hero-title {
  font-size: 110px;
  color: var(--white-color);
  margin: 0 0 12px 0;
  line-height: 1;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.hero-layout4 .hero-title2 {
  font-size: 72px;
  color: var(--white-color);
  line-height: 1;
  margin: 0 0 50px 0;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.hero-layout4 .hero-img {
  position: absolute;
  right: 8%;
  bottom: 0;
  z-index: 2;
  max-width: 920px;
}

.hero-layout4 .hero-btns {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.hero-layout4 .slick-arrow {
  --btn-size: 60px;
  --icon-gap: 20px;
  top: calc(50% - (var(--btn-size) + var(--icon-gap)) / 2);
  left: 180px;
  border: 2px solid var(--white-color);
  color: var(--white-color);
  background-color: transparent;
}

.hero-layout4 .slick-arrow.slick-next {
  top: calc(50% + (var(--btn-size) + var(--icon-gap)) / 2);
}

@media (max-width: 1800px) {
  .hero-layout4 .slick-arrow {
    left: 60px;
  }
}

.hero-layout4 .slick-current {
  --animation-name: slideinup;
}

.hero-info {
  display: flex;
  align-items: center;
}

.hero-info .media-icon {
  margin-right: 25px;
}

.hero-info .media-label {
  font-size: 22px;
  font-family: var(--title-font);
  color: #7190ff;
  display: block;
  margin-bottom: 20px;
}

.hero-info .media-link {
  font-size: 36px;
  font-weight: 700;
  color: var(--white-color);
  font-family: var(--title-font);
}

.hero-info .media-link a {
  color: inherit;
}

.hero-info .media-link a:hover {
  color: var(--theme-color);
}

.hero-layout5 .hero-inner {
  padding: 250px 0 100px 0;
  min-height: 950px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.hero-layout5 .hero-content {
  position: relative;
  z-index: 4;
}

.hero-layout5 .hero-subtitle {
  font-size: 40px;
  font-weight: 700;
  color: var(--white-color);
  font-family: var(--title-font);
  letter-spacing: -0.2px;
  display: block;
  margin-bottom: 17px;
}

.hero-layout5 .hero-title {
  font-size: 100px;
  color: var(--white-color);
  letter-spacing: -0.5px;
  font-weight: 700;
  margin: 0 0 7px 0;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.hero-layout5 .hero-list ul {
  margin: 0 0 43px 0;
  padding: 0;
  list-style-type: none;
}

.hero-layout5 .hero-list li {
  font-size: 20px;
  font-weight: 500;
  font-family: var(--title-font);
  color: var(--white-color);
  margin-bottom: 20px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.hero-layout5 .hero-list li:nth-child(2) {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.hero-layout5 .hero-list li>i:first-child {
  margin-right: 15px;
}

.hero-layout5 .hero-shape1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 1210px;
  height: 1030px;
  z-index: 1;
  opacity: 0.7;
  background-color: #001c82;
  -webkit-clip-path: polygon(0px 0px, 100% 0px, 63.5% 100%, 0% 100%);
  clip-path: polygon(0px 0px, 100% 0px, 63.5% 100%, 0% 100%);
}

.hero-layout5 .hero-shape2 {
  position: absolute;
  z-index: 2;
  top: -11px;
  right: 0;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
  max-width: 30%;
}

.hero-layout5 .hero-shape3 {
  position: absolute;
  z-index: 2;
  top: 248px;
  left: -15px;
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.hero-layout5 .hero-shape4 {
  position: absolute;
  z-index: 2;
  top: 166px;
  left: 40%;
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
}

.hero-layout5 .hero-shape5 {
  position: absolute;
  z-index: 2;
  top: 430px;
  left: -13px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.hero-layout5 .hero-info {
  position: absolute;
  right: 80px;
  bottom: 95px;
  z-index: 3;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.hero-layout5 .slick-arrow {
  width: 80px;
  height: 80px;
  line-height: 80px;
  background-color: rgba(255, 255, 255, 0.2);
  color: var(--white-color);
  text-align: center;
  font-size: 24px;
  border-radius: 50%;
  --pos-x: 150px;
  border: none;
}

@media (max-width: 1800px) {
  .hero-layout5 .slick-arrow {
    --pos-x: 70px;
  }
}

.hero-layout5 .slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.hero-layout5 .hero-btns {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

.hero-layout5 .slick-current {
  --animation-name: slideinup;
}

.hero-layout5 .slick-current .hero-info {
  --animation-name: slideinright;
}

.hero-layout5 .slick-current .hero-shape5,
.hero-layout5 .slick-current .hero-shape4,
.hero-layout5 .slick-current .hero-shape3,
.hero-layout5 .slick-current .hero-shape2 {
  --animation-name: fadein-custom;
}

/* Hight Resoulation devices */
@media (min-width: 1921px) {
  .hero-layout4 .hero-img {
    right: 16%;
  }
}

/* Extra large devices */
@media (max-width: 1500px) {

  .ls-wrapper .icon-btn.style2,
  .ls-wrapper .hero-layout4 .style2.slick-arrow,
  .hero-layout4 .ls-wrapper .style2.slick-arrow {
    --btn-size: 40px;
    --btn-font-size: 14px;
  }

  .ls-custom-dot {
    --icon-size: 40px;
    font-size: 14px;
    line-height: 36px;
    margin: 10px 0;
  }

  .hero-layout2 .hero-shape2 {
    max-width: 450px;
  }

  .hero-layout2 .hero-play {
    left: 14.5%;
    bottom: 13.6%;
  }

  .hero-layout2 .hero-bg-text {
    font-size: 220px;
  }

  .hero-layout2 .hero-title {
    font-size: 60px;
    max-width: 700px;
  }

  .hero-layout2 .hero-subtitle {
    font-size: 18px;
  }

  .hero-layout2 .hero-inner {
    min-height: 800px;
  }

  .hero-layout2 .hero-play .play-btn {
    --icon-size: 100px;
    --icon-font-size: 24px;
  }

  #ls-global .ls-hero-layout5.ls-v6 .ls-nav-next {
    right: 60px;
  }

  #ls-global .ls-hero-layout5.ls-v6 .ls-nav-prev {
    left: 60px;
  }

  .hero-layout3 .slick-dots {
    top: auto;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }

  .hero-layout3 .slick-dots button {
    width: 40px;
    height: 40px;
    line-height: 38px;
    font-size: 16px;
  }

  .hero-layout3 .slick-dots li {
    display: inline-block;
    margin: 0 10px 0 0;
  }

  .hero-layout3 .slick-dots li:last-child {
    margin-right: 0;
  }

  .hero-layout3 .slick-dots li.slick-active button {
    transform: none;
  }

  .hero-layout4 {
    margin-top: 0;
  }

  .hero-layout4 .hero-img {
    right: 0;
    max-width: 850px;
  }

  .hero-layout4 .hero-shape2 {
    right: 2%;
    max-width: 450px;
  }

  .hero-layout4 .hero-inner {
    min-height: 850px;
    max-width: 100%;
    padding: 100px 0 100px 0;
  }

  .hero-layout4 .hero-title {
    font-size: 90px;
  }

  .hero-layout4 .hero-title2 {
    font-size: 60px;
  }

  .hero-layout4 .hero-subtitle {
    font-size: 20px;
  }

  .hero-layout5 .hero-inner {
    padding: 100px 0 100px 0;
    min-height: 750px;
  }

  .hero-layout5 .hero-subtitle {
    font-size: 30px;
    margin-bottom: 6px;
  }

  .hero-layout5 .hero-title {
    font-size: 72px;
  }

  .hero-layout5 .hero-shape1 {
    max-width: 770px;
  }

  .hero-layout5 .hero-list li {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .hero-layout5 .hero-list ul {
    margin: 0 0 33px 0;
  }

  .hero-layout5 .hero-subtitle {
    font-size: 24px;
  }

  .hero-layout5 .hero-info {
    right: 40px;
    bottom: 40px;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .hero-layout2 .hero-inner {
    min-height: 700px;
  }

  .hero-layout2 .hero-shape2 {
    max-width: 350px;
  }

  .hero-layout2 .hero-play {
    left: 10.5%;
    bottom: 10.6%;
  }

  .hero-layout2 .hero-play .play-btn {
    margin-right: 20px;
  }

  .hero-layout2 .hero-play__title {
    font-size: 18px;
  }

  .hero-layout2 .hero-play__label {
    font-size: 14px;
  }

  .hero-layout2 .hero-title {
    font-size: 48px;
    max-width: 635px;
  }

  .hero-layout2 .hero-subtitle {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .hero-layout2 .hero-bg-text {
    font-size: 190px;
  }

  .hero-layout3 .hero-title {
    font-size: 48px;
  }

  .hero-layout3 .hero-inner {
    min-height: 750px;
  }

  .hero-layout4 .hero-img {
    max-width: 700px;
  }

  .hero-layout4 .hero-shape2 {
    max-width: 380px;
  }

  .hero-layout4 .hero-shape1 {
    --shape-size: 400px;
    bottom: -100px;
  }

  .hero-layout4 .hero-title {
    font-size: 66px;
  }

  .hero-layout4 .hero-title2 {
    font-size: 48px;
  }

  .hero-layout4 .hero-inner {
    min-height: 700px;
    padding: 100px 0 100px 0;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .ls-btn-group .vs-btn {
    padding: 15px 25px;
    font-size: 14px;
  }

  .ls-layer .icon-btn.style4,
  .ls-layer .hero-layout4 .style4.slick-arrow,
  .hero-layout4 .ls-layer .style4.slick-arrow,
  .ls-layer .icon-btn.style3,
  .ls-layer .hero-layout4 .style3.slick-arrow,
  .hero-layout4 .ls-layer .style3.slick-arrow {
    --btn-size: 45px;
  }

  #ls-global .ls-hero-layout5.ls-v6 .ls-nav-next,
  #ls-global .ls-hero-layout5.ls-v6 .ls-nav-prev {
    display: none;
  }

  .hero-layout1 .hero-inner {
    min-height: 600px;
    padding-bottom: 0;
  }

  .hero-layout1 .hero-title {
    font-size: 46px;
  }

  .hero-layout1 .hero-innertext {
    font-size: 14px;
    padding: 7px 18px;
  }

  .hero-layout1 .hero-shape1 {
    top: -645px;
    left: 10px;
    width: 200px;
    height: 1490px;
  }

  .hero-layout1 .hero-shape2 {
    display: none;
  }

  .hero-layout3 .hero-inner {
    min-height: initial;
  }

  .hero-layout3 .hero-title {
    font-size: 42px;
  }

  .hero-layout4 .hero-img {
    max-width: 510px;
  }

  .hero-layout4 .hero-inner {
    min-height: 550px;
    padding: 80px 0 80px 0;
  }

  .hero-layout4 .hero-title {
    font-size: 60px;
  }

  .hero-layout4 .hero-title2 {
    font-size: 46px;
    margin: 0 0 30px 0;
  }

  .hero-layout4 .hero-subtitle {
    margin: 0 0 18px 0;
  }

  .hero-layout4 .hero-shape1 {
    --shape-size: 330px;
  }

  .hero-layout4 .hero-shape2 {
    max-width: 280px;
  }

  .hero-layout5 .hero-inner {
    min-height: auto;
  }

  .hero-layout5 .hero-title {
    font-size: 60px;
  }

  .hero-layout5 .hero-subtitle {
    font-size: 20px;
  }

  .hero-layout5 .hero-shape1 {
    max-width: 600px;
  }

  .hero-layout5 .hero-info .media-link {
    font-size: 24px;
  }

  .hero-layout5 .hero-info .media-label {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .hero-layout5 .hero-info .media-icon {
    margin-right: 15px;
    max-width: 50px;
  }

  .hero-layout5 .hero-shape4 {
    top: 20%;
    left: 40%;
    max-width: 30%;
  }

  .hero-layout5 .hero-shape5 {
    top: 40%;
    max-width: 12%;
  }

  .hero-layout5 .hero-shape3 {
    top: 10%;
    max-width: 10%;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .hero-layout1 {
    text-align: center;
  }

  .hero-layout1 .hero-inner {
    min-height: auto;
  }

  .hero-layout1 .hero-title {
    font-size: 36px;
    margin-bottom: 15px;
  }

  .hero-layout1 .hero-innertext {
    font-size: 12px;
    padding: 5px 15px;
    top: 0;
  }

  .hero-layout1 .hero-btns {
    justify-content: center;
  }

  .hero-layout2 .hero-inner {
    padding: 70px 0 100px 0;
    min-height: initial;
  }

  .hero-layout2 .hero-shape2 {
    max-width: 200px;
  }

  .hero-layout2 .hero-bg-text {
    font-size: 130px;
  }

  .hero-layout2 .hero-play {
    position: relative;
    bottom: 0;
    left: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 30px auto 0 auto;
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }

  .hero-layout2 .hero-play .play-btn {
    --icon-font-size: 18px;
    --icon-size: 60px;
    margin: 0 0 20px 0;
  }

  .hero-layout2 .hero-play__label,
  .hero-layout2 .hero-play__title {
    display: none;
  }

  .hero-layout2 .hero-title {
    font-size: 40px;
    max-width: 530px;
  }

  .hero-layout3 .hero-img {
    margin-bottom: 40px;
  }

  .hero-layout3 .hero-img img {
    width: 100%;
  }

  .hero-layout3 .hero-content {
    text-align: center;
  }

  .hero-layout3 .hero-btns {
    justify-content: center;
  }

  .hero-layout4 .hero-img {
    max-width: 360px;
  }

  .hero-layout4 .hero-inner {
    min-height: 400px;
  }

  .hero-layout4 .hero-shape2 {
    max-width: 200px;
  }

  .hero-layout4 .hero-title {
    font-size: 48px;
  }

  .hero-layout4 .hero-title2 {
    font-size: 36px;
  }

  .hero-layout4 .hero-shape1 {
    --shape-size: 260px;
  }

  .hero-layout5 .hero-title {
    font-size: 46px;
  }

  .hero-layout5 .hero-subtitle {
    font-size: 18px;
  }

  .hero-layout5 .hero-list li {
    font-size: 14px;
  }

  .hero-layout5 .hero-shape1 {
    max-width: 450px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .ls-btn-group {
    justify-content: center;
    gap: 10px;
  }

  .ls-btn-group .vs-btn {
    padding: 11.5px 14px;
    font-size: 12px;
  }

  .vs-btn.style10.mobile-white {
    background-color: var(--white-color);
    border-color: transparent;
    color: var(--title-color);
  }

  .hero-layout1 .hero-title {
    font-size: 30px;
  }

  .hero-layout1 .hero-innertext {
    border: none;
    padding: 0;
    display: inline;
    font-size: inherit;
    margin: 0;
    vertical-align: top;
    text-decoration: underline;
  }

  .hero-layout2 .hero-title {
    font-size: 34px;
    margin-bottom: 30px;
  }

  .hero-layout2 .hero-subtitle {
    font-size: 14px;
  }

  .hero-layout2 .hero-bg-text {
    font-size: 70px;
  }

  .hero-layout2 .hero-shape2 {
    max-width: 150px;
  }

  .hero-layout3 .hero-subtitle {
    font-size: 16px;
  }

  .hero-layout3 .hero-title {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .hero-layout4 .hero-content {
    text-align: center;
  }

  .hero-layout4 .hero-img,
  .hero-layout4 .hero-shape2 {
    display: none;
  }

  .hero-layout4 .hero-btns {
    justify-content: center;
  }

  .hero-layout4 .hero-subtitle {
    font-size: 16px;
  }

  .hero-layout4 .hero-title {
    font-size: 36px;
  }

  .hero-layout4 .hero-title2 {
    font-size: 30px;
  }

  .hero-layout4 .hero-shape1 {
    --shape-size: 200px;
  }

  .hero-layout5 .hero-title {
    font-size: 34px;
  }

  .hero-layout5 .hero-info {
    display: none;
  }

  .hero-layout5 .hero-shape1 {
    max-width: 100%;
    height: 100%;
    -webkit-clip-path: none;
    clip-path: none;
  }

  .hero-layout5 .hero-list li>i:first-child {
    margin-right: 10px;
  }
}

/*------------------- 4.9. Error  -------------------*/
.error-number {
  font-size: 274px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 3px;
  margin-top: -0.1em;
}

.error-title {
  font-size: 36px;
  margin-bottom: 8px;
}

.error-text {
  max-width: 600px;
  margin: 0 auto 32px auto;
}

/* Medium devices */
@media (max-width: 991px) {
  .error-number {
    font-size: 200px;
  }

  .error-title {
    font-size: 20px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .error-number {
    font-size: 110px;
  }
}

/*------------------- 4.10. Feature  -------------------*/
.feature-style1 {
  background-color: var(--white-color);
  padding: 50px 40px;
  box-shadow: 0px 18px 13px rgba(234, 240, 250, 0.5);
  border-radius: 5px;
  position: relative;
  margin-bottom: 30px;
}

.feature-style1:before {
  content: "";
  position: absolute;
  left: 40px;
  bottom: 0;
  height: 3px;
  width: calc(100% - 80px);
  background-color: var(--theme-color);
}

.feature-style1 .feature-icon {
  margin: 0 0 15px 0;
}

.feature-style1 .feature-title {
  margin: 0 0 13px 0;
}

.feature-style1 .feature-text {
  margin: 0 0 20px 0;
}

.feature-style1:hover .vs-btn {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.feature-style2 {
  background-color: var(--white-color);
  padding: 40px 40px 32px 40px;
  border-radius: 5px;
  box-shadow: 10px 10px 31px rgba(169, 177, 193, 0.17);
  margin: 0 0 30px 0;
  height: 400px;
}

.feature-style2 .feature-icon {
  background-color: var(--smoke-color);
  width: 110px;
  height: 110px;
  line-height: 90px;
  text-align: center;
  border-radius: 5px;
  margin: 0 0 22px 0;
  transition: all ease 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-style2 .feature-icon img {
  transition: all ease 0.4s;
  filter: none;
  object-fit: contain;
}

.feature-style2 .feature-title {
  margin: 0 0 10px 0;
}

.feature-slide2 {
  margin-top: -30px;
  margin-bottom: -30px;
}

.feature-slide2 .feature-style2 {
  box-shadow: 0px 10px 21px rgba(169, 177, 193, 0.15);
  margin: 30px 0 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1199px) {
  .feature-wrap1.space-top {
    padding-top: 1px;
  }

  .feature-wrap1>.container {
    position: relative;
    z-index: 36;
    margin-top: -75px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .feature-slide2 .feature-style2 {
    box-shadow: 0px 10px 21px rgba(169, 177, 193, 0.15);
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .feature-style1 {
    padding: 40px 30px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .feature-style1 {
    padding: 40px 20px;
  }

  .feature-style2 {
    padding: 40px 25px 32px 25px;
  }
}

/*------------------- 4.11. About  -------------------*/
.img-box1 .img-1 img {
  filter: drop-shadow(0px 5px 32px rgba(21, 40, 76, 0.08));
}

.img-box1 .img-2 {
  z-index: 1;
  margin: -200px 0 0 auto;
  max-width: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.img-box1 img {
  border-radius: 5px;
}

.img-box2 {
  position: relative;
  margin: 0 0 30px 0;
}

.img-box2 .img-1 {
  position: relative;
  margin-left: -200px;
}

.img-box2 .img-2 {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: var(--white-color);
  border-radius: 10px 0 0 0;
  padding: 10px 0 0 10px;
}

.img-box3 {
  position: relative;
}

.img-box3 img {
  border-radius: 5px;
}

@media (max-width: 767px) {
  .img-box3 .img-1 {
    display: none;
  }
}

.img-box3 .img-1 {
  text-align: right;
  padding-left: 100px;
  padding-bottom: 50px;
}

.img-box3 .img-2 {
  margin: -100px 0 0 20px;
}

.img-box3 .award-box {
  position: absolute;
  left: 0;
  right: 80px;
  bottom: 0px;
  margin: 0 0 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.img-box4 {
  position: relative;
}

.img-box4 .img-1 {
  margin: -120px 0 0 0;
}

.img-box4 .img-2 {
  margin: -178px 0 0 auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
}

.img-box5 {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-left: auto;
  position: relative;
  max-width: 100%;
  padding: 40px 40px 0 0;
}

.img-box5 img {
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.img-box5 .img-1 {
  text-align: right;
  margin-bottom: 20px;
}

.img-box5 .img-bottom {
  display: flex;
  align-items: flex-start;
}

.img-box5 .img-3 {
  margin: -47px 0 0 20px;
  box-shadow: 0px 4px 32px rgba(61, 79, 115, 0.19);
}

.img-box5 .shape1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 350px;
  max-width: 100%;
  height: 470px;
  max-height: 100%;
  border: 5px solid rgba(14, 89, 242, 0.1);
  border-radius: 50px 5px 5px 5px;
}

.img-box6 {
  margin-bottom: 30px;
}

.img-box6 img {
  border-radius: 15px;
}

.img-box7 img {
  border-radius: 7px;
}

.img-box8 {
  position: relative;
}

.img-box8 .play-btn {
  left: -30px;
  top: 90px;
  position: absolute;
}

.img-box9 {
  display: grid;
  grid-template-columns: repeat(2, auto);
  position: relative;
  margin-right: -120px;
  padding-bottom: 55px;
}

.img-box9 img {
  width: 100%;
}

.img-box9 .img-1 {
  display: inline-block;
  margin: 65px 30px 0 0;
}

.img-box9 .img-2 {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.img-box9 .img-2:before {
  content: "";
  position: absolute;
  right: -40px;
  top: 40px;
  bottom: 40px;
  width: 45px;
  background-color: #e9ebf5;
  z-index: -1;
}

.img-box9 .img-3 {
  position: absolute;
  left: 85px;
  bottom: 0;
  z-index: 2;
}

.img-box10 {
  position: relative;
  padding-right: 30px;
}

.img-box10 .icon-btn,
.img-box10 .hero-layout4 .slick-arrow,
.hero-layout4 .img-box10 .slick-arrow {
  border-radius: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.exp-media {
  display: flex;
  margin: 0 0 15px 0;
}

.exp-media__total {
  display: inline-block;
  text-align: center;
  background-color: var(--white-color);
  color: var(--theme-color);
  font-family: var(--title-font);
  font-weight: 700;
  font-size: 36px;
  width: 80px;
  height: 100px;
  line-height: 100px;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(91, 118, 175, 0.15);
  margin: 0 20px 0 0;
}

.exp-media__title {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  color: var(--theme-color);
  margin: -0.1em 0 10px 0;
}

.list-style1 ul {
  margin: 20px 0 27px 0;
  padding: 42px 0 0 0;
  list-style-type: none;
  border-top: 1px solid #E9EFFB;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.list-style1 li {
  position: relative;
  color: var(--title-color);
  color: var(--black-color);
  margin: 0 0 10px 0;
  padding: 0 0 0 33px;
}

.list-style1 li>i:first-child {
  position: absolute;
  left: 0;
  color: var(--theme-color);
  top: 3px;
}

.list-style2 ul {
  margin: 0 0 40px 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.list-style2 li {
  position: relative;
  border: 1px solid #EAEEF4;
  color: var(--title-color);
  font-family: var(--title-font);
  font-weight: 500;
  padding: 16px 25px;
}

.list-style2 li>i:first-child {
  color: var(--theme-color);
  margin-right: 10px;
}

.list-style3 ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list-style3 li {
  margin: 0 0 7px 0;
}

.list-style3 i {
  color: var(--theme-color);
  margin: 0 10px 0 0;
}

.list-style4 ul {
  margin: 0;
  padding: 43px 40px 46px 39px;
  list-style-type: none;
  background-color: var(--theme-color);
  color: var(--white-color);
}

.list-style4 li {
  font-size: 18px;
  font-weight: 500;
  padding: 0 0 0 50px;
  position: relative;
  margin: 0 0 27px 0;
}

.list-style4 li:last-child {
  margin-bottom: 0;
}

.list-style4 li i:first-child {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  background-color: var(--white-color);
  color: var(--theme-color);
  border-radius: 50%;
  text-align: center;
  z-index: 1;
  position: absolute;
  top: -3px;
  left: 0;
}

.list-style5 ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 37px 0 0 0;
  gap: 25px;
}

.list-style5 li {
  background-color: var(--white-color);
  color: var(--title-color);
  font-weight: 500;
  border: 2px solid #f0f2fa;
  box-shadow: 7.5px 12.99px 60px 0px rgba(9, 34, 126, 0.07);
  padding: 20px 20px;
  text-align: center;
}

.list-style5 li i {
  color: var(--theme-color);
  margin-right: 10px;
}

.quote-media {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  border-top: 1px solid #e9ebf3;
  margin-top: 40px;
  padding-top: 40px;
}

.quote-media .media-icon {
  margin-right: 40px;
  position: relative;
}

.quote-media .media-icon::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 20px;
  width: 67px;
  height: 67px;
  background-color: var(--theme-color);
  opacity: 0.05;
  border-radius: 50%;
  z-index: -1;
}

.quote-media .media-title {
  font-weight: 400;
  font-size: 18px;
}

.award-box {
  background-color: #F4F7FC;
  position: relative;
  padding: 60px 60px 57px 60px;
  display: flex;
  border-radius: 5px;
}

.award-box>* {
  position: relative;
  z-index: 2;
}

.award-box__shape {
  position: absolute;
  left: 20px;
  right: 20px;
  top: 20px;
  bottom: 20px;
  background-color: var(--theme-color);
  box-shadow: 0px 4px 20px rgba(14, 89, 242, 0.33);
  border-radius: 5px;
  z-index: 1;
}

.award-box__icon {
  position: relative;
  color: var(--white-color);
  font-size: 42px;
  line-height: 1;
  margin: 0 20px 0 0;
}

.award-box__number {
  line-height: 1;
  margin: 0 0 8px 0;
  font-size: 40px;
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--white-color);
}

.award-box__text {
  font-weight: 700;
  font-size: 16px;
  font-family: var(--title-font);
  text-transform: uppercase;
  margin: 0;
  color: var(--white-color);
  line-height: 1;
}

.about-media {
  display: flex;
}

.about-media__icon {
  margin: 0 20px 0 0;
}

.call-media {
  display: flex;
  align-items: center;
  padding: 10px 40px 10px 10px;
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  margin: 0 0 40px 0;
  background-color: var(--white-color);
}

.call-media__icon {
  background-color: var(--smoke-color);
  box-shadow: 0px 7px 37px rgba(96, 125, 185, 0.11);
  border-radius: 5px;
  width: 95px;
  height: 95px;
  line-height: 95px;
  border-radius: 5px;
  text-align: center;
  margin: 0 20px 0 0;
}

.call-media__label {
  text-transform: uppercase;
  font-family: var(--title-font);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  display: block;
  margin: 0 0 12px 0;
}

.call-media__info {
  color: var(--title-color);
  font-family: var(--title-font);
  font-weight: 700;
  line-height: 1;
  display: block;
  margin: 0;
}

.call-media__info a {
  color: inherit;
}

.call-media__info a:hover {
  color: var(--theme-color);
}

.media-order {
  display: flex;
  margin: 0 0 5px 0;
}

.media-order__number {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 20px;
  font-weight: 700;
  font-family: var(--title-font);
  background-color: var(--theme-color);
  color: var(--white-color);
  text-align: center;
  display: inline-block;
  margin: 0 30px 0 0;
  border-radius: 5px;
}

.media-order__title {
  margin: -0.25em 0 10px 0;
}

.progress-box {
  margin: 0 0 25px 0;
}

.progress-box__number,
.progress-box__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  font-family: var(--title-font);
  color: var(--title-color);
  display: inline-block;
  margin: 0 0 17px 0;
}

.progress-box__number {
  float: right;
  margin: 5px 0 0 0;
}

.progress-box__progress {
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border: 1px solid #D5E0F5;
  height: 16px;
  padding: 4px;
  border-radius: 5px;
  background-color: var(--white-color);
}

.progress-box__bar {
  height: 100%;
  background-color: var(--theme-color);
  border-radius: inherit;
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
}

.about-wrap1 {
  border-top: 1px solid #e8ebf6;
  position: relative;
}

.about-shape1 {
  font-size: 150px;
  font-weight: 400;
  font-family: var(--title-font);
  line-height: 1;
  display: inline-block;
  -ms-writing-mode: tb-rl;
  writing-mode: tb-rl;
  text-transform: uppercase;
  transform: rotate(180deg);
  position: absolute;
  right: 95px;
  bottom: 120px;
  color: rgba(14, 89, 242, 0.07);
}

.about-shape2 {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 1567px;
  max-height: 620px;
  z-index: -1;
  /* Hight Resoulation devices */
}

@media (min-width: 1921px) {
  .about-shape2 {
    max-width: 80%;
  }
}

.about-shape3 {
  background-color: #f6f7fc;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 620px;
  /* Hight Resoulation devices */
}

@media (min-width: 1921px) {
  .about-shape3 {
    max-width: 34%;
  }
}

.contact-media {
  display: flex;
  align-items: center;
}

.contact-media__icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  text-align: center;
  font-size: 26px;
  border-radius: 7px;
  position: relative;
  z-index: 1;
}

.contact-media__label {
  text-transform: uppercase;
  font-family: var(--title-font);
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  display: block;
  margin: 0 0 9px 0;
}

.contact-media__info {
  color: var(--title-color);
  font-family: var(--title-font);
  font-weight: 700;
  display: block;
  margin: 0 0 -0.25em 0;
  font-size: 15px;
  line-height: 24px;
}

.contact-media__info a {
  color: inherit;
}

.contact-media .media-body {
  background-color: var(--white-color);
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 10px 10px 50px;
  margin-left: -30px;
  border-radius: 5px;
}

.about-call {
  display: flex;
  align-items: center;
  border-right: 2px solid var(--theme-color);
  padding: 0 40px 0 0;
  margin: 0 40px 0 0;
}

.about-call__icon {
  background-color: var(--white-color);
  color: var(--theme-color);
  text-align: center;
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin-right: 20px;
  font-size: 20px;
  border-radius: 50%;
}

.about-call__label {
  font-size: 14px;
  color: var(--black-color);
  display: block;
}

.about-call__number {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--theme-color);
}

.about-box1 .about-subtitle {
  font-weight: 500;
  line-height: 1;
  display: block;
  font-family: var(--title-font);
  color: var(--theme-color);
  display: flex;
  align-items: center;
  max-width: 280px;
  margin: 0 0 33px 0;
}

.about-box1 .about-subtitle:after {
  content: "";
  position: relative;
  right: 0;
  height: 1px;
  width: 100%;
  flex: 1;
  background-color: var(--theme-color);
  display: inline-block;
  margin: 0 0 0 10px;
}

.about-box1 .about-text {
  margin: 0 0 33px 0;
}

.about-box1 .about-title {
  margin-top: -0.3em;
}

.about-box2 {
  padding: var(--section-space, 120px) 0;
  max-width: 560px;
}

.about-box2 .author-degi {
  font-family: var(--title-font);
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
}

.about-box2 .author-name {
  margin-bottom: 0;
}

.about-box3 {
  max-width: 620px;
  padding-left: 50px;
  position: relative;
}

.about-box3 .sec-title4 {
  margin-bottom: 44px;
}

.about-box3 .vs-btn {
  margin-top: 40px;
}

@media (min-width: 1800px) {
  .about-box3 .vs-btn {
    position: absolute;
    right: -59%;
    bottom: 71px;
    margin: 0;
  }
}

.about-box3-btn {
  padding-left: 100px;
  padding-bottom: 140px;
}

.skill-about {
  margin-bottom: 22px;
}

.skill-about .skill-percentage {
  font-size: 60px;
  font-weight: 700;
  color: var(--title-color);
  line-height: 1;
  margin-bottom: 6px;
}

.skill-about .skill-title {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 27px;
}

.skill-about .progress {
  border-radius: 0;
  height: 10px;
  background-color: #363940;
  margin-bottom: 20px;
}

.skill-about .progress .progress-bar {
  background-color: var(--theme-color);
}

.skill-about .skill-text {
  margin-bottom: 0;
}

/* Extra large devices */
@media (max-width: 1500px) {
  .about-shape1 {
    font-size: 80px;
    right: 50px;
    bottom: 70px;
  }

  .img-box2 .img-1 {
    margin-left: 0;
  }

  .img-box2 .img-2 {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: -100px 30px 0 auto;
    padding: 10px;
    border-radius: 10px;
  }

  .img-box9 {
    margin-right: 0;
  }

  .about-box2 {
    max-width: 500px;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .img-box8 .play-btn {
    left: 0;
    top: 0;
    position: absolute;
  }

  .img-box8 .play-btn:after,
  .img-box8 .play-btn:before {
    display: none;
  }

  .img-box8 .img-1 img {
    width: 100%;
  }

  .about-shape3 {
    max-width: 29%;
  }

  .play-btn.style5 i {
    --icon-size: 70px;
    --icon-font-size: 22px;
  }

  .about-box3 {
    max-width: 100%;
    padding-left: 0;
  }

  .list-style4 ul {
    padding: 30px 40px 36px 32px;
  }

  .list-style4 ul li {
    font-size: 16px;
  }

  .about-shape2 {
    max-width: 100%;
    max-height: 100%;
  }

  .about-wrap2 {
    padding-bottom: var(--section-space);
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .img-box3 {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }

  .img-box9 .img-2:before {
    display: none;
  }

  .img-box4 .img-1 {
    margin: 0;
  }

  .img-box4 .img-2 {
    margin: -100px 0 0 auto;
    max-width: 70%;
  }

  .about-box3 .sec-title4 {
    margin-bottom: 30px;
  }

  .list-style2 li {
    padding: 14px 20px;
    font-size: 14px;
  }

  .list-style4 ul {
    background-color: transparent;
    padding: 0;
  }

  .list-style4 li {
    color: var(--title-color);
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .about-box2 {
    padding: 40px 0 var(--section-space-mobile, 80px) 0;
  }

  .about-wrap2 {
    padding-bottom: var(--section-space-mobile);
  }

  .img-box1 .img-1 img {
    width: 100%;
  }

  .img-box5 {
    width: 100%;
  }

  .img-box5 .img-1 img {
    width: 100%;
  }

  .img-box5 .img-bottom {
    justify-content: center;
  }

  .skill-about .skill-percentage {
    font-size: 48px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .img-box10 {
    padding-right: 0;
  }

  .img-box2 .img-2,
  .img-box1 .img-2 {
    margin: 20px 0 0 0;
    width: 100%;
  }

  .img-box2 .img-2 img,
  .img-box1 .img-2 img {
    width: 100%;
  }

  .img-box5 {
    padding: 0;
  }

  .img-box5 .shape1 {
    display: none;
  }

  .img-box9 {
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .img-box9 img {
    width: 100%;
  }

  .img-box9 .img-1 {
    display: block;
    margin: 0 0 30px 0;
  }

  .img-box9 .img-3 {
    display: block;
    position: relative;
    left: 0;
    bottom: 0;
    margin: 30px 0 0 0;
  }

  .list-style2 ul {
    display: block;
  }

  .list-style2 ul li:not(:last-child) {
    margin: 0 0 20px 0;
  }

  .list-style5 ul {
    display: block;
  }

  .list-style5 ul li:not(:last-child) {
    margin-bottom: 20px;
  }

  .quote-media .media-icon {
    margin-right: 20px;
  }

  .media-order {
    display: block;
  }

  .media-order__number {
    margin: 0 0 20px 0;
  }

  .img-box3 img {
    width: 100%;
  }

  .img-box3 .img-2 {
    margin: 30px 0 0 0;
  }

  .call-media__icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
    margin: 0 10px 0 0;
  }

  .call-media__icon img {
    max-width: 25px;
  }

  .call-media__label {
    font-size: 12px;
  }

  .award-box {
    padding: 30px;
  }

  .award-box__shape {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .img-box3 .award-box {
    position: relative;
    bottom: 0;
    right: 0;
    margin: 30px 0 0 0;
    width: 100%;
    box-shadow: none;
  }

  .exp-media {
    margin-top: 30px;
  }

  .list-style1 ul {
    display: block;
    margin: 0 0 30px 0;
    padding: 30px 0 0 0;
  }

  .list-style4 ul li {
    font-size: 14px;
    padding-left: 35px;
    margin-bottom: 15px;
  }

  .list-style4 ul li i:first-child {
    width: 25px;
    height: 25px;
    line-height: 26px;
    font-size: 12px;
    top: -1px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .about-call {
    padding: 0;
    border-right: none;
    margin-top: 20px;
  }
}

/*------------------- 4.12. Service  -------------------*/
.service-style1 {
  padding: 40px;
  background-color: var(--white-color);
  box-shadow: 0px 16px 47px rgba(78, 111, 178, 0.07);
  border-radius: 5px;
  position: relative;
  transition: all ease 0.4s;
  overflow: hidden;
  margin: 0 0 30px 0;
}

.service-style1 .service-icon {
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  background-color: var(--smoke-color);
  border-radius: 50%;
  margin: 0 0 28px 0;
}

.service-style1 .service-text {
  margin: 0 0 20px 0;
}

.service-style1 .service-title a {
  color: var(--title-color);
  transition: all ease 0.3s;
  position: relative;
  z-index: 3;
}

.service-style1 .service-number,
.service-style1 .link-btn,
.service-style1 .service-icon,
.service-style1 .service-text {
  position: relative;
  z-index: 3;
  transition: all ease 0.4s;
}

.service-style1 .service-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  transform: scale(1.15);
}

.service-style1 .service-number {
  line-height: 1;
  color: rgba(14, 89, 242, 0.1);
  font-size: 60px;
  font-weight: 700;
  font-family: var(--title-font);
  margin-top: 4px;
  opacity: 1;
  visibility: visible;
}

.service-style1 .service-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 27px 0;
}

.service-style1 .service-top .service-icon {
  margin: 0;
}

.service-style1:hover {
  box-shadow: 0px 16px 47px rgba(14, 89, 242, 0.2);
  background-color: var(--theme-color);
}

.service-style1:hover .service-number {
  opacity: 0;
  visibility: hidden;
}

.service-style1:hover .service-icon {
  background-color: var(--white-color);
}

.service-style1:hover .link-btn,
.service-style1:hover .service-title a,
.service-style1:hover .service-text {
  color: var(--white-color);
}

.service-style1:hover .vs-btn {
  background-color: var(--white-color);
}

.service-style1:hover .service-bg {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.service-style1.layout2 {
  box-shadow: none;
  border: 1px solid #EAF2FE;
}

.service-style1.layout2:hover {
  box-shadow: 0px 16px 47px rgba(14, 89, 242, 0.2);
}

.service-style1.layout3 {
  box-shadow: none;
  border: 1px solid #ECF0F6;
  padding: 50px;
  border-radius: 0;
}

.service-style1.layout3 .service-icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
}

.service-style2 {
  display: flex;
  align-items: center;
  margin: 0 0 30px 0;
}

.service-style2 .service-img {
  width: 160px;
  position: relative;
  background-color: var(--smoke-color);
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
}

.service-style2 .service-img img {
  border-radius: 5px;
}

.service-style2 .service-number {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 500;
  font-family: var(--title-font);
  color: var(--theme-color);
  background-color: var(--white-color);
  text-align: center;
  border-radius: 5px 0;
  position: absolute;
  left: 0;
  top: 0;
}

.service-style2 .service-title {
  margin: 0 0 10px 0;
}

.service-style2 .service-title a {
  color: var(--title-color);
  transition: all ease 0.4s;
}

.service-style2 .service-text {
  margin: 0 0 12px 0;
}

.service-style2 .service-content {
  flex: 1;
  position: relative;
  border-radius: 5px;
  padding: 31px 40px 30px 130px;
  margin: 0 0 0 -105px;
  transition: all ease 0.4s;
  overflow: hidden;
  background-color: var(--white-color);
}

.service-style2 .service-content>* {
  position: relative;
  z-index: 1;
  transition: all ease 0.4s;
}

.service-style2 .service-shape {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 5px;
  transition: all ease 0.4s;
  transform: scale(1.1);
  background-color: var(--theme-color);
  opacity: 0;
  visibility: hidden;
  z-index: 0;
}

.service-style2:hover .service-text,
.service-style2:hover .service-title a,
.service-style2:hover .link-btn {
  color: var(--white-color);
}

.service-style2:hover .service-content {
  border-color: transparent;
}

.service-style2:hover .service-shape {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.service-style3 {
  text-align: center;
  margin: 0 0 30px 0;
  position: relative;
}

.service-style3 .service-img img {
  width: 100%;
}

.service-style3 .service-icon {
  display: block;
  width: var(--icon-size, 120px);
  height: var(--icon-size, 120px);
  line-height: calc(var(--icon-size, 120px) - var(--icon-shape, 10px) * 2);
  text-align: center;
  background-color: var(--smoke-color);
  border: 10px solid var(--white-color);
  margin: 0 auto 15px auto;
  border-radius: 50%;
  z-index: 1;
  position: relative;
}

.service-style3 .service-title {
  margin: 0;
}

.service-style3 .service-title a {
  color: inherit;
}

.service-style3 .service-text {
  margin: 0 0 15px 0;
  color: #BED4FF;
}

.service-style3 .service-content {
  padding: 0 30px 37px 30px;
}

.service-style3 .service-front {
  transform: rotateX(0deg) rotateY(0deg);
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.4s ease-in-out;
  z-index: 900;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: var(--white-color);
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
}

.service-style3 .service-front .service-icon {
  margin-top: calc(var(--icon-size, 120px) / -2);
}

.service-style3 .service-back {
  z-index: 1000;
  transform: rotateY(-180deg);
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.4s ease-in-out;
  background-color: var(--theme-color);
  border-radius: 5px;
  overflow: hidden;
}

.service-style3 .service-back .service-content {
  padding: 40px 30px 37px 30px;
}

.service-style3 .service-back .service-icon {
  background-color: var(--theme-color);
}

.service-style3 .service-back .service-icon img {
  filter: brightness(0) invert(1);
}

.service-style3 .service-back .service-title {
  color: var(--white-color);
  margin: 0 0 10px 0;
}

.service-style3 .service-back .link-btn {
  color: var(--white-color);
}

.service-style3:hover .service-front {
  z-index: 900;
  transform: rotateY(180deg);
  box-shadow: none;
}

.service-style3:hover .service-back {
  z-index: 1000;
  transform: rotateX(0deg) rotateY(0deg);
}

.service-style4 {
  background-color: var(--theme-color);
  padding: 40px 45px 45px 45px;
  margin-bottom: 30px;
}

.service-style4 .service-top {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #6486ff;
  margin-bottom: 25px;
}

.service-style4 .service-title {
  color: var(--white-color);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
}

.service-style4 .service-title a {
  color: inherit;
}

.service-style4 .service-title a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.service-style4 .service-btn {
  font-size: 24px;
  color: var(--white-color);
}

.service-style4 .service-btn:hover {
  color: rgba(255, 255, 255, 0.7);
}

.service-style4 .service-text {
  color: #beccff;
  margin-bottom: 28px;
}

.service-style4 .service-img {
  overflow: hidden;
}

.service-style4 .service-img img {
  width: 100%;
  transition: all ease 0.4s;
  transform: scale(1);
}

.service-style4:hover .service-img img {
  transform: scale(1.16);
}

.service-style5 {
  margin-bottom: 30px;
}

.service-style5 .service-img {
  margin-bottom: 28px;
  border-bottom: 7px solid var(--theme-color);
  overflow: hidden;
}

.service-style5 .service-img img {
  width: 100%;
  transition: all ease 0.4s;
  transform: scale(1);
}

.service-style5 .service-text {
  margin-bottom: 25px;
}

.service-style5 .service-title {
  margin-bottom: 15px;
}

.service-style5 .icon-btn,
.service-style5 .hero-layout4 .slick-arrow,
.hero-layout4 .service-style5 .slick-arrow {
  background-color: #dbe2fc;
  border-radius: 50%;
}

.service-style5 .icon-btn:hover,
.service-style5 .hero-layout4 .slick-arrow:hover,
.hero-layout4 .service-style5 .slick-arrow:hover {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.service-style5:hover .service-img img {
  transform: scale(1.1);
}

.service-list-box {
  background-color: var(--smoke-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 35px 40px;
}

.service-list-box .title {
  margin: 0 0 27px 0;
}

.service-list-box .list-style3 li:last-child {
  margin-bottom: 2.5px;
}

.service-tab-menu {
  display: flex;
  margin: 0 0 60px 0;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.service-tab-menu .btn-img {
  display: block;
  width: 120px;
  height: 120px;
  line-height: 100px;
  background-color: var(--smoke-color);
  border: 10px solid var(--white-color);
  border-radius: 50%;
  margin: -60px auto 30px auto;
  transition: all ease 0.4s;
}

.service-tab-menu .btn-img img {
  filter: none;
  transition: all ease 0.3s;
}

.service-tab-menu .btn-title {
  display: block;
  margin: 0 0 6px 0;
  color: var(--title-color);
  transition: all ease 0.4s;
}

.service-tab-menu .btn-text {
  color: var(--body-color);
  transition: all ease 0.4s;
  display: block;
}

.service-tab-menu .nav-link {
  border: none;
  flex: 1;
  max-width: 300px;
  display: inline-block;
  text-align: center;
  margin: 60px 0 0 0;
  padding: 0 0 38px 0;
  background-color: var(--white-color);
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
  min-width: 210px;
  transition: all ease 0.4s;
}

.service-tab-menu .nav-link.active,
.service-tab-menu .nav-link:hover {
  background-color: var(--theme-color);
  box-shadow: 0px 6px 15px rgba(14, 90, 242, 0.18);
}

.service-tab-menu .nav-link.active .btn-title,
.service-tab-menu .nav-link:hover .btn-title {
  color: var(--white-color);
}

.service-tab-menu .nav-link.active .btn-text,
.service-tab-menu .nav-link:hover .btn-text {
  color: #BED4FF;
}

.service-tab-menu .nav-link.active .btn-img,
.service-tab-menu .nav-link:hover .btn-img {
  border-color: #F6F7FA;
  background-color: var(--theme-color);
}

.service-tab-menu .nav-link.active .btn-img img,
.service-tab-menu .nav-link:hover .btn-img img {
  filter: brightness(0) invert(1);
}

.service-shape1 {
  position: absolute;
  left: 120px;
  right: 120px;
  top: 0;
  height: 100%;
  max-height: 454px;
  background-color: #F6F7FA;
  z-index: -1;
}

.service-sidebar .widget_nav_menu a::before,
.service-sidebar .widget_meta a::before,
.service-sidebar .widget_pages a::before,
.service-sidebar .widget_archive a::before,
.service-sidebar .widget_categories a::before {
  content: "\f061";
  color: var(--body-color);
}

.service-sidebar .widget_nav_menu a:hover:before,
.service-sidebar .widget_meta a:hover:before,
.service-sidebar .widget_pages a:hover:before,
.service-sidebar .widget_archive a:hover:before,
.service-sidebar .widget_categories a:hover:before {
  color: var(--theme-color);
}

/* Extra large devices */
@media (max-width: 1500px) {
  .service-shape1 {
    left: 0;
    right: 0;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .service-style3 .service-back .service-content {
    padding: 40px 20px 37px 20px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .service-style1 {
    padding: 40px 20px;
  }

  .service-style1 .service-icon {
    margin: 0 0 18px 0;
  }

  .service-style1.layout3 {
    padding: 40px 30px;
  }

  .service-list-box .title {
    margin: 0 0 17px 0;
  }

  .service-style5 .service-title {
    margin-bottom: 15px;
    font-size: 26px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .service-sidebar {
    margin-top: 30px;
  }

  .service-list-box {
    padding: 35px 20px;
  }

  .service-style3 .service-img {
    max-height: 205px;
    overflow: hidden;
  }

  .service-shape1 {
    max-height: 800px;
  }

  .service-tab-menu .nav-link {
    min-width: 45%;
  }

  .service-style4 {
    padding: 40px 30px 45px 30px;
  }

  .service-style4 .service-title {
    font-size: 18px;
  }

  .service-style4 .service-text {
    font-size: 14px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .service-style2 {
    display: block;
    text-align: center;
  }

  .service-style2 .service-img {
    margin: 0 auto;
  }

  .service-style2 .service-content {
    padding: 99px 30px 30px 30px;
    margin: -69px 0 0 0;
  }

  .service-style4 {
    padding: 30px 20px 35px 20px;
  }

  .service-style4 .service-title {
    font-size: 18px;
  }

  .service-style4 .service-text {
    font-size: 14px;
  }
}

/*------------------- 4.13. Counter  -------------------*/
.counter-media {
  display: flex;
  align-items: center;
}

.counter-media__icon {
  display: inline-block;
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  margin: 0 25px 0 0;
  background-color: var(--theme-color);
  border-radius: 5px;
}

.counter-media__number {
  line-height: 1;
  margin: -0.04em 0 7px 0;
  display: block;
}

.counter-media__title {
  font-family: var(--title-font);
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  line-height: 1;
  text-transform: uppercase;
}

.counter-style2 {
  display: flex;
}

.counter-style2 .media-icon {
  color: var(--theme-color);
  font-size: 40px;
  line-height: 1;
  margin-right: 20px;
}

.counter-style2 .media-label {
  font-size: 60px;
  line-height: 1;
  display: block;
  font-family: var(--title-font);
  color: var(--title-color);
  font-weight: 700;
  margin: -0.03em 0 0 0;
}

.counter-style2 .media-link {
  color: var(--theme-color);
  font-size: 20px;
}

.counter-shape1 {
  background-color: rgba(17, 33, 62, 0.07);
  position: absolute;
  left: 0;
  right: 0;
  bottom: -60px;
  top: -60px;
  -webkit-clip-path: polygon(0 0, 100% 120px, 100% 100%, 0 calc(100% - 120px));
  clip-path: polygon(0 0, 100% 120px, 100% 100%, 0 calc(100% - 120px));
}

.counter-wrap1 {
  background-color: var(--white-color);
  box-shadow: 0px 13px 29px rgba(131, 150, 188, 0.1);
  border-radius: 5px;
}

/* Large devices */
@media (max-width: 1199px) {
  .counter-media {
    display: block;
    text-align: center;
  }

  .counter-media__icon {
    margin: 0 0 20px 0;
  }

  .counter-style2 .media-label {
    font-size: 46px;
  }

  .counter-style2 .media-icon {
    font-size: 30px;
  }

  .counter-style2 .media-link {
    font-size: 16px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .counter-style2 {
    display: block;
    text-align: center;
  }

  .counter-style2 .media-icon {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .counter-media__icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }

  .counter-media__icon img {
    max-width: 40px;
  }

  .counter-media__title {
    font-size: 12px;
  }

  .counter-media__number {
    font-size: 30px;
  }
}

/*------------------- 4.14. Team  -------------------*/
.team-style2 .team-social,
.team-style1 .team-social {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 0;
  background-color: var(--theme-color);
  text-align: center;
  padding: 16.5px 10px;
  line-height: 1;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.6s;
}

.team-style2 .team-social a,
.team-style1 .team-social a {
  display: inline-block;
  color: #bbd1ff;
  margin: 0 18px 0 0;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  transform: translateY(-15px);
}

.team-style2 .team-social a:last-child,
.team-style1 .team-social a:last-child {
  margin-right: 0;
}

.team-style2 .team-social a:hover,
.team-style1 .team-social a:hover {
  color: var(--white-color);
}

.team-style2:hover .team-social,
.team-style1:hover .team-social {
  opacity: 1;
  visibility: visible;
}

.team-style2:hover .team-social a,
.team-style1:hover .team-social a {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.team-style2:hover .team-social a:nth-of-type(1),
.team-style1:hover .team-social a:nth-of-type(1) {
  transition-delay: 0s;
}

.team-style2:hover .team-social a:nth-of-type(2),
.team-style1:hover .team-social a:nth-of-type(2) {
  transition-delay: 0.1s;
}

.team-style2:hover .team-social a:nth-of-type(3),
.team-style1:hover .team-social a:nth-of-type(3) {
  transition-delay: 0.2s;
}

.team-style2:hover .team-social a:nth-of-type(4),
.team-style1:hover .team-social a:nth-of-type(4) {
  transition-delay: 0.3s;
}

.team-style2:hover .team-social a:nth-of-type(5),
.team-style1:hover .team-social a:nth-of-type(5) {
  transition-delay: 0.4s;
}

.team-style1 {
  overflow: hidden;
  background-color: var(--white-color);
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
  margin: 0 0 30px 0;
  transition: all ease 0.4s;
}

.team-style1 .team-img {
  overflow: hidden;
  position: relative;
}

.team-style1 .team-img img {
  width: 100%;
  transition: all ease 0.4s;
  transform: scale(1);
}

.team-style1 .team-content {
  padding: 30px 0 23px 0;
  text-align: center;
}

.team-style1 .team-title {
  font-size: 20px;
  line-height: 1;
  margin: 0 0 10px 0;
}

.team-style1 .team-degi {
  color: var(--theme-color);
  margin: 0;
  font-size: 14px;
}

.team-style1:hover {
  background-color: #F6F7FA;
  box-shadow: none;
}

.team-style1:hover .team-img img {
  transform: scale(1.1);
}

.team-style1.layout2 {
  padding: 30px;
  background-color: var(--white-color);
  margin: 0 0 30px 0;
}

.team-style1.layout2 .team-content {
  padding: 38px 0 4px 0;
  box-shadow: none;
}

.team-style1.layout2 .team-img {
  overflow: hidden;
  border-radius: 5px;
}

.team-style1.layout2 .team-title {
  text-transform: uppercase;
  font-size: 24px;
}

.team-style1.layout2 .team-degi {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--title-font);
}

.team-style2 {
  margin: 0 0 30px 0;
}

.team-style2 .team-img {
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin: 0 30px -60px 30px;
  border-radius: 5px;
}

.team-style2 .team-img img {
  width: 100%;
  border-radius: 5px;
  transition: all ease 0.4s;
  filter: none;
}

.team-style2 .team-shape2,
.team-style2 .team-shape1 {
  position: absolute;
  left: 15%;
  top: -55%;
  width: 373.37px;
  height: 549.99px;
  background: rgba(21, 30, 50, 0.41);
  transform: rotate(-34.46deg) translateX(110%);
  transition: all ease 0.4s;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.team-style2 .team-shape2 {
  width: 206.12px;
  height: 391px;
  background: rgba(14, 89, 242, 0.41);
  left: 74%;
  top: -29%;
}

.team-style2 .team-social {
  left: 50px;
  right: 50px;
  bottom: 50px;
  border-radius: 5px;
  z-index: 4;
}

.team-style2 .team-social a {
  color: #abc7fd;
}

.team-style2 .team-social a:hover {
  color: var(--white-color);
}

.team-style2 .team-title {
  text-transform: uppercase;
  margin: 0 0 8px 0;
}

.team-style2 .team-degi {
  color: var(--theme-color);
  margin: 0;
  line-height: 1;
}

.team-style2 .team-content {
  background-color: var(--white-color);
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
  padding: 97px 0 42px 0;
  text-align: center;
  transition: all ease 0.4s;
}

.team-style2:hover .team-content {
  background-color: #F6F7FA;
  box-shadow: none;
}

.team-style2:hover .team-shape1,
.team-style2:hover .team-shape2 {
  transform: rotate(-34.46deg) translateX(0);
  opacity: 1;
  visibility: visible;
}

.team-style2:hover .team-img img {
  filter: grayscale(100%);
}

.team-style3 {
  margin-bottom: 30px;
}

.team-style3 .team-img {
  border-radius: 20px;
  overflow: hidden;
}

.team-style3 .team-img img {
  width: 100%;
  transition: all ease 0.4s;
  transform: scale(1);
}

.team-style3 .team-content {
  background-color: var(--white-color);
  text-align: center;
  padding: 30px 0 40px 0;
  margin: -105px 40px 0 40px;
  position: relative;
  z-index: 1;
  box-shadow: 5px 8.66px 60px 0px rgba(149, 163, 213, 0.18);
  border-radius: 30px;
}

.team-style3 .team-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.team-style3 .team-degi {
  font-size: 14px;
  color: var(--theme-color);
  font-weight: 400;
  display: block;
}

.team-style3 .team-social a i {
  display: inline-block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background-color: var(--theme-color);
  color: var(--white-color);
  font-size: 14px;
  border-radius: 50%;
  margin: 0 1.5px;
  transition: all ease 0.4s;
}

.team-style3 .team-social a i.fa-facebook-f {
  background-color: #d0e5ff;
  color: #2c6bc0;
}

.team-style3 .team-social a i.fa-facebook-f:hover {
  color: #fff;
  background-color: #2c6bc0;
}

.team-style3 .team-social a i.fa-youtube {
  background-color: #ffd6dc;
  color: #ff0024;
}

.team-style3 .team-social a i.fa-youtube:hover {
  color: #fff;
  background-color: #ff0024;
}

.team-style3 .team-social a i.fa-twitter {
  background-color: #c4e9ff;
  color: #1da1f2;
}

.team-style3 .team-social a i.fa-twitter:hover {
  color: #fff;
  background-color: #1da1f2;
}

.team-style3 .team-social a i.fa-basketball-ball {
  background-color: #ffdbe9;
  color: #ea4c89;
}

.team-style3 .team-social a i.fa-basketball-ball:hover {
  color: #fff;
  background-color: #ea4c89;
}

.team-style3:hover .team-img img {
  transform: scale(1.1);
}

.slick-slide .team-style2 .team-content,
.slick-slide .team-style1 {
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.07);
}

.team-about .team-name {
  margin: -0.3em 0 0 0;
}

.team-about .team-degi {
  color: var(--theme-color);
  display: block;
  margin: 0 0 27px 0;
}

.team-about .team-text {
  margin: 0 0 35px 0;
}

.team-about .vs-btn {
  margin: 23px 0 0 0;
}

.team-inner {
  padding: 110px 0 93px 0;
  margin: 90px auto 120px auto;
  max-width: 1680px;
  width: 100%;
}

.team-media {
  display: flex;
  align-items: center;
  margin: 0 0 18px 0;
}

.team-media__icon {
  display: inline-block;
  width: var(--icon-size, 50px);
  height: var(--icon-size, 50px);
  line-height: var(--icon-size, 50px);
  border: 1px solid #E8EEFC;
  text-align: center;
  color: var(--theme-color);
  margin: 0 20px 0 0;
  font-size: 18px;
  border-radius: 50%;
}

.team-media__label {
  font-size: 14px;
}

.team-media__info {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--body-font);
  margin: 0;
}

.team-media__info a {
  color: inherit;
}

.team-media__info a:hover {
  color: var(--theme-color);
}

.team-zigzag .team-style3:nth-child(even) {
  margin-top: 50px;
}

/* Large devices */
@media (max-width: 1199px) {
  .team-style2 .team-social {
    left: 10px;
    right: 10px;
    bottom: 0;
  }

  .team-style3 .team-content {
    padding: 30px 0 40px 0;
    margin: -70px 20px 0 20px;
  }

  .team-zigzag .team-style3:nth-child(even) {
    margin-top: 0;
  }

  .team-style1.layout2 {
    padding: 15px 15px 25px 15px;
  }

  .team-style1.layout2 .team-title {
    font-size: 20px;
  }

  .team-style1.layout2 .team-degi {
    font-size: 14px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .team-about .team-degi {
    margin: 0 0 17px 0;
  }

  .team-about .team-text {
    margin: 0 0 25px 0;
  }

  .team-about .vs-btn {
    margin: 13px 0 0 0;
  }

  .team-inner {
    padding: 70px 0 53px 0;
    margin: 50px auto 80px auto;
  }
}

/*------------------- 4.15. Process  -------------------*/
.process-style1 {
  text-align: center;
  margin: 0 0 5px 0;
  position: relative;
}

.process-style1 .process-arrow {
  position: absolute;
  right: -53px;
  top: 0;
}

.process-style1:nth-child(even) {
  margin-top: 60px;
}

.process-style1:nth-child(even) .process-arrow {
  transform: rotateX(190deg);
}

.process-style1:last-child .process-arrow {
  display: none;
}

.process-style1 .process-icon {
  position: relative;
  text-align: center;
  width: var(--icon-size, 130px);
  height: var(--icon-size, 130px);
  line-height: var(--icon-size, 130px);
  box-shadow: 0px 0px 77px 10px rgba(170, 170, 170, 0.13);
  background-color: var(--white-color);
  border-radius: 50%;
  margin: 0 auto 30px auto;
}

.process-style1 .process-number {
  text-align: center;
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 26px;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 50%;
  position: relative;
  border: 5px solid var(--white-color);
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  font-weight: 700;
  font-family: var(--title-font);
}

.process-style1 .process-title {
  margin: 0 0 10px 0;
}

.process-style2 {
  text-align: center;
  max-width: 290px;
  position: relative;
  margin: 0 0 5px 0;
}

.process-style2 .process-icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  position: relative;
  display: inline-block;
  background-color: var(--white-color);
  margin: 22.5px 0 25px 0;
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
}

.process-style2 .process-number {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--title-font);
  position: absolute;
  right: 0;
  top: 0;
  background-color: var(--theme-color);
  color: var(--white-color);
  margin: -22.5px -22.5px 0 0;
  border-radius: 50%;
}

.process-style2 .process-arrow {
  position: absolute;
  right: -55%;
  top: 10%;
}

.process-style2:last-child .process-arrow {
  display: none;
}

@media (min-width: 1500px) {
  .process-wrap1 {
    margin-left: 120px;
    margin-right: 120px;
  }
}

/* Large devices */
@media (max-width: 1199px) {

  .process-style1,
  .process-style1:nth-child(even) {
    margin-top: 0;
  }

  .process-style1 .process-arrow {
    right: -45px;
    top: 25px;
    max-width: 80px;
  }

  .process-style2 .process-arrow {
    right: -23%;
    top: 15%;
    max-width: 90px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .process-style1 .process-arrow {
    display: none;
  }

  .process-style1 .process-icon {
    --icon-size: 100px;
    margin: 0 auto 20px auto;
  }

  .process-style1 .process-icon img {
    max-width: 40px;
  }

  .process-style2 {
    max-width: 100%;
  }

  .process-style2 .process-arrow {
    display: none;
  }
}

/*------------------- 4.16. Accordion  -------------------*/
.accordion-style1 {
  margin: 0 0 30px 0;
}

.accordion-style1 .accordion-item {
  margin: 0 0 20px 0;
  padding: 0 25px 0 25px;
  background-color: var(--white-color);
  box-shadow: 0px 13px 17px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
}

.accordion-style1 .accordion-button {
  background-color: transparent;
  border: none;
  padding: 14.5px 0;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--title-color);
  text-align: left;
}

.accordion-style1 .accordion-button:after {
  content: "\f061";
  font-family: var(--icon-font);
  font-weight: 400;
  color: var(--title-color);
  border: none;
  width: auto;
  height: auto;
  background-image: none;
}

.accordion-style1 .accordion-button:focus {
  box-shadow: none;
}

.accordion-style1 .accordion-button:not(.collapsed):after {
  transform: rotate(0);
}

.accordion-style1 .accordion-body {
  border-top: 1px solid #EBEBEB;
  padding: 17px 0 20px 0;
}

.accordion-style1 .accordion-body p:last-child {
  margin-bottom: 0;
}

.accordion-style1 .accordion-collapse {
  border: none;
}

.accordion-style1.layout2 .accordion-item {
  padding: 0;
  box-shadow: none;
}

.accordion-style1.layout2 .accordion-button {
  padding: 14.5px 25px;
  font-size: 16px;
  background-color: var(--theme-color);
  color: var(--white-color);
  border-radius: 0;
}

.accordion-style1.layout2 .accordion-button:after {
  color: var(--white-color);
}

.accordion-style1.layout2 .accordion-button.collapsed {
  background-color: var(--smoke-color);
  color: var(--title-color);
}

.accordion-style1.layout2 .accordion-button.collapsed:after {
  color: var(--theme-color);
}

.accordion-style1.layout2 .accordion-body {
  border: none;
  background-color: var(--smoke-color);
  padding: 25px 25px 23px 25px;
}

.accordion-style2 .accordion-item {
  margin: 0 0 20px 0;
  padding: 0 35px 6.5px 35px;
  background-color: var(--white-color);
  box-shadow: 7.5px 12.99px 30px 0px rgba(148, 171, 255, 0.05);
  border-radius: 30px;
}

.accordion-style2 .accordion-button {
  background-color: transparent;
  border: none;
  padding: 24.5px 0 18px 0;
  font-size: 17px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--title-color);
  text-align: left;
}

.accordion-style2 .accordion-button:after {
  content: "\f35a";
  font-family: var(--icon-font);
  font-weight: 400;
  color: var(--title-color);
  border: none;
  width: auto;
  height: auto;
  background-image: none;
  transform: rotate(0);
}

.accordion-style2 .accordion-button:focus {
  box-shadow: none;
}

.accordion-style2 .accordion-button:not(.collapsed):after {
  transform: rotate(90deg);
}

.accordion-style2 .accordion-button:hover {
  color: var(--theme-color);
}

.accordion-style2 .accordion-body {
  padding: 0 0 20px 0;
}

.accordion-style2 .accordion-body p {
  font-size: 14px;
}

.accordion-style2 .accordion-body p:last-child {
  margin-bottom: 0;
}

.accordion-style2 .accordion-collapse {
  border: none;
}

.faq-wrap1 {
  padding-top: 240px;
  padding-bottom: 90px;
  position: relative;
}

.faq-shape1 {
  position: absolute;
  right: 0;
  top: 120px;
  bottom: 0;
  width: 100%;
  max-width: 1120px;
  border-radius: 5px 0 0 5px;
  z-index: -1;
}

.faq-shape2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 945px;
  max-height: 570px;
  z-index: -2;
  border-radius: 0 5px 5px 0;
}

/* Hight Resoulation devices */
@media (min-width: 1921px) {
  .faq-shape1 {
    max-width: 60%;
  }

  .faq-shape2 {
    max-width: 49%;
  }
}

/* Extra large devices */
@media (max-width: 1500px) {
  .faq-wrap1 {
    padding-top: var(--section-space, 120px);
    padding-bottom: calc(var(--section-space, 120px) - 30px);
  }

  .faq-shape2 {
    max-height: initial;
    bottom: 0;
    display: none;
  }

  .faq-shape1 {
    max-width: 70%;
    top: 0;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .faq-wrap1 {
    padding-top: var(--section-space, 120px);
  }

  .faq-shape2 {
    max-height: initial;
    bottom: 0;
    display: none;
  }

  .faq-shape1 {
    max-width: 70%;
    top: 0;
  }

  .accordion-style1 .accordion-button {
    font-size: 16px;
  }

  .accordion-style1 .accordion-body {
    font-size: 14px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .faq-wrap1 {
    padding-top: var(--section-space-mobile, 80px);
    padding-bottom: calc(var(--section-space-mobile, 80px) - 30px);
  }

  .faq-shape1 {
    max-width: 100%;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .accordion-style1 .accordion-item {
    padding: 0 20px 0px 20px;
  }

  .accordion-style1.layout2 .accordion-button {
    padding: 14.5px 20px;
    font-size: 16px;
  }

  .accordion-style1.layout2 .accordion-body {
    padding: 25px 20px 23px 20px;
  }

  .accordion-style2 .accordion-item {
    padding: 0 30px 6.5px 30px;
  }
}

/*------------------- 4.17. Simple Sections  -------------------*/
.brand-slide-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.brand-slide-nav button {
  position: relative;
  border: none;
  text-transform: uppercase;
  background-color: var(--theme-color);
  color: var(--white-color);
  width: 50%;
  padding: 12px 20px;
}

.brand-slide-nav button .fa-long-arrow-left {
  margin-right: 10px;
}

.brand-slide-nav button .fa-long-arrow-right {
  margin-left: 10px;
}

.brand-slide-nav button:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  height: 20px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: -10px;
}

.brand-slide-nav button:last-child:after {
  display: none;
}

.brand-slide-nav button:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.vs-brand1 img {
  transition: all ease 0.4s;
  filter: grayscale(100%) invert(0.8);
}

.vs-brand1 img:hover,
.vs-brand1.slick-current img {
  filter: grayscale(0) invert(0);
}

.brand-wrap1 {
  padding: 80px 60px;
  background-color: var(--theme-color);
  text-align: center;
  background-size: auto;
  background-position: center center;
}

/* Small devices */
@media (max-width: 767px) {
  .brand-wrap1 {
    padding: 60px 25px;
  }
}

/*------------------- 4.18. Testimonial  -------------------*/
.test-wrap1 {
  position: relative;
  border-bottom: 2px solid #f4f6fc;
}

.testi-style1 {
  background-color: #F8F8F9;
  padding: 1px 40px 35px 40px;
  margin: 15px 0 30px 0;
  border-radius: 5px;
  transition: all ease 0.4s;
}

.testi-style1:hover {
  background-color: var(--white-color);
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
}

.testi-style1 .testi-icon {
  padding: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--white-color);
  border-radius: 5px;
  margin: -25px 0 20px 0;
}

.testi-style1 .testi-icon i {
  width: var(--icon-size, 60px);
  height: var(--icon-size, 60px);
  line-height: var(--icon-size, 60px);
  color: var(--white-color);
  font-size: 24px;
  border-radius: inherit;
  background-color: var(--theme-color);
  text-align: center;
  display: inline-block;
}

.testi-style1 .testi-text {
  margin: 0 0 22px 0;
}

.testi-style1 .testi-name {
  margin: 0;
}

.testi-style1 .testi-degi {
  font-size: 14px;
  display: block;
  color: var(--theme-color);
}

.testi-style2 {
  background-color: var(--white-color);
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
  padding: 50px 50px 40px 50px;
  margin: 0 0 30px 0;
}

.testi-style2 .testi-body {
  display: flex;
  margin: 0 0 12px 0;
}

.testi-style2 .author-img {
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin: 0 30px 0 0;
  border-radius: 5px;
}

.testi-style2 .testi-text {
  font-family: var(--title-font);
  line-height: 30px;
  font-size: 16px;
  font-style: italic;
}

.testi-style2 .testi-name {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 2px 0;
}

.testi-style2 .testi-degi {
  font-size: 14px;
  font-family: var(--title-font);
  color: var(--theme-color);
}

.testi-style2-slide {
  margin-top: -30px;
}

.testi-style2-slide .testi-style2 {
  box-shadow: 0px 10px 21px rgba(169, 177, 193, 0.15);
  margin-top: 30px;
}

.author-style1 {
  display: flex;
  border-radius: 15px;
  position: relative;
  padding: 30px;
  margin: 0 0 30px 0;
  transition: all ease 0.4s;
  box-shadow: none;
  background-color: transparent;
  cursor: pointer;
}

.author-style1:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 5px;
  height: 20px;
  background-color: var(--theme-color);
  border-radius: 9999px;
  margin: -10px 0 0 0;
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
}

.author-style1 .author-name {
  margin: 0;
}

.author-style1 .author-degi {
  color: var(--theme-color);
  font-size: 14px;
}

.author-style1 .author-img {
  margin: 0 15px 0 0;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.slick-current .author-style1,
.author-style1:hover {
  background-color: var(--white-color);
  box-shadow: 0px 17px 20px #EEEFF1;
}

.slick-current .author-style1::before {
  opacity: 1;
  visibility: visible;
}

.testi-text-slide .testi-text {
  font-size: 18px;
  margin: 10px 0 30px 0;
}

.testimonial-arrow {
  position: absolute;
  right: -126px;
  top: 50%;
  transform: translateY(-50%);
}

.testimonial-arrow .icon-btn,
.testimonial-arrow .hero-layout4 .slick-arrow,
.hero-layout4 .testimonial-arrow .slick-arrow {
  display: block;
  margin: 0 0 20px 0;
}

.testimonial-arrow .icon-btn:last-child,
.testimonial-arrow .hero-layout4 .slick-arrow:last-child,
.hero-layout4 .testimonial-arrow .slick-arrow:last-child {
  margin-bottom: 0;
}

.testi-shape1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  max-width: 1567px;
  max-height: 684px;
}

.testi-shape2 {
  position: absolute;
  right: 0;
  bottom: 0;
  top: var(--section-space);
  width: 100%;
  background-color: var(--white-color);
  max-width: 1670px;
  z-index: -1;
}

.test-wrap1 {
  padding-top: calc(var(--section-space) * 2);
  padding-bottom: calc(var(--section-space) - 30px);
  position: relative;
}

.testi-inner1 {
  width: 750px;
}

.testi-style3 {
  background-color: var(--white-color);
  padding: 40px 40px 32px 40px;
  border-radius: 50px;
  box-shadow: 5px 8.66px 60px 0px rgba(187, 191, 204, 0.18);
  margin-bottom: 30px;
}

.testi-style3 .testi-author {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
}

.testi-style3 .author-img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.testi-style3 .author-img img {
  width: 100%;
}

.testi-style3 .testi-name {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.testi-style3 .testi-degi {
  color: var(--theme-color);
  font-size: 14px;
}

.testi-style3 .testi-rating {
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--title-color);
  border-top: 1px solid #e3e9ff;
  padding-top: 20px;
  margin-top: 22px;
}

.testi-style3 .testi-rating i {
  color: #feb519;
  margin-left: 5px;
  font-size: 14px;
}

.testi-style3 .testi-rating i:first-child {
  margin-left: 15px;
}

.testi-style4 {
  background-color: var(--white-color);
  padding: 50px 50px 40px 50px;
  transform: skewY(-5deg);
  border-radius: 30px;
  margin: 26px 0 56px 0;
  box-shadow: 4px 5px 60px 0px rgba(178, 180, 187, 0.05);
}

.testi-style4 .testi-body {
  transform: skewY(5deg);
}

.testi-style4 .author-img {
  width: 100px;
  height: 100px;
  position: relative;
  margin-bottom: 30px;
}

.testi-style4 .author-img img {
  border-radius: 50%;
  width: 100%;
}

.testi-style4 .quote {
  background-color: var(--white-color);
  display: inline-block;
  color: var(--theme-color);
  line-height: 1;
  width: 30px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 14px;
  transform: skewY(-10deg);
  border-radius: 5px;
  box-shadow: 3.5px 6.062px 40px 0px rgba(0, 0, 0, 0.21);
}

.testi-style4 .quote i::before {
  transform: skewY(10deg);
  display: block;
}

.testi-style4 .testi-name {
  font-weight: 600;
  margin: 0 0 0px 0;
}

.testi-style4 .testi-degi {
  font-size: 14px;
  color: var(--theme-color);
  margin: 0;
}

/* Hight Resoulation devices */
@media (min-width: 1921px) {
  .testi-shape1 {
    max-width: 85%;
  }

  .testi-shape2 {
    max-width: 90%;
  }
}

@media (max-width: 1800px) {
  .test-wrap1 .offset-xxl-1 {
    margin-left: 0;
  }

  .testi-inner1 {
    width: auto;
    margin-right: -150px;
  }
}

/* Extra large devices */
@media (max-width: 1500px) {
  .testi-shape1 {
    max-width: initial;
    width: auto;
    right: 50px;
  }

  .testi-shape2 {
    max-width: 100%;
    width: auto;
    left: 50px;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .author-style1 {
    padding: 20px;
  }

  .author-style1 .author-name {
    margin: 0;
    font-size: 18px;
  }

  .testi-inner1 {
    margin-right: -100px;
  }

  .testi-style3 {
    padding: 40px 30px 32px 30px;
  }

  .testi-shape2,
  .testi-shape1 {
    display: none;
  }

  .test-wrap1 {
    padding-top: 0;
  }

  .testi-style4 {
    padding: 35px 30px 30px 30px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .testi-style1 {
    padding: 1px 25px 35px 25px;
  }

  .testi-style2 {
    padding: 40px 40px 30px 40px;
    text-align: center;
  }

  .testi-style2 .author-img {
    margin: 0 auto 15px auto;
  }

  .testi-style2 .testi-body {
    display: flex;
    margin: 0 0 0 0;
    flex-direction: column-reverse;
  }

  .testi-inner1 {
    margin-right: 0;
  }

  .sec-big-text2,
  .sec-big-text {
    font-size: 100px;
  }

  .sec-big-text {
    top: -4px;
  }

  .sec-big-text2 {
    right: 70px;
    bottom: -0.18em;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .testi-style2 {
    padding: 30px;
  }

  .test-wrap1 {
    padding-bottom: calc(var(--section-space-mobile) - 30px);
  }
}

/* Small devices */
@media (max-width: 767px) {

  .sec-big-text2,
  .sec-big-text {
    font-size: 50px;
  }

  .sec-big-text2 {
    right: 0;
    bottom: -0.15em;
    left: 0;
    text-align: center;
  }

  .testi-style4 {
    transform: none;
  }

  .testi-style4 .testi-body {
    transform: none;
  }

  .testi-style4 .author-img {
    width: 70px;
    height: 70px;
    margin-bottom: 20px;
  }
}

/*------------------- 4.19. Popup Side Menu  -------------------*/
.sidemenu-wrapper {
  position: fixed;
  z-index: 99999;
  right: 0;
  top: 0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.8s;
}

.sidemenu-wrapper .closeButton {
  display: inline-block;
  border: none;
  color: var(--white-color);
  background-color: var(--theme-color);
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 50%;
  transform: rotate(0);
  transition: all ease 0.4s;
}

.sidemenu-wrapper .closeButton:hover {
  background-color: var(--title-color);
  color: var(--white-color);
  transform: rotate(90deg);
}

.sidemenu-wrapper .sidemenu-content {
  background-color: var(--white-color);
  width: 450px;
  margin-left: auto;
  padding: 40px;
  height: 100%;
  overflow: scroll;
  position: relative;
  right: -500px;
  cursor: auto;
  transition-delay: 1s;
  transition: right ease 1s;
}

.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
}

.sidemenu-wrapper .sidemenu-content::-webkit-scrollbar {
  width: 2px;
  background-color: #F5F5F5;
}

.sidemenu-wrapper .widget {
  padding: 0;
  border: none;
  margin: 0 0 40px 0;
  background-color: transparent;
}

.sidemenu-wrapper .widget_title {
  border-bottom: 1px solid var(--border-color);
  padding: 0 0 20px 0;
  margin: 0 0 25px 0;
}

.sidemenu-wrapper.show {
  opacity: 1;
  visibility: visible;
  width: 100%;
  transition: all ease 0.8s;
}

.sidemenu-wrapper.show .sidemenu-content {
  right: 0;
  opacity: 1;
  visibility: visible;
}

/*------------------- 4.20. Price Plan  -------------------*/
.price-style1 {
  position: relative;
  background-color: #F6F7FA;
  padding: 50px 60px 60px 60px;
  overflow: hidden;
  border-radius: 5px;
  margin: 0 0 30px 0;
}

.price-style1 .price-shape {
  background-color: var(--theme-color);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transform: scale(1.1);
}

.price-style1 .price-package {
  margin: 0 0 3px 0;
}

.price-style1 .price-amount {
  color: var(--theme-color);
}

.price-style1 .price-duration {
  font-size: 16px;
  color: var(--title-color);
  font-weight: 500;
}

.price-style1 .price-features {
  background-color: var(--white-color);
  padding: 28px 30px 22px 30px;
  margin: 0 0 30px 0;
  border-radius: 5px;
}

.price-style1 .price-features ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.price-style1 .price-features li {
  margin: 0 0 5px 0;
}

.price-style1 .price-features i {
  color: var(--theme-color);
  margin: 0 10px 0 0;
}

.price-style1 .vs-btn {
  width: 100%;
}

.price-style1 .vs-btn,
.price-style1 .price-features,
.price-style1 .price-duration,
.price-style1 .price-amount,
.price-style1 .price-package {
  transition: all ease 0.4s;
  position: relative;
  z-index: 2;
}

.slick-center .price-style1 .price-shape,
.price-style1:hover .price-shape {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.slick-center .price-style1 .price-duration,
.slick-center .price-style1 .price-amount,
.slick-center .price-style1 .price-package,
.price-style1:hover .price-duration,
.price-style1:hover .price-amount,
.price-style1:hover .price-package {
  color: var(--white-color);
}

.slick-center .price-style1 .vs-btn,
.price-style1:hover .vs-btn {
  background-color: var(--white-color);
  color: var(--theme-color);
}

.slick-center .price-style1 .vs-btn:hover,
.price-style1:hover .vs-btn:hover {
  color: var(--white-color);
}

.package-style2 .package-inner {
  position: relative;
  display: flex;
  background-color: var(--white-color);
  padding: 48px 60px 42px 60px;
  border-radius: 25px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.package-style2:last-child .package-inner {
  margin-bottom: 30px;
}

.package-style2 .package-head {
  flex: 1;
}

.package-style2 .package-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 50px;
}

.package-style2 .package-discount {
  line-height: 1;
  display: inline-block;
  position: absolute;
  left: 65px;
  top: -15px;
  color: var(--white-color);
  background-color: var(--theme-color);
  padding: 8px 18px;
  font-size: 14px;
  border-radius: 9999px;
  box-shadow: 0 0 0 5px white;
}

.package-style2 .package-amount {
  font-size: 60px;
  font-weight: 700;
  font-family: var(--title-font);
  color: var(--title-color);
  line-height: 1;
  margin: -0.2em 0 24px 0;
}

.package-style2 .package-amount .duration {
  font-size: 18px;
  color: var(--body-color);
  vertical-align: middle;
  font-weight: 400;
  margin: 23px 0 0 5px;
  display: inline-block;
}

.package-style2 .package-list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.package-style2 .package-list li {
  font-size: 16px;
  color: var(--title-color);
  position: relative;
  border-bottom: 1px solid #f1f2f7;
  padding-bottom: 18px;
  margin-bottom: 18px;
  padding-right: 30px;
}

.package-style2 .package-list li i:first-child {
  position: absolute;
  right: 0;
  top: 3.5px;
  color: var(--theme-color);
}

.package-style2 .package-list li i.fa-times-circle {
  color: var(--title-color);
}

.package-style2 .package-list li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.price-wrap1 {
  position: relative;
  padding-top: calc(var(--section-space) * 2);
  padding-bottom: calc(var(--section-space) - 30px);
}

.price-shape1 {
  position: absolute;
  right: 0;
  left: 230px;
  top: var(--section-space);
  bottom: 0;
}

.shape-1 {
  width: 1690px;
  height: 882px;
}

/* Extra large devices */
@media (max-width: 1500px) {
  .price-shape1 {
    left: 50px;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .price-style1 {
    padding: 30px 20px 40px 20px;
  }

  .price-style1 .price-features {
    padding: 28px 20px 22px 20px;
  }

  .price-style1 .price-features li {
    font-size: 14px;
  }

  .price-style1 .price-amount.h1 {
    font-size: 30px;
  }

  .price-wrap1 {
    padding-top: var(--section-space);
  }

  .price-shape1 {
    top: 0;
    left: 0;
  }

  .package-style2 .package-inner {
    padding: 38px 30px 32px 30px;
  }

  .package-style2 .package-amount {
    font-size: 48px;
  }

  .package-style2 .package-amount .duration {
    font-size: 16px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .package-style2 .package-inner {
    padding: 38px 25px 32px 25px;
  }

  .package-style2 .package-list li {
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding-right: 25px;
    font-size: 14px;
  }

  .package-style2 .package-body {
    padding-left: 30px;
  }

  .package-style2 .package-amount {
    font-size: 36px;
    margin-bottom: 20px;
  }

  .package-style2 .package-amount .duration {
    font-size: 14px;
    margin-top: 8px;
  }

  .package-style2 .vs-btn {
    padding: 14px 19px;
    font-size: 14px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .package-style2 .package-inner {
    display: block;
  }

  .package-style2 .package-body {
    flex: 1;
    padding-left: 0;
    padding-top: 30px;
  }

  .package-style2 .package-discount {
    left: 50%;
    transform: translateX(-50%);
  }

  .price-wrap1 {
    padding-top: var(--section-space-mobile);
    padding-bottom: calc(var(--section-space-mobile) - 30px);
  }
}

/*------------------- 4.21. Forms Style  -------------------*/
.form-style1 {
  background-color: #2d3b4b;
  --bs-gutter-x: 20px;
  padding: 70px 80px 80px 80px;
}

.form-style1 .form-title {
  color: var(--white-color);
  text-align: center;
  margin: 0 0 27px 0;
  padding: 0 0 17px 0;
  position: relative;
}

.form-style1 .form-title:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  border-radius: 5px;
  background-color: #0844BC;
  background-color: var(--white-color);
  opacity: 0.3;
}

.form-style1 .vs-btn {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.form-style1 textarea,
.form-style1 input {
  height: 55px;
  background-color: var(--white-color);
  font-size: 16px;
  font-family: var(--body-font);
  color: var(--body-color);
  border-radius: 5px;
}

.form-style1 textarea {
  min-height: 180px;
  resize: none;
}

.form-style2 {
  margin-bottom: 30px;
}

.form-style2 textarea,
.form-style2 select,
.form-style2 input {
  background-color: var(--smoke-color);
  border: none;
  font-size: 16px;
  padding-left: 25px;
}

.form-style3 .form-title {
  margin: 0 0 40px 0;
}

.form-style3 .form-group {
  display: flex;
  background-color: var(--white-color);
  padding: 12.5px;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 9999px;
  margin: 0;
}

.form-style3 .form-group input {
  flex: 1;
  border: none;
  border-left: 2px solid #d8e0ff;
  background-color: transparent;
  height: 30px;
  font-size: 16px;
  color: var(--title-color);
  font-weight: 500;
}

.form-style3 .form-group input::-moz-placeholder {
  color: var(--title-color);
}

.form-style3 .form-group input:-ms-input-placeholder {
  color: var(--title-color);
}

.form-style3 .form-group input::placeholder {
  color: var(--title-color);
}

.form-style3 .form-group input:first-child {
  border-left: none;
}

.form-style4 {
  z-index: 1;
  position: relative;
}

.form-style4 .form-title {
  margin: -0.3em 0 22px 0;
}

.form-style4 .form-inner {
  background-color: var(--white-color);
  box-shadow: 5px 8.66px 25px 0px rgba(196, 201, 228, 0.2);
  border-radius: 10px;
  padding: 32.5px 60px;
  position: relative;
  margin-bottom: 20px;
}

.form-style4 .form-inner:before {
  content: "";
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: -20px;
  top: 20px;
  background-color: var(--white-color);
  opacity: 0.6;
  z-index: -1;
  box-shadow: 5px 8.66px 25px 0px rgba(206, 211, 234, 0.1);
  border-radius: inherit;
}

.form-style4 .form-select,
.form-style4 .form-control {
  height: 30px;
  border: none;
  font-size: 16px;
  background-color: transparent;
  background-image: none;
  padding-left: 60px;
}

.form-style4 .form-group {
  margin-bottom: 0;
  border-left: 1px solid #dfdfdf;
}

.form-style4 .form-group i {
  position: absolute;
  right: auto;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.form-style4 .form-group i.fa-angle-down {
  font-size: 18px;
}

.form-style4 .form-group:first-child {
  border-left: none;
}

.form-style4 .form-group:first-child .form-control,
.form-style4 .form-group:first-child .form-select {
  padding-left: 35px;
}

.form-style4 .form-group:first-child i {
  left: 0;
}

/* Medium devices */
@media (max-width: 991px) {

  .form-style4 .form-select,
  .form-style4 .form-control {
    height: 55px;
    border: 1px solid #E0E0E0;
    font-size: 16px;
    padding-left: 45px;
    border-radius: 9999px;
  }

  .form-style4 .form-group {
    border-left: none;
  }

  .form-style4 .form-group i {
    left: 30px;
  }

  .form-style4 .form-group:first-child .form-control,
  .form-style4 .form-group:first-child .form-select {
    padding-left: 45px;
  }

  .form-style4 .form-group:first-child i {
    left: 30px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .form-style1 {
    padding: 40px 20px 40px 20px;
  }

  .form-style1 select,
  .form-style1 .form-control,
  .form-style1 .form-select,
  .form-style1 textarea,
  .form-style1 input {
    padding-left: 20px;
    font-size: 14px;
  }

  .form-style3 .form-title {
    margin: 0 0 20px 0;
    font-size: 30px;
    line-height: 1.3;
  }

  .form-style3 .form-group {
    display: block;
    border-radius: 0;
    background-color: transparent;
  }

  .form-style3 .form-group input {
    border: none;
    background-color: var(--white-color);
    height: 50px;
    margin-bottom: 12px;
    border-radius: 6px;
    font-size: 14px;
  }

  .form-style3 .form-group .vs-btn {
    background-color: var(--title-color);
  }

  .form-style3 .form-group .vs-btn:after,
  .form-style3 .form-group .vs-btn:before {
    background-color: var(--white-color);
  }

  .form-style3 .form-group .vs-btn:hover {
    color: var(--title-color);
  }

  .form-style4 .form-inner {
    padding: 32.5px 20px;
  }
}

/*------------------- 4.22. Projects  -------------------*/
.filter-menu1 {
  background-color: var(--white-color);
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto 50px auto;
  max-width: 100%;
  border-radius: 9999px;
  text-align: center;
  padding: 15px 60px;
  box-shadow: 7.5px 12.99px 60px 0px rgba(9, 34, 126, 0.07);
}

.filter-menu1 button {
  border: none;
  background-color: transparent;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  display: inline-block;
  position: relative;
  padding: 17px 31px;
  border-radius: 9999px;
}

.filter-menu1 button:hover {
  color: var(--theme-color);
}

.filter-menu1 button.active {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.project-style1 {
  background-color: var(--white-color);
  box-shadow: 0px 10px 31px rgba(169, 177, 193, 0.17);
  border-radius: 5px;
  margin: 0 0 30px 0;
}

.project-style1 .project-img {
  position: relative;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}

.project-style1 .project-img:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.1);
  background-color: var(--theme-color);
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  z-index: 1;
}

.project-style1 .project-img img {
  transition: all ease 0.4s;
  transform: scale(1);
}

.project-style1 .project-content {
  padding: 25px 30px 30px 30px;
}

.project-style1:hover .vs-btn.style3 {
  background-color: var(--theme-color);
  color: var(--white-color);
}

.project-style1:hover .project-img:before {
  transform: scale(1);
  opacity: 0.5;
  visibility: visible;
}

.project-style1:hover .project-img img {
  transform: scale(1.1);
}

.slick-slide .project-style1 {
  box-shadow: 0px 10px 21px rgba(169, 177, 193, 0.15);
}

.project-style2 {
  position: relative;
  margin: 0 0 30px 0;
}

.project-style2 .project-img {
  overflow: hidden;
  position: relative;
}

.project-style2 .project-img img {
  transition: all ease 0.4s;
  transform: scale(1);
  width: 100%;
}

.project-style2 .project-shape {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  background-color: var(--theme-color);
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
}

.project-style2 .icon-btn,
.project-style2 .hero-layout4 .slick-arrow,
.hero-layout4 .project-style2 .slick-arrow {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background-color: var(--white-color);
  color: var(--theme-color);
  opacity: 0;
  visibility: hidden;
}

.project-style2 .icon-btn:hover,
.project-style2 .hero-layout4 .slick-arrow:hover,
.hero-layout4 .project-style2 .slick-arrow:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.project-style2 .project-title {
  color: var(--white-color);
  margin: 0;
}

.project-style2 .project-label {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--title-font);
  color: var(--white-color);
  display: block;
  margin: 0 0 5px 0;
}

.project-style2 .project-label,
.project-style2 .project-title {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50px);
  transition: all ease 0.4s;
}

.project-style2 .project-content {
  position: absolute;
  left: 70px;
  bottom: 60px;
  z-index: 3;
}

.project-style2:hover .project-shape {
  opacity: 0.8;
  visibility: visible;
}

.project-style2:hover .project-img img {
  transform: scale(1.1);
}

.project-style2:hover .icon-btn,
.project-style2:hover .hero-layout4 .slick-arrow,
.hero-layout4 .project-style2:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.2s;
}

.project-style2:hover .project-title,
.project-style2:hover .project-label {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition-delay: 0.1s;
}

.project-style2:hover .icon-btn,
.project-style2:hover .hero-layout4 .slick-arrow,
.hero-layout4 .project-style2:hover .slick-arrow,
.project-style2:hover .project-label {
  transition-delay: 0.2s;
}

.project-box {
  background-color: var(--smoke-color);
  padding: 45px 40px;
  margin: 0 0 30px 0;
}

.project-box__title {
  margin: -0.3em 0 25px 0;
}

.project-box__icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: var(--white-color);
  text-align: center;
  color: var(--theme-color);
  margin: 0 20px 0 0;
  font-size: 18px;
  border-radius: 50%;
}

.project-box__label {
  font-size: 14px;
  display: block;
  margin: 0 0 10px 0;
  line-height: 1;
}

.project-box__info {
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-color);
  margin: 0;
}

.project-box__item {
  display: flex;
  align-items: center;
}

.project-box__item:not(:last-child) {
  border-bottom: 1px solid #DADFEC;
  margin: 0 0 30px 0;
  padding: 0 0 30px 0;
}

.project-contact {
  background-color: var(--theme-color);
  padding: 32px 40px 20px 40px;
}

.project-contact__title {
  color: var(--white-color);
  margin: 0 0 10px 0;
}

.project-contact__text {
  color: var(--white-color);
  margin: 0 0 22px 0;
}

.project-contact__info {
  color: var(--white-color);
  font-size: 18px;
  font-weight: 500;
  position: relative;
  margin: 0 0 10px 0;
  padding: 0 0 0 33px;
}

.project-contact__info a {
  color: inherit;
}

.project-contact__info i {
  margin: 0 15px 0 0;
  position: absolute;
  left: 0;
  top: 3.5px;
}

.project-style3 {
  position: relative;
  margin-bottom: 30px;
}

.project-style3 .project-img {
  overflow: hidden;
  border-radius: 10px;
}

.project-style3 .project-img img {
  transform: scale(1);
  transition: all ease 0.4s;
  width: 100%;
}

.project-style3 .project-bottom {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  background-color: var(--theme-color);
  border-radius: 10px;
  transition: all ease 0.4s;
  padding: 30px 35px 28px 35px;
  opacity: 0;
  visibility: hidden;
}

.project-style3 .icon-btn,
.project-style3 .hero-layout4 .slick-arrow,
.hero-layout4 .project-style3 .slick-arrow,
.project-style3 .project-category,
.project-style3 .project-title {
  transform: translateY(10px);
  transition: all ease 0.4s;
  opacity: 0;
  visibility: hidden;
}

.project-style3 .project-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--white-color);
  margin: 0 0 2px 0;
}

.project-style3 .project-category {
  font-size: 16px;
  color: #7c98ff;
  margin: 0;
}

.project-style3 .icon-btn,
.project-style3 .hero-layout4 .slick-arrow,
.hero-layout4 .project-style3 .slick-arrow {
  --btn-size: 40px;
  --btn-font-size: 16px;
  transform: translateX(10px);
}

.project-style3:hover .project-bottom {
  opacity: 1;
  visibility: visible;
}

.project-style3:hover .icon-btn,
.project-style3:hover .hero-layout4 .slick-arrow,
.hero-layout4 .project-style3:hover .slick-arrow,
.project-style3:hover .project-title,
.project-style3:hover .project-category {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition-delay: 0.1s;
}

.project-style3:hover .icon-btn,
.project-style3:hover .hero-layout4 .slick-arrow,
.hero-layout4 .project-style3:hover .slick-arrow {
  transition-delay: 0.15s;
}

.project-style3:hover .project-category {
  transition-delay: 0.2s;
}

.project-style3:hover .project-img img {
  transform: scale(1.1);
}

/* Extra large devices */
@media (max-width: 1500px) {

  .project-style2 .icon-btn,
  .project-style2 .hero-layout4 .slick-arrow,
  .hero-layout4 .project-style2 .slick-arrow {
    top: 60px;
    right: 60px;
    left: auto;
    transform: none;
    --btn-size: 40px;
    --btn-font-size: 18px;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .project-box {
    padding: 35px 25px;
  }

  .project-contact {
    padding: 32px 25px 20px 25px;
  }

  .project-style1 .project-img img {
    width: 100%;
  }

  .project-style1 .project-content {
    padding-left: 25px;
    padding-right: 25px;
  }

  .project-style3 .project-bottom {
    left: 15px;
    right: 15px;
    bottom: 15px;
    padding: 20px 20px 20px 20px;
    gap: 15px;
  }

  .project-style3 .project-title {
    font-size: 18px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .project-style2 .project-shape {
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
  }

  .project-style2 .project-content {
    left: 20px;
    bottom: 20px;
  }

  .project-style2 .icon-btn,
  .project-style2 .hero-layout4 .slick-arrow,
  .hero-layout4 .project-style2 .slick-arrow {
    top: 20px;
    right: 20px;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .filter-menu1 {
    padding: 15px 15px;
    border-radius: 10px;
  }

  .filter-menu1 button {
    font-size: 14px;
    padding: 15px 22px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .project-style1 .project-content {
    padding: 25px 20px 30px 20px;
  }
}

/*------------------- 4.23. Contact  -------------------*/
.contact-tab-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin: 0 0 60px 0;
}

.contact-tab-menu .btn-img {
  width: 120px;
  float: left;
  margin: 0 20px 0 0;
}

.contact-tab-menu .btn-title {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--title-font);
  margin: 4px 0 0 0;
  transition: all ease 0.4s;
}

.contact-tab-menu .btn-text {
  display: inline-block;
  color: var(--body-color);
  font-size: 14px;
  font-family: var(--title-font);
  margin: 2px 0 0 0;
  transition: all ease 0.4s;
}

.contact-tab-menu button {
  padding: 20px;
  position: relative;
  width: 100%;
  flex: 1;
  text-align: left;
  background-color: transparent;
  border: 1px solid #F0F0F0;
}

.contact-tab-menu button.active,
.contact-tab-menu button:hover {
  background-color: var(--theme-color);
  border-color: transparent;
}

.contact-tab-menu button.active .btn-text,
.contact-tab-menu button.active .btn-title,
.contact-tab-menu button:hover .btn-text,
.contact-tab-menu button:hover .btn-title {
  color: var(--white-color);
}

.contact-box {
  background-color: var(--smoke-color);
  padding: 60px;
  height: 100%;
}

.contact-box__title {
  margin: -0.4em 0 10px 0;
}

.contact-box__text {
  margin: 0 0 37px 0;
}

.contact-box__item {
  display: flex;
}

.contact-box__item:not(:last-child) {
  padding: 0 0 30px 0;
  margin: 0 0 30px 0;
  border-bottom: 1px solid #DADFEC;
}

.contact-box__icon {
  font-size: 30px;
  line-height: 1;
  color: var(--theme-color);
  align-self: flex-start;
  margin: 0 15px 0 0;
}

.contact-box__label {
  font-size: 18px;
  margin: -0.3em 0 8px 0;
}

.contact-box__info {
  max-width: 235px;
  margin: 0 0 -0.5em 0;
}

.contact-box__info a {
  display: inline-block;
  color: inherit;
}

.contact-box__form .form-group {
  line-height: 1;
}

.contact-box__form textarea,
.contact-box__form select,
.contact-box__form input {
  height: 55px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-position: right 21px center;
}

.contact-box__form i {
  top: 19px;
  right: calc(var(--bs-gutter-x) / 2 + 24px);
  font-size: 16px;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .contact-box {
    padding: 40px;
  }

  .contact-box__title {
    margin: -0.3em 0 10px 0;
  }

  .contact-box__form textarea,
  .contact-box__form select,
  .contact-box__form input {
    font-size: 14px;
  }

  .contact-tab-menu button {
    text-align: center;
  }

  .contact-tab-menu .btn-title {
    display: block;
  }

  .contact-tab-menu .btn-img {
    float: none;
    margin: 0 auto 10px auto;
    display: block;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .contact-tab-menu .btn-title {
    font-size: 16px;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .contact-box {
    padding: 40px 20px;
  }

  .contact-box__label {
    font-size: 16px;
  }

  .contact-box__icon {
    font-size: 20px;
  }
}

/*------------------- 4.24. Event  -------------------*/
.event-table__head {
  background-color: var(--theme-color);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 29px 60px;
}

.event-table__item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #eaeaf0;
  background-color: #f8f8f9;
  padding: 35px 60px 32px 60px;
}

.event-table__item:last-child {
  padding-bottom: 52px;
  border-bottom: none;
}

.event-table__col {
  width: 100%;
}

.event-table__col:nth-child(1) {
  max-width: 20%;
}

.event-table__col:nth-child(2) {
  max-width: 40%;
}

.event-table__col:nth-child(3) {
  max-width: 25%;
}

.event-table__col:nth-child(4) {
  max-width: 15%;
}

.event-table__coltitle {
  color: var(--white-color);
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.event-table__date {
  font-size: 20px;
  font-weight: 500;
  color: var(--title-color);
}

.event-table__media {
  display: flex;
  align-items: center;
}

.event-table__img {
  width: 100px;
  border-radius: 50%;
  margin-right: 25px;
  overflow: hidden;
}

.event-table__img img {
  width: 100%;
}

.event-table__title {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 3px 0;
}

.event-table__title a {
  color: inherit;
}

.event-table__title a:hover {
  color: var(--theme-color);
}

.event-table__text {
  font-size: 14px;
  margin: 0;
}

.event-table__author {
  display: flex;
  align-items: center;
}

.event-table__avater {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}

.event-table__avater img {
  width: 100%;
}

.event-table__name {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

.event-table__degi {
  font-size: 14px;
  margin: 0;
  display: block;
}

/* Large devices */
@media (max-width: 1199px) {
  .event-table__head {
    padding: 16px 40px;
  }

  .event-table__item {
    padding-left: 40px;
    padding-right: 40px;
  }

  .event-table__date {
    font-size: 14px;
  }

  .event-table__img {
    width: 80px;
    margin-right: 20px;
  }

  .event-table__title {
    font-size: 18px;
  }

  .event-table__name {
    font-size: 16px;
  }

  .event-table__avater {
    width: 50px;
    height: 50px;
  }

  .event-table__btn.vs-btn {
    padding: 0;
    width: 130px;
    height: 50px;
    line-height: 47px;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .event-table__item {
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }

  .event-table__head {
    padding: 20px 0;
  }

  .event-table__coltitle {
    font-size: 14px;
  }

  .event-table__col:nth-child(1) {
    max-width: 10%;
    padding-left: 15px;
  }

  .event-table__col:nth-child(2) {
    max-width: 50%;
  }

  .event-table__col:nth-child(3) {
    max-width: 20%;
    text-align: center;
  }

  .event-table__col:nth-child(4) {
    max-width: 20%;
    text-align: center;
    padding-right: 15px;
  }

  .event-table__author {
    display: block;
    text-align: center;
  }

  .event-table__avater {
    margin: 0 auto 10px auto;
  }

  .event-table__date {
    -ms-writing-mode: tb-rl;
    writing-mode: tb-rl;
    transform: rotate(-180deg) translateY(50%);
    background-color: var(--theme-color);
    color: var(--white-color);
    padding: 10px 3px;
    position: absolute;
    left: 0;
    top: 50%;
    border-radius: 9px 0 0 9px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    display: block;
  }

  .event-table__btn.vs-btn {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    height: auto;
    line-height: 1;
    font-size: 14px;
    border: none;
  }

  .event-table__btn.vs-btn:hover {
    background-color: transparent;
  }
}

/* Small devices */
@media (max-width: 767px) {
  .event-table__head {
    display: none;
  }

  .event-table__item {
    padding: 0 30px 20px 30px;
    border: none;
    margin-bottom: 20px;
  }

  .event-table__date {
    -ms-writing-mode: lr-tb;
    writing-mode: lr-tb;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    border-radius: 0 0 10px 10px;
    padding: 5px 10px;
  }

  .event-table__media {
    text-align: center;
    display: block;
    border-bottom: 1px solid #eaeaf0;
    margin-bottom: 25px;
    padding-bottom: 20px;
  }

  .event-table__img {
    width: 100%;
    height: 100%;
    border-radius: 0;
    margin-bottom: 15px;
    margin-right: 0;
  }

  .event-table__author {
    display: flex;
    text-align: left;
  }

  .event-table__avater {
    margin-right: 15px;
  }

  .event-table__btn.vs-btn {
    border: 1px solid var(--theme-color);
    width: 100px;
    height: 40px;
    line-height: 38px;
  }

  .event-table__col:nth-child(1) {
    max-width: 0;
  }

  .event-table__col:nth-child(2) {
    max-width: 100%;
  }

  .event-table__col:nth-child(3) {
    max-width: 50%;
  }

  .event-table__col:nth-child(4) {
    max-width: 50%;
    text-align: right;
    padding: 0;
  }
}

/* Extra small devices */
@media (max-width: 575px) {
  .event-table__author {
    display: block;
    text-align: left;
  }

  .event-table__avater {
    margin-left: 0;
  }
}

/*------------------- 4.25. CTA  -------------------*/
@media (min-width: 1500px) {
  .cta-wrap1 {
    margin-left: 110px;
    margin-right: 110px;
  }
}

.cta-wrap2 {
  background-color: var(--theme-color);
  padding: 80px;
}

.cta-title1 {
  margin-bottom: -1rem;
  color: var(--white-color);
}

.cta-style1 {
  background-color: var(--theme-color);
  padding: 94px 0 90px 0;
  text-align: center;
}

.cta-style1 .vs-btn,
.cta-style1 .cta-title,
.cta-style1 .cta-title2 {
  position: relative;
  z-index: 2;
}

.cta-style1 .cta-title {
  color: var(--white-color);
  font-size: 60px;
  line-height: 1;
  margin: 0 0 8px 0;
}

.cta-style1 .cta-title2 {
  color: var(--white-color);
}

.cta-style1.bg-center {
  background-position: center center;
  background-size: auto;
}

.cta-wrap3 {
  padding-bottom: var(--section-space);
}

@media (min-width: 1199px) {
  .cta-wrap3 {
    margin-bottom: -192px;
    padding-bottom: 0;
  }
}

/* Extra large devices */
@media (max-width: 1500px) {
  .cta-wrap2 {
    padding: 60px;
  }
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .cta-wrap2 {
    padding: 45px;
  }
}

/* Large devices */
@media (max-width: 1199px) {
  .cta-title1 {
    margin-bottom: -0.7rem;
  }
}

/* Medium devices */
@media (max-width: 991px) {
  .cta-title1 {
    margin: -0.5em 0 30px 0;
  }

  .cta-wrap3 {
    padding-bottom: var(--section-space-mobile);
  }
}

/* Small devices */
@media (max-width: 767px) {
  .cta-wrap2 {
    padding: 45px 20px;
  }
}

/*=================================
      05. Spacing
  ==================================*/
/*-- Padding Left And Right --*/
.px-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.px-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.px-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.px-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.px-25 {
  padding-right: 25px;
  padding-left: 25px;
}

.px-30 {
  padding-right: 30px;
  padding-left: 30px;
}

/*-- Padding Top And Bottom --*/
.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.py-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-25 {
  padding-left: 25px;
}

.pl-30 {
  padding-left: 30px;
}

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-25 {
  padding-right: 25px;
}

.pr-30 {
  padding-right: 30px;
}

/*-- margin Left And Right --*/
.mx-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.mx-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.mx-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.mx-20 {
  margin-right: 20px;
  margin-left: 20px;
}

.mx-25 {
  margin-right: 25px;
  margin-left: 25px;
}

.mx-30 {
  margin-right: 30px;
  margin-left: 30px;
}

/*-- margin Top And Bottom --*/
.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.my-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

/*-- margin Top --*/
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

/*-- margin Bottom --*/
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

/*-- margin Left --*/
.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

/*-- margin Right --*/
.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mt-n1 {
  margin-top: -0.25rem;
}

.mt-n2 {
  margin-top: -0.5rem;
}

.mt-n3 {
  margin-top: -1rem;
}

.mt-n4 {
  margin-top: -1.5rem;
}

.mt-n5 {
  margin-top: -3rem;
}

.mb-n1 {
  margin-bottom: -0.25rem;
}

.mb-n2 {
  margin-bottom: -0.5rem;
}

.mb-n3 {
  margin-bottom: -1rem;
}

.mb-n4 {
  margin-bottom: -1.5rem;
}

.mb-n5 {
  margin-bottom: -3rem;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.space,
.space-top {
  padding-top: var(--section-space);
}

.space,
.space-bottom {
  padding-bottom: var(--section-space);
}

.space-extra,
.space-extra-top {
  padding-top: calc(var(--section-space) - 30px);
}

.space-extra,
.space-extra-bottom {
  padding-bottom: calc(var(--section-space) - 30px);
}

/* Medium devices */
@media (max-width: 991px) {

  .space,
  .space-top {
    padding-top: var(--section-space-mobile);
  }

  .space,
  .space-bottom {
    padding-bottom: var(--section-space-mobile);
  }

  .space-extra,
  .space-extra-top {
    padding-top: calc(var(--section-space-mobile) - 30px);
  }

  .space-extra,
  .space-extra-bottom {
    padding-bottom: calc(var(--section-space-mobile) - 30px);
  }

  .space-top-md-none {
    padding-top: 0;
  }
}

/*=================================
      06. Color Plate
  ==================================*/
/* Color Plate */
.vs-color-plate {
  position: fixed;
  display: block;
  z-index: 91;
  padding: 20px;
  width: 240px;
  background: #fff;
  left: 0;
  text-align: left;
  top: 30%;
  transition: all 0.4s ease;
  box-shadow: -3px 0px 10px -2px rgba(0, 0, 0, 0.1);
  direction: ltr;
  transform: translateX(-100%);
}

.vs-color-plate.open {
  transform: translateX(0);
}

.vs-color-plate .color-plate-icon {
  position: absolute;
  right: -48px;
  width: 48px;
  height: 45px;
  line-height: 45px;
  font-size: 21px;
  border-radius: 0 5px 5px 0;
  text-align: center;
  color: var(--white-color);
  top: 0;
  cursor: pointer;
  border: none;
  padding: 0;
  box-shadow: -4px 0px 5px rgba(0, 0, 0, 0.2117647059);
  background-color: var(--theme-color);
}

.vs-color-plate h4 {
  display: block;
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 500;
}

.vs-color-plate p {
  font-size: 12px;
  margin-bottom: 15px;
  line-height: 20px;
}

.vs-color-plate .reset-btn {
  border: none;
  padding: 0;
  height: 20px;
  font-size: 14px;
  text-transform: capitalize;
  color: var(--title-color);
  background-color: var(--smoke-color);
  width: 100%;
  height: 40px;
}

.vs-color-plate .reset-btn:hover {
  background-color: var(--title-color);
  color: var(--white-color);
}

.vs-color-plate input[type=color] {
  border: none;
  padding: 0;
}

.default-color-btn {
  border: none;
  background-color: #000;
  color: var(--white-color);
  font-weight: 400;
  font-family: var(--body-font);
  text-align: center;
  width: 100%;
  font-size: 14px;
  margin: 0 0 10px 0;
  padding: 5px 0;
}

/*# sourceMappingURL=style.css.map */