.name {
  position: relative;
  font-size: 16px;
  font-family: poppins;
  color: #2e2e2e;
  text-align: left;
}
.div,
.form {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.form-name {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: #e8edfb;
  height: 49px;
  border-radius: 5px;
  flex-direction: row;
  padding: 15px;
  box-sizing: border-box;
  font-family: poppins;
  font-size: 14px;
  color: #72849e;
  min-width: 250px;
}
.div {
  flex-direction: column;
  padding: 5px 0;
  gap: 10px 0;
}
.email {
  position: relative;
  font-size: 18px;
  font-family: Roboto;
  color: #2e2e2e;
  text-align: left;
}
.div1,
.form1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.form1 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: #e8edfb;
  height: 49px;
  border-radius: 5px;
  flex-direction: row;
  padding: 15px;
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 16px;
  color: #72849e;
  min-width: 250px;
}
.div1 {
  flex-direction: column;
  padding: 0px 0;
  gap: 0px 0;
}
.phone-number {
  position: relative;
  font-size: 18px;
  font-family: Roboto;
  color: #2e2e2e;
  text-align: left;
}
.div2,
.form2 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.form2 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: #e8edfb;
  height: 49px;
  border-radius: 5px;
  flex-direction: row;
  padding: 15px;
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 16px;
  color: #72849e;
  min-width: 250px;
}
.div2 {
  flex-direction: column;
  padding: 5px 0;
  gap: 10px 0;
}
.company-name {
  position: relative;
  font-size: 18px;
  font-family: Roboto;
  color: #2e2e2e;
  text-align: left;
}
.div3,
.form3 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.form3 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: #e8edfb;
  height: 49px;
  border-radius: 5px;
  flex-direction: row;
  padding: 15px;
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 16px;
  color: #72849e;
  min-width: 250px;
}
.div3 {
  flex-direction: column;
  padding: 5px 0;
  gap: 10px 0;
}
.role {
  position: relative;
  font-size: 18px;
  font-family: Roboto;
  color: #2e2e2e;
  text-align: left;
}
.div4,
.form4 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.form4 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: #e8edfb;
  height: 49px;
  border-radius: 5px;
  flex-direction: row;
  padding: 15px;
  box-sizing: border-box;
  font-family: Roboto;
  font-size: 16px;
  color: #72849e;
  min-width: 250px;
}
.div4 {
  flex-direction: column;
  padding: 5px 0;
  gap: 10px 0;
}
.cancel {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  font-family: poppins;
  color: #559ff8;
  text-align: left;
}
.button {
  cursor: pointer;
  border: 0;
  padding: 10px;
  background-color: #fff;
  flex: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 169px;
  min-height: 50px;
}
.button:hover {
  background-color: #e6e6e6;
}
.contact-to-sales {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  font-family: poppins;
  color: #fff;
  text-align: left;
}
.button1 {
  cursor: pointer;
  border: 0;
  padding: 10px;
  background-color: #559ff8;
  flex: 1;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 169px;
  min-height: 50px;
  white-space: nowrap;
}
.button1:hover {
  background-color: #3b85de;
}
.div5,
.input-form {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.div5 {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  gap: 0 10px;
}
.input-form {
  margin: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px 0;
}
.contact-sales {
  position: relative;
  font-weight: 600;
}
.please-enter-your {
  align-self: stretch;
  position: relative;
  font-size: 18px;
  color: #72849e;
}
.text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px 0;
  min-width: 323px;
  max-width: 100%;
}
.close-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.index,
.title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.title {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.index {
  flex: 1;
  flex-direction: column;
  gap: 10px 0;
}
.modal,
.modal1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}
.modal1 {
  width: 591px;
  border-radius: 5px;
  background-color: #fff;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  max-width: 100%;
}
.modal {
  width: 100%;
  position: relative;
  background-color: #efefef;
  align-items: center;
  justify-content: center;
  padding: 154px 21px 154px 20px;
  letter-spacing: normal;
  text-align: left;
  font-size: 20px;
  color: #2e2e2e;
  font-family: Roboto;
}
@media screen and (max-width: 725px) {
  .modal1 {
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 450px) {
  .contact-sales {
    font-size: 16px;
  }
}
